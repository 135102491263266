<template>
  <div class="user-settings bg-gray-100 min-h-screen py-8">
    <div class="max-w-3xl mx-auto bg-white rounded-lg shadow-md overflow-hidden">
      <div class="bg-gradient-to-r from-purple-600 to-indigo-600 p-6">
        <h1 class="text-3xl font-bold text-white">User Settings</h1>
      </div>
      
      <div class="p-6">
        <form @submit.prevent="saveSettings">
          <!-- Profile Information -->
          <div class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">Profile Information</h2>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label for="username" class="block text-sm font-medium text-gray-700 mb-1">Username</label>
                <input v-model="user.username" id="username" type="text" class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500">
              </div>
              <div>
                <label for="email" class="block text-sm font-medium text-gray-700 mb-1">Email</label>
                <input v-model="user.email" id="email" type="email" class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500">
              </div>
              <div>
                <label for="name" class="block text-sm font-medium text-gray-700 mb-1">Display Name</label>
                <input v-model="user.name" id="name" type="text" class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500">
              </div>
              <div>
                <label for="tagline" class="block text-sm font-medium text-gray-700 mb-1">Tagline</label>
                <input v-model="user.tagline" id="tagline" type="text" class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500">
              </div>
              <div class="md:col-span-2">
                <label for="bio" class="block text-sm font-medium text-gray-700 mb-1">Bio</label>
                <textarea v-model="user.bio" id="bio" rows="4" class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"></textarea>
              </div>
              <div>
                <label for="birthdate" class="block text-sm font-medium text-gray-700 mb-1">Birthdate</label>
                <input v-model="user.birthdate" id="birthdate" type="date" class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500">
              </div>
              <div>
                <label for="location" class="block text-sm font-medium text-gray-700 mb-1">Location</label>
                <input v-model="user.location" id="location" type="text" class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500">
              </div>
            </div>
          </div>

          <!-- Social Media Links -->
          <div class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">Social Media Links</h2>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label for="website" class="block text-sm font-medium text-gray-700 mb-1">Personal Website</label>
                <input v-model="user.socialLinks.website" id="website" type="url" class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500">
              </div>
              <div>
                <label for="twitter" class="block text-sm font-medium text-gray-700 mb-1">Twitter</label>
                <input v-model="user.socialLinks.twitter" id="twitter" type="text" class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500">
              </div>
              <div>
                <label for="instagram" class="block text-sm font-medium text-gray-700 mb-1">Instagram</label>
                <input v-model="user.socialLinks.instagram" id="instagram" type="text" class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500">
              </div>
              <div>
                <label for="facebook" class="block text-sm font-medium text-gray-700 mb-1">Facebook</label>
                <input v-model="user.socialLinks.facebook" id="facebook" type="text" class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500">
              </div>
            </div>
          </div>

          <!-- Avatar Upload -->
          <div class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">Profile Picture</h2>
            <div class="flex items-center space-x-4">
              <img :src="user.avatar" alt="User Avatar" class="w-24 h-24 rounded-full object-cover">
              <div>
                <label for="avatar" class="block text-sm font-medium text-gray-700 mb-1">Upload new picture</label>
                <input @change="handleAvatarUpload" type="file" id="avatar" accept="image/*" class="block w-full text-sm text-gray-500
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-indigo-50 file:text-indigo-700
                  hover:file:bg-indigo-100
                ">
              </div>
            </div>
          </div>

          <!-- Change Password -->
          <div class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">Change Password</h2>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label for="current-password" class="block text-sm font-medium text-gray-700 mb-1">Current Password</label>
                <input v-model="passwords.current" id="current-password" type="password" class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500">
              </div>
              <div>
                <label for="new-password" class="block text-sm font-medium text-gray-700 mb-1">New Password</label>
                <input v-model="passwords.new" id="new-password" type="password" class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500">
              </div>
              <div>
                <label for="confirm-password" class="block text-sm font-medium text-gray-700 mb-1">Confirm New Password</label>
                <input v-model="passwords.confirm" id="confirm-password" type="password" class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500">
              </div>
            </div>
          </div>

          <!-- Privacy Settings -->
          <div class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">Privacy Settings</h2>
            <div class="space-y-2">
              <div class="flex items-center">
                <input v-model="user.privacySettings.showEmail" id="show-email" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
                <label for="show-email" class="ml-2 block text-sm text-gray-900">
                  Show email on public profile
                </label>
              </div>
              <div class="flex items-center">
                <input v-model="user.privacySettings.showBirthdate" id="show-birthdate" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
                <label for="show-birthdate" class="ml-2 block text-sm text-gray-900">
                  Show birthdate on public profile
                </label>
              </div>
              <div class="flex items-center">
                <input v-model="user.privacySettings.allowMessaging" id="allow-messaging" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
                <label for="allow-messaging" class="ml-2 block text-sm text-gray-900">
                  Allow messaging from non-followers
                </label>
              </div>
            </div>
          </div>

          <!-- Notification Preferences -->
          <div class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">Notification Preferences</h2>
            <div class="space-y-2">
              <div class="flex items-center">
                <input v-model="user.notificationPreferences.email" id="email-notifications" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
                <label for="email-notifications" class="ml-2 block text-sm text-gray-900">
                  Receive email notifications
                </label>
              </div>
              <div class="flex items-center">
                <input v-model="user.notificationPreferences.site" id="site-notifications" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
                <label for="site-notifications" class="ml-2 block text-sm text-gray-900">
                  Receive on-site notifications
                </label>
              </div>
              <div class="flex items-center">
                <input v-model="user.notificationPreferences.newFollower" id="new-follower-notifications" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
                <label for="new-follower-notifications" class="ml-2 block text-sm text-gray-900">
                  Notify me about new followers
                </label>
              </div>
              <div class="flex items-center">
                <input v-model="user.notificationPreferences.newComment" id="new-comment-notifications" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
                <label for="new-comment-notifications" class="ml-2 block text-sm text-gray-900">
                  Notify me about new comments on my stories
                </label>
              </div>
              <div class="flex items-center">
                <input v-model="user.notificationPreferences.newApplause" id="new-applause-notifications" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
                <label for="new-applause-notifications" class="ml-2 block text-sm text-gray-900">
                  Notify me about new applause on my stories
                </label>
              </div>
            </div>
          </div>

          <!-- Save Button -->
          <div class="flex justify-end">
            <button type="submit" class="bg-indigo-600 text-white px-6 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Save Changes
            </button>
          </div>
        </form>

        <!-- Delete Account -->
        <div class="mt-12 border-t pt-8">
          <h2 class="text-2xl font-semibold mb-4 text-red-600">Danger Zone</h2>
          <p class="mb-4 text-gray-600">Once you delete your account, there is no going back. Please be certain.</p>
          <button @click="confirmDeleteAccount" class="bg-red-600 text-white px-6 py-2 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
            Delete Account
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { supabase } from '@/supabase';

export default {
  name: 'UserSettings',
  setup() {
    const store = useStore();
    const router = useRouter();

    const user = reactive({
      username: '',
      email: '',
      name: '',
      tagline: '',
      bio: '',
      avatar_url: '',
      birthdate: '',
      location: '',
      socialLinks: {
        website: '',
        twitter: '',
        instagram: '',
        facebook: ''
      },
      privacySettings: {
        showEmail: false,
        showBirthdate: false,
        allowMessaging: true
      },
      notificationPreferences: {
        email: true,
        site: true,
        newFollower: true,
        newComment: true,
        newApplause: true
      }
    });

    const currentUser = computed(() => store.getters['auth/currentUser']);

    const passwords = reactive({
      current: '',
      new: '',
      confirm: ''
    });

    const fetchUserData = async () => {
      if (currentUser.value) {
        const { data, error } = await supabase
          .from('users')
          .select('*')
          .eq('id', currentUser.value.id)
          .single();

        if (error) {
          console.error('Error fetching user data:', error);
          return;
        }

        Object.assign(user, data);
      }
    };

    const handleAvatarUpload = async (event) => {
      const file = event.target.files[0];
      if (file) {
        const fileExt = file.name.split('.').pop();
        const fileName = `${Math.random()}.${fileExt}`;
        const filePath = `avatars/${fileName}`;

        try {
          const { error: uploadError } = await supabase.storage
            .from('avatars')
            .upload(filePath, file);

          if (uploadError) throw uploadError;

          const { data: { publicUrl }, error: urlError } = supabase.storage
            .from('avatars')
            .getPublicUrl(filePath);

          if (urlError) throw urlError;

          user.avatar_url = publicUrl;
        } catch (error) {
          console.error('Error uploading avatar:', error);
          alert('Failed to upload avatar. Please try again.');
        }
      }
    };

    const saveSettings = async () => {
      try {
        if (!validateForm()) {
          return;
        }

        const { error: updateError } = await supabase
          .from('users')
          .update({
            username: user.username,
            email: user.email,
            name: user.name,
            tagline: user.tagline,
            bio: user.bio,
            avatar_url: user.avatar_url,
            birthdate: user.birthdate,
            location: user.location,
            social_links: user.socialLinks,
            privacy_settings: user.privacySettings,
            notification_preferences: user.notificationPreferences
          })
          .eq('id', currentUser.value.id);

        if (updateError) {
          throw new Error(updateError.message);
        }

        if (passwords.new) {
          if (passwords.new !== passwords.confirm) {
            alert('New passwords do not match');
            return;
          }
          const { error: passwordError } = await supabase.auth.updateUser({ 
            password: passwords.new 
          });
          if (passwordError) {
            throw new Error(passwordError.message);
          }
        }

        // Update the Vuex store
        await store.dispatch('auth/refreshUser');

        alert('Settings saved successfully');
      } catch (error) {
        console.error('Error saving settings:', error.message);
        alert('Failed to save settings. Please try again.');
      }
    };

    const validateForm = () => {
      if (!user.username.trim()) {
        alert('Username is required');
        return false;
      }
      if (!user.email.trim() || !isValidEmail(user.email)) {
        alert('Valid email is required');
        return false;
      }
      if (!user.name.trim()) {
        alert('Display name is required');
        return false;
      }
      return true;
    };

    const isValidEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    };

    const confirmDeleteAccount = () => {
      if (confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
        deleteAccount();
      }
    };

    const deleteAccount = async () => {
      try {
        const { error } = await supabase.auth.admin.deleteUser(currentUser.value.id);
        if (error) throw error;

        await store.dispatch('auth/logout');
        router.push('/');
        alert('Your account has been successfully deleted.');
      } catch (error) {
        console.error('Error deleting account:', error);
        alert('Failed to delete account. Please try again.');
      }
    };

    onMounted(fetchUserData);

    return {
      user,
      passwords,
      handleAvatarUpload,
      saveSettings,
      confirmDeleteAccount
    };
  }
};
</script>