<template>
  <div v-if="isAdmin" class="admin-dashboard bg-gray-100 min-h-screen p-6">
    <h1 class="text-3xl font-bold mb-6">Admin Dashboard</h1>
    
    <!-- Group Proposals Section -->
    <div class="bg-white rounded-lg shadow-md p-6 mb-6">
      <h2 class="text-2xl font-semibold mb-4">Group Proposals</h2>
      <div v-if="groupProposals.length === 0">No pending group proposals.</div>
      <div v-else v-for="proposal in groupProposals" :key="proposal.id" class="border-b border-gray-200 py-4">
        <h3 class="text-xl font-medium">{{ proposal.name }}</h3>
        <p class="text-gray-600">{{ proposal.description }}</p>
        <p class="text-sm text-gray-500">Created by: {{ proposal.creator.username }}</p>
        <p class="text-sm text-gray-500">Target Users: {{ proposal.target_users }}</p>
        <p class="text-sm text-gray-500">Will Moderate: {{ proposal.will_moderate ? 'Yes' : 'No' }}</p>
        <p class="text-sm text-gray-500">Status: {{ proposal.status }}</p>
        <div class="mt-2">
          <button @click="approveProposal(proposal.id)" class="bg-green-500 text-white px-4 py-2 rounded mr-2 hover:bg-green-600">Approve</button>
          <button @click="rejectProposal(proposal.id)" class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">Reject</button>
        </div>
      </div>
    </div>
    
    <!-- User Management Section -->
    <div class="bg-white rounded-lg shadow-md p-6 mb-6">
      <h2 class="text-2xl font-semibold mb-4">User Management</h2>
      <div v-for="user in users" :key="user.id" class="border-b border-gray-200 py-4">
    <h3 class="text-xl font-medium">{{ user.username }}</h3>
    <p class="text-sm text-gray-500">{{ user.email }}</p>
    <div class="mt-2">
      <button v-if="!user.is_suspended" @click="suspendUser(user.id)" class="bg-yellow-500 text-white px-4 py-2 rounded mr-2 hover:bg-yellow-600">Suspend</button>
      <button v-else @click="unsuspendUser(user.id)" class="bg-green-500 text-white px-4 py-2 rounded mr-2 hover:bg-green-600">Unsuspend</button>
      <button v-if="!user.is_banned" @click="banUser(user.id)" class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">Ban</button>
      <button v-else @click="unbanUser(user.id)" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Unban</button>
      <button v-if="!user.is_admin" @click="setAsAdmin(user.id)" class="bg-purple-500 text-white px-4 py-2 rounded ml-2 hover:bg-purple-600">Set as Admin</button>
      <button v-else @click="removeAdmin(user.id)" class="bg-gray-500 text-white px-4 py-2 rounded ml-2 hover:bg-gray-600">Remove Admin</button>
    </div>
  </div>
    </div>

    <!-- Featured Stories Section -->
<div class="bg-white rounded-lg shadow-md p-6 mb-6">
  <h2 class="text-2xl font-semibold mb-4">Featured Stories</h2>

  <input 
    v-model="searchQuery" 
    @input="searchStories"
    placeholder="Search stories..." 
    class="w-full p-2 border rounded mb-4"
  >
  
  <!-- Add new featured story -->
  <div class="mb-6 p-4 bg-gray-100 rounded-lg">
    <h3 class="text-lg font-medium mb-2">Add New Featured Story</h3>
    <div class="flex items-end space-x-2">
      <div class="flex-grow">
        <label class="block text-sm font-medium text-gray-700 mb-1">Story</label>
        <select v-model="newFeaturedStory.id" class="w-full p-2 border rounded">
          <option value="">Select a story</option>
          <option v-for="story in availableStories" :key="story.id" :value="story.id">
            {{ story.title }} by {{ story.author.username }}
          </option>
        </select>
      </div>
      <div class="w-24">
        <label class="block text-sm font-medium text-gray-700 mb-1">Order</label>
        <input v-model.number="newFeaturedStory.order" type="number" placeholder="Order" class="w-full p-2 border rounded">
      </div>
      <button @click="addFeaturedStory" class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
        Add
      </button>
    </div>
  </div>

  <!-- List of current featured stories -->
<div v-if="sortedFeaturedStories.length === 0" class="text-gray-500 italic">No featured stories.</div>
<div v-else class="space-y-4">
  <div v-for="featuredStory in sortedFeaturedStories" :key="featuredStory.id" class="border-b border-gray-200 pb-4">
    <div class="flex justify-between items-center">
      <div class="flex items-center space-x-4">
        <img :src="featuredStory.story.cover_image" alt="Story cover" class="w-16 h-24 object-cover rounded">
        <div>
          <h3 class="text-xl font-medium">{{ featuredStory.story.title }}</h3>
          <p class="text-sm text-gray-500">Author: {{ featuredStory.story.author.username }}</p>
        </div>
      </div>
      <div class="flex items-center space-x-2">
        <input v-model.number="featuredStory.featured_order" type="number" class="w-16 p-2 border rounded text-center">
        <button @click="updateFeaturedStoryOrder(featuredStory.story_id, featuredStory.featured_order)" class="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 text-sm">
          Update
        </button>
        <button @click="confirmRemoveFeaturedStory(featuredStory.story_id)" class="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 text-sm">
          Remove
        </button>
      </div>
    </div>
  </div>
</div>
</div>

    <!-- Challenges Management Section -->
<div class="bg-white rounded-lg shadow-md p-6 mb-6">
  <h2 class="text-2xl font-semibold mb-4">Challenges Management</h2>
  
  <!-- Form to create a new challenge -->
  <form @submit.prevent="createChallenge" class="mb-6">
    <input v-model="newChallenge.title" placeholder="Challenge Title" class="w-full p-2 mb-2 border rounded">
    <textarea v-model="newChallenge.description" placeholder="Challenge Description" class="w-full p-2 mb-2 border rounded"></textarea>
    <input v-model="newChallenge.start_date" type="date" class="w-full p-2 mb-2 border rounded">
    <input v-model="newChallenge.end_date" type="date" class="w-full p-2 mb-2 border rounded">
    <select v-model="newChallenge.status" class="w-full p-2 mb-2 border rounded">
      <option value="draft">Draft</option>
      <option value="active">Active</option>
      <option value="completed">Completed</option>
    </select>
    <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded">Create Challenge</button>
  </form>

  <!-- List of existing challenges -->
  <div v-for="challenge in challenges" :key="challenge.id" class="border-b border-gray-200 py-4">
    <h3 class="text-xl font-medium">{{ challenge.title }}</h3>
    <p>{{ challenge.description }}</p>
    <p>Start Date: {{ formatDate(challenge.start_date) }}</p>
    <p>End Date: {{ formatDate(challenge.end_date) }}</p>
    <p>Status: {{ challenge.status }}</p>
    <button @click="editChallenge(challenge)" class="bg-yellow-500 text-white px-4 py-2 rounded mr-2">Edit</button>
    <button @click="deleteChallenge(challenge.id)" class="bg-red-500 text-white px-4 py-2 rounded">Delete</button>
  </div>
</div>

    <!-- Challenge edit modal -->
<div v-if="editingChallenge" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
  <div class="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
    <h3 class="text-lg font-medium leading-6 text-gray-900 mb-4">Edit Challenge</h3>
    <form @submit.prevent="updateChallenge">
      <input v-model="editingChallenge.title" placeholder="Challenge Title" class="w-full p-2 mb-2 border rounded">
      <textarea v-model="editingChallenge.description" placeholder="Challenge Description" class="w-full p-2 mb-2 border rounded"></textarea>
      <input v-model="editingChallenge.start_date" type="date" class="w-full p-2 mb-2 border rounded">
      <input v-model="editingChallenge.end_date" type="date" class="w-full p-2 mb-2 border rounded">
      <select v-model="editingChallenge.status" class="w-full p-2 mb-2 border rounded">
        <option value="draft">Draft</option>
        <option value="active">Active</option>
        <option value="completed">Completed</option>
      </select>
      
      <h4 class="font-medium mt-4 mb-2">Prompts</h4>
      <div v-for="(prompt, index) in editingChallenge.prompts" :key="index" class="mb-2">
        <input v-model="prompt.genre" placeholder="Genre" class="w-full p-2 mb-1 border rounded">
        <textarea v-model="prompt.description" placeholder="Prompt Description" class="w-full p-2 mb-1 border rounded"></textarea>
        <button @click.prevent="removePrompt(index)" class="text-red-500">Remove Prompt</button>
      </div>
      <button @click.prevent="addPrompt" class="text-blue-500 mb-4">Add Prompt</button>

      <div class="flex justify-end">
        <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded mr-2">Update</button>
        <button @click="cancelEdit" class="bg-gray-300 px-4 py-2 rounded">Cancel</button>
      </div>
    </form>
  </div>
</div>
    
    <!-- Admin Broadcasts Section -->
    <div class="bg-white rounded-lg shadow-md p-6 mb-6">
      <h2 class="text-2xl font-semibold mb-4">Admin Broadcasts</h2>
      <textarea v-model="newBroadcast.content" class="w-full p-2 border rounded mb-2" rows="3" placeholder="Enter broadcast message..."></textarea>
      <input v-model="newBroadcast.expiresAt" type="datetime-local" class="w-full p-2 border rounded mb-2">
      <button @click="createBroadcast" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Post Broadcast</button>
      
      <div v-for="broadcast in broadcasts" :key="broadcast.id" class="mt-4 p-4 bg-gray-100 rounded">
        <p>{{ broadcast.content }}</p>
        <p class="text-sm text-gray-600">Created by: {{ broadcast.creator.username }}</p>
        <p class="text-sm text-gray-600">Created at: {{ formatDate(broadcast.created_at) }}</p>
        <p class="text-sm text-gray-600">Expires: {{ formatDate(broadcast.expires_at) }}</p>
        <p class="text-sm text-gray-600">Active: {{ broadcast.is_active ? 'Yes' : 'No' }}</p>
        <button @click="deleteBroadcast(broadcast.id)" class="mt-2 text-red-600 hover:text-red-800">Delete</button>
      </div>
    </div>
  </div>
  <div v-else>
    <p>You do not have permission to access this page.</p>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { supabase } from '@/supabase';
import { adminService } from '@/services/adminService';
import { notificationService } from '@/services/notificationService';

export default {
  name: 'AdminDashboard',
  setup() {
    const store = useStore();
    const isAdmin = computed(() => store.getters['auth/isAdmin']);
    const currentUser = computed(() => store.getters['auth/currentUser']);
    const groupProposals = ref([]);
    const users = ref([]);
    const broadcasts = ref([]);
    const newBroadcast = ref({ content: '', expiresAt: '' });
    const featuredStories = ref([]);
    const availableStories = ref([]);
    const newFeaturedStory = ref({ id: null, order: null });
    const searchQuery = ref('');
    const challenges = ref([]);
    const editingChallenge = ref(null);

    const newChallenge = ref({
      title: '',
      description: '',
      start_date: '',
      end_date: '',
      status: 'draft',
      prompts: [
        { genre: '', description: '' },
        { genre: '', description: '' },
        { genre: '', description: '' }
      ]
    });

    const sortedFeaturedStories = computed(() => {
      return [...featuredStories.value].sort((a, b) => a.featured_order - b.featured_order);
    });

    const fetchGroupProposals = async () => {
      try {
        groupProposals.value = await adminService.getGroupProposals();
      } catch (error) {
        console.error('Error fetching group proposals:', error);
      }
    };

    const fetchUsers = async () => {
      try {
        users.value = await adminService.getUsers();
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    const fetchBroadcasts = async () => {
      try {
        broadcasts.value = await adminService.getBroadcasts();
      } catch (error) {
        console.error('Error fetching broadcasts:', error);
      }
    };

    const fetchFeaturedStories = async () => {
      try {
        featuredStories.value = await adminService.getFeaturedStories();
      } catch (error) {
        console.error('Error fetching featured stories:', error);
      }
    };

    const fetchAvailableStories = async () => {
      try {
        availableStories.value = await adminService.getAvailableStories();
      } catch (error) {
        console.error('Error fetching available stories:', error);
      }
    };

    const searchStories = async () => {
      if (searchQuery.value.length > 2) {
        try {
          availableStories.value = await adminService.searchStories(searchQuery.value);
        } catch (error) {
          console.error('Error searching stories:', error.message);
          // Add user feedback here
        }
      } else {
        fetchAvailableStories();
      }
    };

    const approveProposal = async (proposalId) => {
      try {
        await adminService.approveGroupProposal(proposalId);
        await fetchGroupProposals();
      } catch (error) {
        console.error('Error approving proposal:', error);
      }
    };

    const rejectProposal = async (proposalId) => {
      try {
        await adminService.rejectGroupProposal(proposalId);
        await fetchGroupProposals();
      } catch (error) {
        console.error('Error rejecting proposal:', error);
      }
    };

    const suspendUser = async (userId) => {
      try {
        console.log('Attempting to suspend user:', userId);
        const updatedUser = await adminService.suspendUser(userId);
        console.log('User suspended:', updatedUser);
        users.value = users.value.map(user => user.id === userId ? updatedUser : user);
      } catch (error) {
        console.error('Error suspending user:', error.message);
        alert(`Failed to suspend user: ${error.message}`);
      }
    };

    const unsuspendUser = async (userId) => {
      try {
        console.log('Attempting to unsuspend user:', userId);
        const updatedUser = await adminService.unsuspendUser(userId);
        console.log('User unsuspended:', updatedUser);
        users.value = users.value.map(user => user.id === userId ? updatedUser : user);
      } catch (error) {
        console.error('Error unsuspending user:', error.message);
        alert(`Failed to unsuspend user: ${error.message}`);
      }
    };

    const banUser = async (userId) => {
      try {
        console.log('Attempting to ban user:', userId);
        const updatedUser = await adminService.banUser(userId);
        console.log('User banned:', updatedUser);
        users.value = users.value.map(user => user.id === userId ? updatedUser : user);
      } catch (error) {
        console.error('Error banning user:', error.message);
        alert(`Failed to ban user: ${error.message}`);
      }
    };

    const unbanUser = async (userId) => {
      try {
        console.log('Attempting to unban user:', userId);
        const updatedUser = await adminService.unbanUser(userId);
        console.log('User unbanned:', updatedUser);
        users.value = users.value.map(user => user.id === userId ? updatedUser : user);
      } catch (error) {
        console.error('Error unbanning user:', error.message);
        alert(`Failed to unban user: ${error.message}`);
      }
    };

    const setAsAdmin = async (userId) => {
      try {
        console.log('Attempting to set user as admin:', userId);
        const updatedUser = await adminService.setUserAsAdmin(userId);
        console.log('User set as admin:', updatedUser);
        users.value = users.value.map(user => user.id === userId ? updatedUser : user);
      } catch (error) {
        console.error('Error setting user as admin:', error.message);
        alert(`Failed to set user as admin: ${error.message}`);
      }
    };

    const removeAdmin = async (userId) => {
      try {
        console.log('Attempting to remove admin status from user:', userId);
        const updatedUser = await adminService.removeUserAsAdmin(userId);
        console.log('Admin status removed from user:', updatedUser);
        users.value = users.value.map(user => user.id === userId ? updatedUser : user);
      } catch (error) {
        console.error('Error removing admin status from user:', error.message);
        alert(`Failed to remove admin status: ${error.message}`);
      }
    };

    const createBroadcast = async () => {
      try {
        const broadcast = await adminService.createBroadcast(
          newBroadcast.value.content,
          newBroadcast.value.expiresAt,
          currentUser.value.id
        );
        
        // Notify all users of the new broadcast
        await notificationService.notifyAllUsersOfBroadcast(broadcast.content.substring(0, 50) + '...', broadcast.id);

        newBroadcast.value = { content: '', expiresAt: '' };
        await fetchBroadcasts();
      } catch (error) {
        console.error('Error creating broadcast:', error);
      }
    };

    const deleteBroadcast = async (id) => {
      try {
        await adminService.deleteBroadcast(id);
        await fetchBroadcasts();
      } catch (error) {
        console.error('Error deleting broadcast:', error);
      }
    };

    const addFeaturedStory = async () => {
      if (!newFeaturedStory.value.id || !newFeaturedStory.value.order) {
        console.error('Both story and order are required');
        return;
      }
      try {
        await adminService.addFeaturedStory(newFeaturedStory.value.id, newFeaturedStory.value.order);
        await fetchFeaturedStories();
        newFeaturedStory.value = { id: null, order: null };
      } catch (error) {
        console.error('Error adding featured story:', error);
      }
    };

    const removeFeaturedStory = async (storyId) => {
      try {
        await adminService.removeFeaturedStory(storyId);
        await fetchFeaturedStories();
      } catch (error) {
        console.error('Error removing featured story:', error);
      }
    };

    const updateFeaturedStoryOrder = async (storyId, newOrder) => {
      try {
        await adminService.updateFeaturedStoryOrder(storyId, newOrder);
        await fetchFeaturedStories();
      } catch (error) {
        console.error('Error updating featured story order:', error);
      }
    };

    const fetchChallenges = async () => {
      const { data, error } = await supabase
        .from('challenges')
        .select(`
          *,
          prompts:challenge_prompts(*)
        `)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching challenges:', error);
      } else {
        challenges.value = data;
      }
    };

    const createChallenge = async () => {
      const { data: challengeData, error: challengeError } = await supabase
        .from('challenges')
        .insert([{
          title: newChallenge.value.title,
          description: newChallenge.value.description,
          start_date: newChallenge.value.start_date,
          end_date: newChallenge.value.end_date,
          status: newChallenge.value.status
        }])
        .select()
        .single();

      if (challengeError) {
        console.error('Error creating challenge:', challengeError);
        return;
      }

      const promptsToInsert = newChallenge.value.prompts.map(prompt => ({
        challenge_id: challengeData.id,
        genre: prompt.genre,
        description: prompt.description
      }));

      const { error: promptsError } = await supabase
        .from('challenge_prompts')
        .insert(promptsToInsert);

      if (promptsError) {
        console.error('Error creating challenge prompts:', promptsError);
        return;
      }

      await fetchChallenges();
      resetNewChallenge();
    };

    const editChallenge = (challenge) => {
      editingChallenge.value = JSON.parse(JSON.stringify(challenge));
    };

    const updateChallenge = async () => {
      const { error } = await supabase
        .from('challenges')
        .update({
          title: editingChallenge.value.title,
          description: editingChallenge.value.description,
          start_date: editingChallenge.value.start_date,
          end_date: editingChallenge.value.end_date,
          status: editingChallenge.value.status
        })
        .eq('id', editingChallenge.value.id);

      if (error) {
        console.error('Error updating challenge:', error);
        return;
      }

      // Update prompts
      for (const prompt of editingChallenge.value.prompts) {
        if (prompt.id) {
          await supabase
            .from('challenge_prompts')
            .update({
              genre: prompt.genre,
              description: prompt.description
            })
            .eq('id', prompt.id);
        } else {
          await supabase
            .from('challenge_prompts')
            .insert({
              challenge_id: editingChallenge.value.id,
              genre: prompt.genre,
              description: prompt.description
            });
        }
      }

      await fetchChallenges();
      editingChallenge.value = null;
    };

    const deleteChallenge = async (id) => {
      const { error: promptsError } = await supabase
        .from('challenge_prompts')
        .delete()
        .eq('challenge_id', id);

      if (promptsError) {
        console.error('Error deleting challenge prompts:', promptsError);
        return;
      }

      const { error: challengeError } = await supabase
        .from('challenges')
        .delete()
        .eq('id', id);

      if (challengeError) {
        console.error('Error deleting challenge:', challengeError);
        return;
      }

      await fetchChallenges();
    };

    const resetNewChallenge = () => {
      newChallenge.value = {
        title: '',
        description: '',
        start_date: '',
        end_date: '',
        status: 'draft',
        prompts: [
          { genre: '', description: '' },
          { genre: '', description: '' },
          { genre: '', description: '' }
        ]
      };
    };

    const cancelEdit = () => {
      editingChallenge.value = null;
    };

    const addPrompt = () => {
      editingChallenge.value.prompts.push({ genre: '', description: '' });
    };

    const removePrompt = (index) => {
      editingChallenge.value.prompts.splice(index, 1);
    };

    const confirmRemoveFeaturedStory = async (storyId) => {
      if (confirm('Are you sure you want to remove this story from featured?')) {
        await removeFeaturedStory(storyId);
      }
    };

    const formatDate = (date) => {
      return new Date(date).toLocaleDateString('en-US', { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    };

    onMounted(() => {
      fetchGroupProposals();
      fetchUsers();
      fetchBroadcasts();
      fetchFeaturedStories();
      fetchAvailableStories();
      fetchChallenges();
    });

    watch(searchQuery, () => {
      searchStories();
    });

    return {
      isAdmin,
      groupProposals,
      users,
      broadcasts,
      newBroadcast,
      featuredStories,
      availableStories,
      newFeaturedStory,
      searchQuery,
      sortedFeaturedStories,
      challenges,
      newChallenge,
      editingChallenge,
      approveProposal,
      rejectProposal,
      suspendUser,
      unsuspendUser,
      banUser,
      unbanUser,
      setAsAdmin,
      removeAdmin,
      createBroadcast,
      deleteBroadcast,
      addFeaturedStory,
      removeFeaturedStory,
      updateFeaturedStoryOrder,
      confirmRemoveFeaturedStory,
      createChallenge,
      editChallenge,
      updateChallenge,
      deleteChallenge,
      cancelEdit,
      addPrompt,
      removePrompt,
      formatDate,
      searchStories,
    };
  }
};
</script>