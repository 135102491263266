<template>
  <div class="create-post-page bg-gray-100 min-h-screen p-4 md:p-8">
    <div class="max-w-4xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
      <div class="bg-gradient-to-r from-indigo-600 to-purple-600 p-6 text-white">
        <h1 class="text-3xl font-bold">Create New Post in {{ groupName }}</h1>
      </div>
      <form @submit.prevent="submitPost" class="p-6">
        <div class="mb-6">
          <label for="title" class="block text-sm font-medium text-gray-700 mb-2">Title</label>
          <input
            type="text"
            id="title"
            v-model="title"
            required
            class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            placeholder="Enter an engaging title for your post"
          >
        </div>
        <div class="mb-6">
          <label for="content" class="block text-sm font-medium text-gray-700 mb-2">Content</label>
          <div id="editor" class="border border-gray-300 rounded-md"></div>
        </div>
        <div class="mb-6">
          <label for="tags" class="block text-sm font-medium text-gray-700 mb-2">Tags</label>
          <div class="flex flex-wrap gap-2">
            <div v-for="tag in selectedTags" :key="tag" class="bg-indigo-100 text-indigo-800 px-3 py-1 rounded-full text-sm flex items-center">
              {{ tag }}
              <button @click="removeTag(tag)" class="ml-2 text-indigo-600 hover:text-indigo-800">&times;</button>
            </div>
            <input
              type="text"
              v-model="newTag"
              @keydown.enter.prevent="addTag"
              placeholder="Add a tag"
              class="px-3 py-1 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
          </div>
        </div>
        <div class="mb-6">
          <label for="category" class="block text-sm font-medium text-gray-700 mb-2">Category</label>
          <select
            id="category"
            v-model="category"
            class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            <option value="">Select a category</option>
            <option value="discussion">Discussion</option>
            <option value="question">Question</option>
            <option value="showcase">Showcase</option>
            <option value="resource">Resource</option>
          </select>
        </div>
        <div class="flex items-center mb-6">
          <input type="checkbox" id="notifications" v-model="notifications" class="mr-2">
          <label for="notifications" class="text-sm text-gray-700">Receive notifications for comments</label>
        </div>
        <div class="flex justify-between items-center">
          <button
            type="button"
            @click="goBack"
            class="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition duration-300"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="bg-indigo-600 text-white px-6 py-2 rounded-md hover:bg-indigo-700 transition duration-300 flex items-center"
            :disabled="isSubmitting"
          >
            <span v-if="!isSubmitting">Create Post</span>
            <span v-else class="flex items-center">
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Submitting...
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { groupService } from '@/services/groupService';

export default {
  name: 'CreatePostPage',
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const groupId = route.params.groupId;
    const groupName = ref('');
    const title = ref('');
    const content = ref('');
    const selectedTags = ref([]);
    const newTag = ref('');
    const category = ref('');
    const notifications = ref(true);
    const isSubmitting = ref(false);
    let quill;

    onMounted(async () => {
      try {
        const groupDetails = await groupService.getGroupDetails(groupId);
        groupName.value = groupDetails.name;
      } catch (error) {
        console.error('Error fetching group details:', error);
        groupName.value = 'Unknown Group';
      }

      quill = new Quill('#editor', {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean'],
            ['link', 'image', 'video']
          ]
        }
      });

      quill.on('text-change', () => {
        content.value = quill.root.innerHTML;
      });
    });

    const addTag = () => {
      if (newTag.value && !selectedTags.value.includes(newTag.value)) {
        selectedTags.value.push(newTag.value);
        newTag.value = '';
      }
    };

    const removeTag = (tag) => {
      selectedTags.value = selectedTags.value.filter(t => t !== tag);
    };

    const submitPost = async () => {
      if (isSubmitting.value) return;
      isSubmitting.value = true;
      try {
        const userId = store.getters['auth/getCurrentUserId'];
        if (!userId) {
          throw new Error('User not logged in');
        }
        const newPost = await groupService.createGroupPost(
          groupId,
          userId,
          title.value,
          content.value,
          {
            tags: selectedTags.value,
            category: category.value,
            notifications: notifications.value,
          }
        );
        if (newPost) {
          // Navigate to the newly created post
          router.push({ name: 'GroupPost', params: { groupId: groupId, postId: newPost.id } });
        } else {
          throw new Error('Failed to create post');
        }
      } catch (error) {
        console.error('Error creating post:', error);
        // You might want to show an error message to the user here
      } finally {
        isSubmitting.value = false;
      }
    };

    const goBack = () => {
      router.push({ name: 'GroupDetails', params: { id: groupId } });
    };

    return {
      groupName,
      title,
      content,
      selectedTags,
      newTag,
      category,
      notifications,
      isSubmitting,
      addTag,
      removeTag,
      submitPost,
      goBack,
    };
  },
};
</script>

<style>
.ql-editor {
  min-height: 200px;
}
</style>