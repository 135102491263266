<template>
  <div class="bg-gray-100 min-h-screen">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div class="flex flex-col lg:flex-row gap-8">
        <!-- Left Sidebar -->
        <div class="lg:w-1/4">
          <!-- Profile Card -->
          <div class="bg-white rounded-lg shadow-md overflow-hidden mb-6">
            <div class="bg-gradient-to-r from-blue-500 to-purple-600 h-32 relative">
              <img :src="user.avatar_url" :alt="user.name" class="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 w-24 h-24 rounded-full border-4 border-white" />
            </div>
            <div class="pt-16 px-6 pb-6 text-center">
              <h1 class="text-2xl font-bold">{{ user.name }}</h1>
              <p class="text-gray-600 mt-1">@{{ user.username }}</p>
              <p class="text-gray-600 mt-1">{{ user.tagline }}</p>
              <div class="flex justify-center mt-4 space-x-4">
                <div class="cursor-pointer" @click="viewFollowers">
                  <p class="font-semibold">{{ user.followers }}</p>
                  <p class="text-sm text-gray-600">Followers</p>
                </div>
                <div class="cursor-pointer" @click="viewFollowing">
                  <p class="font-semibold">{{ user.following }}</p>
                  <p class="text-sm text-gray-600">Following</p>
                </div>
                <div>
                  <p class="font-semibold">{{ user.stories }}</p>
                  <p class="text-sm text-gray-600">Stories</p>
                </div>
              </div>
              <button 
                @click="followUser" 
                class="mt-6 bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition duration-300 w-full"
              >
                {{ isFollowing ? 'Unfollow' : 'Follow' }}
              </button>
              <button 
                @click="openMessageBox"
                class="mt-2 bg-green-600 text-white px-4 py-2 rounded-full hover:bg-green-700 transition duration-300 w-full inline-block"
              >
                Send Message
              </button>
            </div>
          </div>

          <!-- Writing Stats -->
          <div class="bg-white rounded-lg shadow-md p-6 mb-6">
            <h2 class="text-xl font-bold mb-4">Writing Stats</h2>
            <div class="space-y-4">
              <div class="flex justify-between items-center">
                <span class="text-gray-600">Total Words</span>
                <span class="font-semibold">{{ user.stats.totalWords.toLocaleString() }}</span>
              </div>
              <div class="flex justify-between items-center">
                <span class="text-gray-600">Stories Published</span>
                <span class="font-semibold">{{ user.stats.storiesPublished }}</span>
              </div>
              <div class="flex justify-between items-center">
                <span class="text-gray-600">Total Chapters</span>
                <span class="font-semibold">{{ user.stats.totalChapters }}</span>
              </div>
            </div>
          </div>

          <!-- Achievements -->
          <div class="bg-white rounded-lg shadow-md p-6">
            <h2 class="text-xl font-bold mb-4">Achievements</h2>
            <div class="grid grid-cols-3 gap-4">
              <div v-for="achievement in user.achievements" :key="achievement.id" class="text-center">
                <img :src="achievement.icon" :alt="achievement.name" class="w-12 h-12 mx-auto mb-2" />
                <p class="text-xs font-semibold">{{ achievement.name }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Main Content -->
        <div class="lg:flex-1">
          <div class="bg-white rounded-lg shadow-md overflow-hidden">
            <div class="flex border-b">
              <button
                v-for="tab in visibleTabs"
                :key="tab"
                @click="currentTab = tab"
                class="px-4 py-2 font-medium"
                :class="{ 'text-blue-600 border-b-2 border-blue-600': currentTab === tab }"
              >
                {{ tab }}
              </button>
            </div>

            <!-- About and Books -->
            <div v-if="currentTab === 'About'" class="p-6">
              <div class="mb-8">
                <div class="flex justify-between items-center mb-4">
                  <h2 class="text-2xl font-bold">About Me</h2>
                  <button @click="toggleAboutMe" class="text-blue-600 hover:underline">
                    {{ showAboutMe ? 'Hide' : 'Show' }}
                  </button>
                </div>
                <p v-if="showAboutMe">{{ user.bio }}</p>
              </div>

              <div class="mb-8">
                <h2 class="text-2xl font-bold mb-4">Published Books</h2>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  <div v-for="story in user.publishedStories" :key="story.id" class="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer" @click="viewBook(story.id)">
                    <img :src="story.cover" :alt="story.title" class="w-full h-48 object-cover" />
                    <div class="p-4">
                      <h3 class="font-semibold text-lg mb-2">{{ story.title }}</h3>
                      <p class="text-gray-600 mb-2">{{ story.description }}</p>
                      <span class="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-xs">{{ story.genre }}</span>
                      <div class="mt-3 flex items-center text-sm text-gray-500">
                        <EyeIcon class="w-4 h-4 mr-1" />
                        <span>{{ story.reads.toLocaleString() }} reads</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div class="flex justify-between items-center mb-4">
                  <h2 class="text-2xl font-bold">Message Board</h2>
                </div>
                <!-- Pinned Announcements -->
                <div v-if="user.pinnedAnnouncements.length > 0" class="mb-6">
                  <h3 class="text-xl font-semibold mb-2">Pinned Announcements</h3>
                  <div v-for="announcement in user.pinnedAnnouncements" :key="announcement.id" class="bg-yellow-100 p-4 rounded-lg mb-2">
                    <div v-html="announcement.content"></div>
                    <div class="text-sm text-gray-500 mt-2">{{ announcement.createdAt }}</div>
                  </div>
                </div>
                <!-- New message form -->
                <div class="mb-6">
                  <form @submit.prevent="addMessage" class="bg-gray-100 p-4 rounded-lg">
                    <textarea
                      v-model="newMessage"
                      rows="3"
                      class="w-full border rounded-lg p-2 mb-2"
                      placeholder="Write a message..."
                    ></textarea>
                    <button
                      type="submit"
                      class="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-300"
                    >
                      Post Message
                    </button>
                  </form>
                </div>
                <div class="space-y-6 mb-6">
                  <div v-for="message in user.messages" :key="message.id" class="bg-white p-4 rounded-lg shadow-md">
                    <div class="flex items-center justify-between mb-2">
                      <div class="flex items-center">
                        <img :src="message.author.avatar" :alt="message.author.name" class="w-10 h-10 rounded-full mr-3" />
                        <div>
                          <router-link :to="{ name: 'VisitedUserProfile', params: { username: message.author.username } }" class="font-semibold text-lg hover:underline">{{ message.author.name }}</router-link>
                          <p class="text-gray-500 text-sm">{{ message.createdAt }}</p>
                        </div>
                      </div>
                    </div>
                    <div v-html="message.content" class="text-gray-800 mt-2 mb-4"></div>
                    <div class="space-y-4 ml-8 border-l-2 border-gray-200 pl-4">
                      <div v-for="reply in message.replies" :key="reply.id" class="bg-gray-50 p-3 rounded-lg">
                        <div class="flex items-center justify-between mb-2">
                          <div class="flex items-center">
                            <img :src="reply.author.avatar" :alt="reply.author.name" class="w-8 h-8 rounded-full mr-2" />
                            <div>
                              <router-link :to="{ name: 'VisitedUserProfile', params: { username: reply.author.username } }" class="font-semibold hover:underline">{{ reply.author.name }}</router-link>
                              <p class="text-gray-500 text-xs">{{ reply.createdAt }}</p>
                            </div>
                          </div>
                        </div>
                        <p class="text-gray-800">{{ reply.content }}</p>
                        <button @click="showReplyForm(reply.id)" class="text-blue-600 hover:underline text-sm mt-2">Reply</button>
                      </div>
                    </div>
                    <div class="mt-4">
                      <button @click="showReplyForm(message.id)" class="text-blue-600 hover:underline">Reply</button>
                    </div>
                    <div v-if="replyingToMessageId === message.id" class="mt-2">
                      <textarea
                        v-model="newReply"
                        rows="2"
                        class="w-full border rounded-lg p-2 mb-2"
                        placeholder="Write a reply..."
                      ></textarea>
                      <button
                        @click="addReply(message.id)"
                        class="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-300"
                      >
                        Submit Reply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Bookshelf -->
            <div v-if="currentTab === 'Bookshelf'" class="p-6">
              <h2 class="text-2xl font-bold mb-4">Bookshelf</h2>
              <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                <div v-for="book in user.bookshelves" :key="book.id" class="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer" @click="viewBook(book.id)">
                  <img :src="book.cover" :alt="book.title" class="w-full h-48 object-cover" />
                  <div class="p-4">
                    <h3 class="font-semibold text-lg mb-2">{{ book.title }}</h3>
                    <p class="text-gray-600">{{ book.author }}</p>
                    <span :class="{
                      'bg-green-100 text-green-800': book.status === 'Read',
                      'bg-yellow-100 text-yellow-800': book.status === 'Currently Reading',
                      'bg-red-100 text-red-800': book.status === 'Want to Read'
                    }" class="px-2 py-1 rounded-full text-xs inline-block mt-2">{{ book.status }}</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Activity Tab Content -->
            <div v-if="currentTab === 'Activity'" class="p-6">
              <h2 class="text-2xl font-bold mb-4">Recent Activity</h2>
              <ul class="space-y-4">
                <li v-for="activity in user.activityFeed" :key="activity.id" class="border-b pb-4 last:border-b-0">
                  <div class="flex items-center">
                    <span class="mr-2">
                      <HeartIcon v-if="activity.type === 'vote'" class="w-5 h-5 text-red-500" />
                      <MessageSquareIcon v-if="activity.type === 'comment'" class="w-5 h-5 text-blue-500" />
                      <UserPlusIcon v-if="activity.type === 'follow'" class="w-5 h-5 text-green-500" />
                      <UserIcon v-if="activity.type === 'recommendation_invite'" class="w-5 h-5 text-purple-500" />
                      <BookmarkIcon v-if="activity.type === 'added_to_list'" class="w-5 h-5 text-yellow-500" />
                    </span>
                    <span>
                      {{ activity.description }}
                      <router-link :to="activity.link" class="text-blue-600 hover:underline">
                        {{ activity.linkText }}
                      </router-link>
                      <span class="text-gray-500 text-sm ml-2">{{ activity.time }}</span>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { EyeIcon, HeartIcon, MessageSquareIcon, UserPlusIcon, UserIcon, BookmarkIcon } from 'lucide-vue-next';
import { supabase } from '@/supabase';

export default {
  name: 'VisitedUserProfile',
  props: {
    username: {
      type: String,
      required: true
    }
  },
  components: {
    EyeIcon,
    HeartIcon,
    MessageSquareIcon,
    UserPlusIcon,
    UserIcon,
    BookmarkIcon
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();

    const currentUser = computed(() => store.getters['auth/currentUser']);
    
    const user = reactive({
      id: '',
      username: '',
      name: '',
      tagline: '',
      avatar_url: '',
      bio: '',
      followers: 0,
      following: 0,
      stories: 0,
      stats: {
        totalWords: 0,
        storiesPublished: 0,
        totalChapters: 0
      },
      achievements: [],
      publishedStories: [],
      bookshelves: [],
      activityFeed: [],
      messages: [],
      pinnedAnnouncements: [],
    });

    const visibleTabs = ref(['About', 'Bookshelf', 'Activity']);
    const currentTab = ref('About');
    const showAboutMe = ref(false);
    const isFollowing = ref(false);
    const replyingToMessageId = ref(null);
    const newReply = ref('');
    const newMessage = ref('');

    const fetchUserProfile = async () => {
      try {
        const { data, error } = await supabase
          .from('users')
          .select('*')
          .eq('username', props.username)
          .single();

        if (error) throw error;

        if (data) {
          Object.assign(user, data);
          await Promise.all([
            fetchPublishedStories(),
            fetchBookshelves(),
            fetchActivityFeed(),
            fetchMessages(),
            fetchPinnedAnnouncements(),
          ]);
        } else {
          throw new Error('User not found');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    const fetchPublishedStories = async () => {
      try {
        const { data, error } = await supabase
          .from('stories')
          .select('*')
          .eq('author_id', user.id)
          .eq('status', 'published')
          .order('created_at', { ascending: false });

        if (error) throw error;
        user.publishedStories = data;
      } catch (error) {
        console.error('Error fetching stories:', error);
      }
    };

    const fetchBookshelves = async () => {
      try {
        const { data, error } = await supabase
          .from('bookshelves')
          .select('*')
          .eq('user_id', user.id);

        if (error) throw error;
        user.bookshelves = data;
      } catch (error) {
        console.error('Error fetching bookshelves:', error);
      }
    };

    const fetchActivityFeed = async () => {
      try {
        const { data, error } = await supabase
          .from('user_activity')
          .select('*')
          .eq('user_id', user.id)
          .order('created_at', { ascending: false })
          .limit(10);

        if (error) throw error;
        user.activityFeed = data;
      } catch (error) {
        console.error('Error fetching activity feed:', error);
      }
    };

    const fetchMessages = async () => {
      try {
        const { data, error } = await supabase
          .from('profile_messages')
          .select(`
            *,
            author:users!author_id(name, avatar),
            replies:profile_message_replies(
              *,
              author:users!author_id(name, avatar)
            )
          `)
          .eq('profile_id', user.id)
          .order('created_at', { ascending: false });

        if (error) throw error;
        user.messages = data;
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    const fetchPinnedAnnouncements = async () => {
      try {
        const { data, error } = await supabase
          .from('announcements')
          .select('*')
          .eq('user_id', user.id)
          .eq('is_pinned', true)
          .order('created_at', { ascending: false });

        if (error) throw error;
        user.pinnedAnnouncements = data;
      } catch (error) {
        console.error('Error fetching pinned announcements:', error);
      }
    };

    const toggleAboutMe = () => {
      showAboutMe.value = !showAboutMe.value;
    };

    const followUser = async () => {
      try {
        if (isFollowing.value) {
          const { error } = await supabase
            .from('followers')
            .delete()
            .eq('follower_id', currentUser.value.id)
            .eq('followed_id', user.id);

          if (error) throw error;
          user.followers--;
        } else {
          const { error } = await supabase
            .from('followers')
            .insert({
              follower_id: currentUser.value.id,
              followed_id: user.id
            });

          if (error) throw error;
          user.followers++;
        }
        isFollowing.value = !isFollowing.value;
      } catch (error) {
        console.error('Error following/unfollowing user:', error);
      }
    };

    const openMessageBox = () => {
      router.push({ 
        name: 'Messages', 
        params: { userId: user.id },
        query: { new: 'true' }
      });
    };

    const showReplyForm = (messageId) => {
      replyingToMessageId.value = messageId;
    };

    const addReply = async (messageId) => {
      if (newReply.value.trim()) {
        try {
          const { data, error } = await supabase
            .from('profile_message_replies')
            .insert({
              message_id: messageId,
              author_id: currentUser.value.id,
              content: newReply.value
            })
            .select(`
              *,
              author:users!author_id(name, avatar)
            `)
            .single();

          if (error) throw error;

          const message = user.messages.find(m => m.id === messageId);
          if (message) {
            message.replies.push(data);
          }
          newReply.value = '';
          replyingToMessageId.value = null;
        } catch (error) {
          console.error('Error adding reply:', error);
        }
      }
    };

    const addMessage = async () => {
      if (newMessage.value.trim()) {
        try {
          const { data, error } = await supabase
            .from('profile_messages')
            .insert({
              profile_id: user.id,
              author_id: currentUser.value.id,
              content: newMessage.value
            })
            .select(`
              *,
              author:users!author_id(name, avatar)
            `)
            .single();

          if (error) throw error;

          user.messages.unshift({
            ...data,
            replies: []
          });
          newMessage.value = '';
        } catch (error) {
          console.error('Error adding message:', error);
        }
      }
    };

    const viewBook = (bookId) => {
      router.push({ name: 'StoryPage', params: { id: bookId } });
    };

    const viewFollowers = () => {
      router.push({ name: 'Followers', params: { username: user.username } });
    };

    const viewFollowing = () => {
      router.push({ name: 'Following', params: { username: user.username } });
    };

    onMounted(async () => {
      await store.dispatch('auth/checkAuth');
      await fetchUserProfile();
      const { data } = await supabase
        .from('followers')
        .select()
        .eq('follower_id', currentUser.value.id)
        .eq('followed_id', user.id)
        .single();
      isFollowing.value = !!data;
    });

    watch(() => props.username, fetchUserProfile);

    return {
      user,
      visibleTabs,
      currentTab,
      currentUser,
      showAboutMe,
      isFollowing,
      replyingToMessageId,
      newReply,
      newMessage,
      toggleAboutMe,
      followUser,
      openMessageBox,
      showReplyForm,
      addReply,
      addMessage,
      viewBook,
      viewFollowers,
      viewFollowing
    };
  }
};
</script>

<style scoped>
::v-deep .ql-editor {
  min-height: 100px;
  max-height: 200px;
  overflow-y: auto;
}

::v-deep .ql-toolbar {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

::v-deep .ql-container {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
</style>