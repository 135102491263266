// src/store/index.js

import { createStore } from 'vuex';
import storyModule from './modules/story';
import authModule from './modules/auth';

export default createStore({
  modules: {
    story: storyModule,
    auth: authModule,
    // Other modules if any
  }
});