<template>
  <div class="chapter-page bg-gray-100 min-h-screen" @click="closeDropdowns">
    <header class="bg-gradient-to-r from-purple-600 to-indigo-600 text-white shadow-md p-4">
      <div class="max-w-4xl mx-auto flex justify-between items-center">
        <button @click="goToStoryPage" class="hover:text-gray-200 flex items-center">
          <ArrowLeftIcon class="w-6 h-6 mr-2" />
          <span class="text-lg font-semibold">{{ story.title }}</span>
        </button>
        <div class="relative">
          <button @click.stop="toggleChapterList" class="flex items-center text-lg font-semibold bg-white text-indigo-600 px-4 py-2 rounded-full hover:bg-gray-100 transition duration-300">
            {{ chapter.title }}
            <ChevronDownIcon class="ml-2 w-5 h-5" />
          </button>
          <div v-if="showChapterList" class="absolute top-full right-0 mt-2 w-64 bg-white rounded-md shadow-xl z-10">
            <div v-for="chap in story.chapters" :key="chap.id" 
                 @click="goToChapter(chap.id)"
                 class="p-2 hover:bg-gray-100 cursor-pointer text-gray-800">
              {{ chap.title }}
            </div>
          </div>
        </div>
      </div>
    </header>

    <main class="max-w-4xl mx-auto mt-8 flex relative">
      <div class="w-3/4 bg-white shadow-lg rounded-lg overflow-hidden mr-4">
        <div class="p-6">
          <div class="flex justify-between items-center mb-4">
            <h1 class="text-3xl font-bold">{{ chapter.title }}</h1>
            <button v-if="!isAuthor" @click="applaudChapter" class="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition duration-300 flex items-center">
              <HandIcon class="w-5 h-5 mr-2" />
              Applaud
            </button>
          </div>

          <div v-if="chapter.shoutout" class="mb-4 bg-purple-100 p-4 rounded-lg">
            <p class="font-semibold">Shoutout to: 
              <a :href="`/user/${chapter.shoutout.userId}`" class="text-indigo-600 hover:text-indigo-800">
                {{ chapter.shoutout.username }}
              </a>
            </p>
            <p class="text-sm text-gray-600 mt-1">{{ chapter.shoutout.message }}</p>
          </div>

          <div class="prose max-w-none">
            <p v-for="(paragraph, index) in chapterParagraphs" :key="index" class="relative mb-4">
              {{ paragraph }}
              <span v-if="paragraphHasInlineComment(index)" 
                    @click="showInlineComment(index)" 
                    class="inline-comment-icon cursor-pointer text-blue-500 ml-2">
                💬
              </span>
            </p>
          </div>
        </div>

        <div class="bg-gray-100 p-4 flex justify-between items-center">
          <button v-if="previousChapterId" @click="goToChapter(previousChapterId)" class="text-indigo-600 hover:text-indigo-800 flex items-center">
            <ArrowLeftIcon class="w-5 h-5 mr-1" />
            Previous Chapter
          </button>
          <div class="flex space-x-8">
            <div class="text-center">
              <EyeIcon class="w-6 h-6 mx-auto text-indigo-600" />
              <p class="font-bold text-2xl text-indigo-600">{{ chapter.reads }}</p>
              <p class="text-sm text-gray-600">Reads</p>
            </div>
            <div class="text-center">
              <HandIcon class="w-6 h-6 mx-auto text-green-600" />
              <p class="font-bold text-2xl text-green-600">{{ chapter.applause }}</p>
              <p class="text-sm text-gray-600">Applause</p>
            </div>
            <div class="text-center">
              <MessageSquareIcon class="w-6 h-6 mx-auto text-purple-600" />
              <p class="font-bold text-2xl text-purple-600">{{ chapter.comments.length }}</p>
              <p class="text-sm text-gray-600">Comments</p>
            </div>
          </div>
          <button v-if="nextChapterId" @click="goToChapter(nextChapterId)" class="text-indigo-600 hover:text-indigo-800 flex items-center">
            Next Chapter
            <ArrowRightIcon class="w-5 h-5 ml-1" />
          </button>
        </div>
      </div>

      <div class="w-1/4">
        <div class="bg-white rounded-lg shadow-md p-4 mb-4">
          <div class="flex items-center space-x-4">
            <img :src="story.author.avatar_url" :alt="story.author.name" class="w-16 h-16 rounded-full" />
            <div>
              <p class="font-semibold text-lg">{{ story.author.name }}</p>
              <button v-if="!isAuthor" @click="toggleFollowAuthor" class="mt-2 bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition duration-300">
                {{ isFollowing ? 'Unfollow' : 'Follow' }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="isAuthor" class="bg-white rounded-lg shadow-md p-4">
          <button @click="toggleShoutoutModal" class="w-full bg-purple-500 text-white px-4 py-2 rounded-full hover:bg-purple-600 transition duration-300">
            Add Shoutout
          </button>
        </div>

        <!-- Inline Comment Sidebar -->
        <div v-if="selectedInlineComment" class="bg-white rounded-lg shadow-md p-4 mt-4">
          <h3 class="font-bold mb-2">Inline Comment</h3>
          <p class="text-sm text-gray-600 mb-2">{{ selectedInlineComment.text }}</p>
          <p>{{ selectedInlineComment.comment }}</p>
        </div>
      </div>
    </main>

    <div class="mt-8 bg-white shadow-lg rounded-lg p-6 max-w-4xl mx-auto">
      <h3 class="text-2xl font-bold mb-4 text-gray-800">Comments</h3>

      <!-- Add Comment Form -->
      <div class="mb-6">
        <textarea v-model="newComment" rows="3" class="w-full border rounded-md p-2" placeholder="Add a comment..."></textarea>
        <button @click="addComment" class="mt-2 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700">
          Post Comment
        </button>
      </div>

      <!-- Existing Comments -->
      <div class="space-y-4">
        <div v-for="comment in chapter.comments" :key="comment.id" class="border-b pb-2">
          <div class="flex items-center mb-2">
            <img :src="comment.author.avatar_url" :alt="comment.author.name" class="w-8 h-8 rounded-full mr-2">
            <span class="font-bold">{{ comment.author.name }}</span>
            <span class="text-gray-500 text-sm ml-2">{{ formatDate(comment.created_at) }}</span>
          </div>
          <p>{{ comment.content }}</p>
          <button @click="toggleReplyForm(comment.id)" class="text-blue-500 text-sm mt-1">Reply</button>
          <div v-if="comment.showReplyForm" class="mt-2">
            <textarea v-model="comment.replyContent" rows="2" class="w-full border rounded-md p-2" :placeholder="`Reply to @${comment.author.name}...`"></textarea>
            <button @click="addReply(comment.id)" class="mt-1 bg-blue-500 text-white px-2 py-1 rounded-md text-sm">Post Reply</button>
          </div>
          <div v-for="reply in comment.replies" :key="reply.id" class="ml-4 mt-2">
            <div class="flex items-center">
              <img :src="reply.author.avatar_url" :alt="reply.author.name" class="w-6 h-6 rounded-full mr-2">
              <span class="font-bold text-sm">{{ reply.author.name }}</span>
              <span class="text-gray-500 text-xs ml-2">{{ formatDate(reply.created_at) }}</span>
            </div>
            <p class="text-sm">{{ reply.content }}</p>
            <button @click="toggleNestedReplyForm(comment.id, reply.id)" class="text-blue-500 text-xs mt-1">Reply</button>
            <div v-if="reply.showReplyForm" class="mt-2">
              <textarea v-model="reply.replyContent" rows="2" class="w-full border rounded-md p-2" :placeholder="`Reply to @${reply.author.name}...`"></textarea>
              <button @click="addNestedReply(comment.id, reply.id)" class="mt-1 bg-blue-500 text-white px-2 py-1 rounded-md text-xs">Post Reply</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Shoutout modal -->
    <div v-if="showShoutoutModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div class="bg-white p-6 rounded-lg shadow-lg w-96">
        <h3 class="text-lg font-bold mb-4 text-gray-800">Add Shoutout</h3>
        <input v-model="shoutoutUser" class="w-full border rounded-md p-2 mb-2" placeholder="Enter username">
        <textarea v-model="shoutoutMessage" rows="3" class="w-full border rounded-md p-2 mb-4" placeholder="Write your shoutout message..."></textarea>
        <div class="flex justify-end">
          <button @click="cancelShoutout" class="mr-2 text-gray-600 hover:text-gray-800">Cancel</button>
          <button @click="submitShoutout" class="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600">Submit</button>
        </div>
      </div>
    </div>

    <!-- Inline comment popup -->
    <div v-if="showInlineCommentForm" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div class="bg-white p-4 rounded-lg shadow-lg w-96">
        <h3 class="text-lg font-bold mb-2">Add Inline Comment</h3>
        <textarea v-model="newInlineComment" rows="3" class="w-full border rounded-md p-2" placeholder="Write your comment..."></textarea>
        <div class="mt-2 flex justify-end">
          <button @click="cancelInlineComment" class="mr-2 text-gray-600 hover:text-gray-800">Cancel</button>
          <button @click="submitInlineComment" class="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed, onMounted, onUnmounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { ArrowLeftIcon, ArrowRightIcon, ChevronDownIcon, EyeIcon, MessageSquareIcon, HandIcon } from 'lucide-vue-next';
import { supabase } from '@/supabase';

export default {
  name: 'ChapterPage',
  components: {
    ArrowLeftIcon,
    ArrowRightIcon,
    ChevronDownIcon,
    EyeIcon,
    MessageSquareIcon,
    HandIcon,
  },
  props: {
    storyId: {
      type: String,
      required: true
    },
    chapterId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const router = useRouter();
    const showChapterList = ref(false);
    const newComment = ref('');
    const showShoutoutModal = ref(false);
    const shoutoutUser = ref('');
    const shoutoutMessage = ref('');
    const selectedInlineComment = ref(null);
    const showInlineCommentForm = ref(false);
    const newInlineComment = ref('');
    const selectedParagraphIndex = ref(null);
    const isFollowing = ref(false);
    const currentUser = ref(null);


    const isAuthor = computed(() => {
  return currentUser.value && currentUser.value.id === story.author.id;
});

    const story = reactive({
      id: '',
      title: '',
      chapters: [],
      author: {
        id: '',
        name: '',
        avatar_url: ''
      },
      reads: 0,
      applause: 0,
      comments: []
    });

    const chapter = reactive({
      id: '',
      title: '',
      content: '',
      reads: 0,
      applause: 0,
      comments: [],
      shoutout: null,
      inlineComments: []
    });

    const chapterParagraphs = computed(() => {
      return chapter.content.split('\n\n');
    });

    const paragraphHasInlineComment = (index) => {
      return chapter.inlineComments.some(comment => comment.paragraphIndex === index);
    };

    const showInlineComment = (index) => {
      selectedInlineComment.value = chapter.inlineComments.find(comment => comment.paragraphIndex === index);
    };

    const saveReadingProgress = async () => {
      if (!currentUser.value) return;

      const { error } = await supabase
        .from('user_reading_progress')
        .upsert({
          user_id: currentUser.value.id,
          story_id: story.id,
          chapter_id: chapter.id,
          progress: window.scrollY / document.documentElement.scrollHeight
        }, { onConflict: 'user_id, story_id' });

      if (error) console.error('Error saving reading progress:', error);
    };

    const loadReadingProgress = async () => {
      if (!currentUser.value) return;

      const { data, error } = await supabase
        .from('user_reading_progress')
        .select('chapter_id, progress')
        .eq('user_id', currentUser.value.id)
        .eq('story_id', story.id)
        .single();

      if (error) {
        console.error('Error loading reading progress:', error);
        return;
      }

      if (data) {
        await goToChapter(data.chapter_id);
        setTimeout(() => {
          window.scrollTo(0, data.progress * document.documentElement.scrollHeight);
        }, 100);
      }
    };

    const toggleChapterList = () => {
      showChapterList.value = !showChapterList.value;
    };

    const closeDropdowns = () => {
      showChapterList.value = false;
    };

    const applaudChapter = async () => {
      if (!currentUser.value) {
        alert('Please log in to applaud.');
        return;
      }

      const { error } = await supabase
        .from('chapter_applause')
        .upsert({
          user_id: currentUser.value.id,
          chapter_id: chapter.id
        }, { onConflict: 'user_id, chapter_id' });

      if (error) {
        console.error('Error applauding chapter:', error);
        return;
      }

      chapter.applause++;
    };

    const addComment = async () => {
      if (!currentUser.value) {
        alert('Please log in to comment.');
        return;
      }

      if (newComment.value.trim()) {
        const { data, error } = await supabase
          .from('chapter_comments')
          .insert({
            chapter_id: chapter.id,
            user_id: currentUser.value.id,
            content: newComment.value
          })
          .select('*, user:users(id, name, avatar_url)')
          .single();

        if (error) {
          console.error('Error adding comment:', error);
          return;
        }

        chapter.comments.unshift(data);
        newComment.value = '';
      }
    };

    const toggleReplyForm = (commentId) => {
      const comment = chapter.comments.find(c => c.id === commentId);
      if (comment) {
        comment.showReplyForm = !comment.showReplyForm;
      }
    };

    const addReply = async (commentId) => {
      if (!currentUser.value) {
        alert('Please log in to reply.');
        return;
      }

      const comment = chapter.comments.find(c => c.id === commentId);
      if (comment && comment.replyContent.trim()) {
        const { data, error } = await supabase
          .from('comment_replies')
          .insert({
            comment_id: commentId,
            user_id: currentUser.value.id,
            content: `@${comment.author.name} ${comment.replyContent}`
          })
          .select('*, author:users(id, name, avatar_url)')
          .single();

        if (error) {
          console.error('Error adding reply:', error);
          return;
        }

        if (!comment.replies) comment.replies = [];
        comment.replies.push(data);
        comment.replyContent = '';
        comment.showReplyForm = false;
      }
    };

    const toggleNestedReplyForm = (commentId, replyId) => {
      const comment = chapter.comments.find(c => c.id === commentId);
      if (comment) {
        const reply = comment.replies.find(r => r.id === replyId);
        if (reply) {
          reply.showReplyForm = !reply.showReplyForm;
        }
      }
    };

    const addNestedReply = async (commentId, replyId) => {
      if (!currentUser.value) {
        alert('Please log in to reply.');
        return;
      }

      const comment = chapter.comments.find(c => c.id === commentId);
      if (comment) {
        const reply = comment.replies.find(r => r.id === replyId);
        if (reply && reply.replyContent.trim()) {
          const { data, error } = await supabase
            .from('comment_replies')
            .insert({
              comment_id: commentId,
              user_id: currentUser.value.id,
              content: `@${reply.author.name} ${reply.replyContent}`,
              parent_reply_id: replyId
            })
            .select('*, author:users(id, name, avatar_url)')
            .single();

          if (error) {
            console.error('Error adding nested reply:', error);
            return;
          }

          comment.replies.push(data);
          reply.replyContent = '';
          reply.showReplyForm = false;
        }
      }
    };

    const toggleShoutoutModal = () => {
      showShoutoutModal.value = !showShoutoutModal.value;
    };

    const submitShoutout = async () => {
      if (shoutoutUser.value.trim() && shoutoutMessage.value.trim()) {
        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('id, username')
          .eq('username', shoutoutUser.value)
          .single();

        if (userError || !userData) {
          alert('User not found');
          return;
        }

        const { error } = await supabase
          .from('chapter_shoutouts')
          .upsert({
            chapter_id: chapter.id,
            user_id: userData.id,
            message: shoutoutMessage.value
          }, { onConflict: 'chapter_id' });

        if (error) {
          console.error('Error adding shoutout:', error);
          return;
        }

        chapter.shoutout = {
          userId: userData.id,
          username: userData.username,
          message: shoutoutMessage.value
        };
        cancelShoutout();
      }
    };

    const cancelShoutout = () => {
      showShoutoutModal.value = false;
      shoutoutUser.value = '';
      shoutoutMessage.value = '';
    };

    const previousChapterId = computed(() => {
      const index = story.chapters.findIndex(c => c.id === chapter.id);
      return index > 0 ? story.chapters[index - 1].id : null;
    });

    const nextChapterId = computed(() => {
      const index = story.chapters.findIndex(c => c.id === chapter.id);
      return index < story.chapters.length - 1 ? story.chapters[index + 1].id : null;
    });

    const goToChapter = async (chapterId) => {
      await router.push(`/story/${story.id}/chapter/${chapterId}`);
      loadChapterData(chapterId);
    };

    const goToStoryPage = () => {
      router.push(`/story/${story.id}`);
    };

    const handleTextSelection = () => {
      const selection = window.getSelection();
      if (selection.toString().trim().length > 0) {
        const range = selection.getRangeAt(0);
        const startNode = range.startContainer.parentNode;
        const paragraphIndex = Array.from(startNode.parentNode.children).indexOf(startNode);

        showInlineCommentForm.value = true;
        selectedParagraphIndex.value = paragraphIndex;
      }
    };

    const submitInlineComment = async () => {
      if (!currentUser.value) {
        alert('Please log in to add inline comments.');
        return;
      }

      if (newInlineComment.value.trim() && selectedParagraphIndex.value !== null) {
        const { data, error } = await supabase
          .from('inline_comments')
          .insert({
            chapter_id: chapter.id,
            user_id: currentUser.value.id,
            paragraph_index: selectedParagraphIndex.value,
            text: window.getSelection().toString(),
            comment: newInlineComment.value
          })
          .select('*, author:users(id, name, avatar_url)')
          .single();

        if (error) {
          console.error('Error adding inline comment:', error);
          return;
        }

        chapter.inlineComments.push(data);
        cancelInlineComment();
      }
    };

    const cancelInlineComment = () => {
      showInlineCommentForm.value = false;
      newInlineComment.value = '';
      selectedParagraphIndex.value = null;
    };

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    };

    const toggleFollowAuthor = async () => {
      if (!currentUser.value) {
        alert('Please log in to follow authors.');
        return;
      }

      if (isFollowing.value) {
        const { error } = await supabase
          .from('followers')
          .delete()
          .eq('follower_id', currentUser.value.id)
          .eq('followed_id', story.author.id);

        if (error) {
          console.error('Error unfollowing author:', error);
          return;
        }
      } else {
        const { error } = await supabase
          .from('followers')
          .insert({
            follower_id: currentUser.value.id,
            followed_id: story.author.id
          });

        if (error) {
          console.error('Error following author:', error);
          return;
        }
      }

      isFollowing.value = !isFollowing.value;
    };

    const loadStoryData = async () => {
      const { data, error } = await supabase
        .from('stories')
        .select(`
          id, 
          title, 
          author:users!author_id(id, name, avatar_url),
          chapters(id, title, order)
        `)
        .eq('id', props.storyId)
        .single();

      if (error) {
        console.error('Error loading story data:', error);
        return;
      }

      Object.assign(story, data);
      story.chapters.sort((a, b) => a.order - b.order);
    };

    const loadChapterData = async (chapterId) => {
      const { data, error } = await supabase
        .from('chapters')
        .select(`
          id, 
          title, 
          content,
          chapter_reads(count),
          chapter_votes(count),
          chapter_comments(
            id, 
            content, 
            created_at, 
            user:users(id, name, avatar_url)
          ),
          inline_comments(
            id, 
            paragraph_index, 
            text, 
            comment, 
            user:users(id, name, avatar_url)
          )
        `)
        .eq('id', chapterId)
        .single();

      if (error) {
        console.error('Error loading chapter data:', error);
        return;
      }

      Object.assign(chapter, {
        ...data,
        reads: data.chapter_reads[0]?.count || 0,
        votes: data.chapter_votes[0]?.count || 0,
        comments: data.chapter_comments,
        inlineComments: data.inline_comments
      });
      
      // Format shoutout data
      if (chapter.shoutout && chapter.shoutout.length > 0) {
        const shoutout = chapter.shoutout[0];
        chapter.shoutout = {
          userId: shoutout.user_id,
          username: shoutout.users.username,
          message: shoutout.message
        };
      } else {
        chapter.shoutout = null;
      }

      // Update read count
      if (currentUser.value) {
        const { error: readError } = await supabase
          .from('chapter_reads')
          .upsert({
            user_id: currentUser.value.id,
            chapter_id: chapter.id
          }, { onConflict: 'user_id, chapter_id' });

        if (readError) console.error('Error updating read count:', readError);
      }

      // Check if user is following the author
      if (currentUser.value) {
        const { data: followData, error: followError } = await supabase
          .from('followers')
          .select('id')
          .eq('follower_id', currentUser.value.id)
          .eq('followed_id', story.author.id)
          .single();

        if (followError && followError.code !== 'PGRST116') {
          console.error('Error checking follow status:', followError);
        }

        isFollowing.value = !!followData;
      }
    };

    onMounted(async () => {
      const { data: { user } } = await supabase.auth.getUser();
      currentUser.value = user;
      await loadStoryData();
      await loadChapterData(props.chapterId);
      loadReadingProgress();
      window.addEventListener('scroll', saveReadingProgress);
      document.addEventListener('mouseup', handleTextSelection);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', saveReadingProgress);
      document.removeEventListener('mouseup', handleTextSelection);
    });

    watch(() => props.chapterId, async (newChapterId) => {
      await loadChapterData(newChapterId);
      saveReadingProgress();
    });

    return {
      story,
      chapter,
      chapterParagraphs,
      showChapterList,
      newComment,
      showShoutoutModal,
      shoutoutUser,
      shoutoutMessage,
      selectedInlineComment,
      showInlineCommentForm,
      newInlineComment,
      isFollowing,
      isAuthor,
      toggleChapterList,
      closeDropdowns,
      applaudChapter,
      addComment,
      toggleReplyForm,
      addReply,
      toggleNestedReplyForm,
      addNestedReply,
      toggleShoutoutModal,
      submitShoutout,
      cancelShoutout,
      previousChapterId,
      nextChapterId,
      goToChapter,
      goToStoryPage,
      paragraphHasInlineComment,
      showInlineComment,
      submitInlineComment,
      cancelInlineComment,
      formatDate,
      toggleFollowAuthor,
      currentUser
    };
  }
};
</script>

<style scoped>
.inline-comment-icon {
  display: inline-block;
  cursor: pointer;
  transition: transform 0.2s;
}

.inline-comment-icon:hover {
  transform: scale(1.2);
}
</style>