import { supabase } from '@/supabase';

export const NOTIFICATION_TYPES = {
  COMMENT: 'comment',
  APPLAUSE: 'applause',
  FOLLOW: 'follow',
  MENTION: 'mention',
  ANNOUNCEMENT: 'announcement',
  GROUP_COMMENT: 'group_comment',
  GROUP_COMMENT_REPLY: 'group_comment_reply',
  RL_INVITE: 'rl_invite',
  RL_ACCEPT: 'rl_accept',
  RECOMMENDATION: 'recommendation',
  NEW_CHAPTER: 'new_chapter',
  CHALLENGE_INVITE: 'challenge_invite',
  CHALLENGE_WINNER: 'challenge_winner',
  PROFILE_MESSAGE: 'profile_message',
  PROFILE_MESSAGE_REPLY: 'profile_message_reply',
  ADMIN_BROADCAST: 'admin_broadcast'
};

export const notificationService = {
  async createNotification(userId, type, message, link) {
    const { data, error } = await supabase
      .from('notifications')
      .insert([
        { user_id: userId, type, message, link }
      ]);

    if (error) {
      console.error('Error creating notification:', error);
      throw error;
    }

    return data;
  },

  async isFollowing(followerId, followedId) {
    const { data, error } = await supabase
      .from('follows')
      .select('*')
      .eq('follower_id', followerId)
      .eq('following_id', followedId);

    if (error) {
      console.error('Error checking follow status:', error);
      return false;
    }

    return data.length > 0;
  },

  async isInBookshelf(userId, storyId) {
    const { data, error } = await supabase
      .from('user_books')
      .select('*')
      .eq('user_id', userId)
      .eq('story_id', storyId);

    if (error) {
      console.error('Error checking bookshelf status:', error);
      return false;
    }

    return data.length > 0;
  },

  async createCommentNotification(userId, storyTitle, storyId, commentId) {
    const message = `New comment on your story "${storyTitle}"`;
    const link = `/story/${storyId}#comment-${commentId}`;
    return this.createNotification(userId, NOTIFICATION_TYPES.COMMENT, message, link);
  },

  async createApplauseNotification(userId, storyTitle, storyId) {
    const message = `Someone applauded your story "${storyTitle}"`;
    const link = `/story/${storyId}`;
    return this.createNotification(userId, NOTIFICATION_TYPES.APPLAUSE, message, link);
  },

  async createFollowNotification(userId, followerName) {
    const message = `${followerName} started following you`;
    const link = `/profile/${followerName}`;
    return this.createNotification(userId, NOTIFICATION_TYPES.FOLLOW, message, link);
  },

  async createMentionNotification(userId, mentionerName, storyTitle, storyId) {
    const message = `${mentionerName} mentioned you in "${storyTitle}"`;
    const link = `/story/${storyId}`;
    return this.createNotification(userId, NOTIFICATION_TYPES.MENTION, message, link);
  },

  async createAnnouncementNotification(authorId, authorName, announcementTitle) {
    const message = `New announcement from ${authorName}: ${announcementTitle}`;
    const link = `/announcements`;

    const { data: followers, error } = await supabase
      .from('follows')
      .select('follower_id')
      .eq('following_id', authorId);

    if (error) {
      console.error('Error fetching followers for announcement:', error);
      return;
    }

    for (const follower of followers) {
      await this.createNotification(follower.follower_id, NOTIFICATION_TYPES.ANNOUNCEMENT, message, link);
    }
  },

  async createGroupCommentNotification(commenterName, groupName, groupId, postId) {
    const message = `${commenterName} commented on a post in ${groupName}`;
    const link = `/group/${groupId}/post/${postId}`;

    // Fetch the post creator
    const { data: post, error: postError } = await supabase
      .from('group_posts')
      .select('user_id')
      .eq('id', postId)
      .single();

    if (postError) {
      console.error('Error fetching post creator:', postError);
      return;
    }

    // Fetch users following the post
    const { data: followers, error: followerError } = await supabase
      .from('post_followers')
      .select('user_id')
      .eq('post_id', postId);

    if (followerError) {
      console.error('Error fetching post followers:', followerError);
      return;
    }

    // Combine post creator and followers, removing duplicates
    const notifyUsers = new Set([post.user_id, ...followers.map(f => f.user_id)]);

    for (const userId of notifyUsers) {
      await this.createNotification(userId, NOTIFICATION_TYPES.GROUP_COMMENT, message, link);
    }
  },

  async createGroupCommentReplyNotification(replierName, groupName, groupId, postId, parentCommentId) {
    const message = `${replierName} replied to your comment in ${groupName}`;
    const link = `/group/${groupId}/post/${postId}#comment-${parentCommentId}`;

    // Fetch the parent comment creator
    const { data: parentComment, error: commentError } = await supabase
      .from('group_comments')
      .select('user_id')
      .eq('id', parentCommentId)
      .single();

    if (commentError) {
      console.error('Error fetching parent comment creator:', commentError);
      return;
    }

    await this.createNotification(parentComment.user_id, NOTIFICATION_TYPES.GROUP_COMMENT_REPLY, message, link);
  },

  async createRLInviteNotification(userId, inviterName, listName) {
    const message = `${inviterName} invited you to join their reading list "${listName}"`;
    const link = `/reading-lists/invites`;
    return this.createNotification(userId, NOTIFICATION_TYPES.RL_INVITE, message, link);
  },

  async createRLAcceptNotification(userId, accepterName, listName) {
    const message = `${accepterName} accepted your invitation to "${listName}"`;
    const link = `/reading-lists/${listName}`;
    return this.createNotification(userId, NOTIFICATION_TYPES.RL_ACCEPT, message, link);
  },

  async createRecommendationNotification(userId, recommenderName, storyTitle, storyId) {
    const message = `${recommenderName} recommended "${storyTitle}" to you`;
    const link = `/story/${storyId}`;
    return this.createNotification(userId, NOTIFICATION_TYPES.RECOMMENDATION, message, link);
  },

  async createNewChapterNotification(authorId, storyTitle, storyId, chapterNumber) {
    const message = `New chapter ${chapterNumber} of "${storyTitle}" is available`;
    const link = `/story/${storyId}/chapter/${chapterNumber}`;

    // Get all followers and users who have the story in their bookshelf
    const { data: followers, error: followError } = await supabase
      .from('follows')
      .select('follower_id')
      .eq('following_id', authorId);

    const { data: bookshelfUsers, error: bookshelfError } = await supabase
      .from('user_books')
      .select('user_id')
      .eq('story_id', storyId);

    if (followError || bookshelfError) {
      console.error('Error fetching users for new chapter notification:', followError || bookshelfError);
      return;
    }

    const notifiedUsers = new Set([
      ...followers.map(f => f.follower_id),
      ...bookshelfUsers.map(u => u.user_id)
    ]);

    for (const userId of notifiedUsers) {
      await this.createNotification(userId, NOTIFICATION_TYPES.NEW_CHAPTER, message, link);
    }
  },

  async createChallengeInviteNotification(userId, inviterName, challengeName, challengeId) {
    const message = `${inviterName} invited you to participate in the challenge "${challengeName}"`;
    const link = `/challenge/${challengeId}`;
    return this.createNotification(userId, NOTIFICATION_TYPES.CHALLENGE_INVITE, message, link);
  },

  async createChallengeWinnerNotification(userId, challengeName, challengeId) {
    const message = `Congratulations! You won the challenge "${challengeName}"`;
    const link = `/challenge/${challengeId}/results`;
    return this.createNotification(userId, NOTIFICATION_TYPES.CHALLENGE_WINNER, message, link);
  },

  async adminNotifyChallengeWinner(adminId, winnerId, challengeName, challengeId) {
    const { data: admin, error: adminError } = await supabase
      .from('users')
      .select('username')
      .eq('id', adminId)
      .single();

    if (adminError) {
      console.error('Error fetching admin details:', adminError);
      return;
    }

    const message = `Congratulations! ${admin.username} has declared you the winner of the "${challengeName}" challenge!`;
    const link = `/challenge/${challengeId}/results`;

    await this.createNotification(winnerId, NOTIFICATION_TYPES.CHALLENGE_WINNER, message, link);
  },

  async createProfileMessageNotification(userId, senderName, profileOwnerName) {
    const message = `${senderName} left a message on your profile`;
    const link = `/profile/${profileOwnerName}#message-board`;
    return this.createNotification(userId, NOTIFICATION_TYPES.PROFILE_MESSAGE, message, link);
  },

  async createProfileMessageReplyNotification(userId, replierName, profileOwnerName, messageId) {
    const message = `${replierName} replied to your message on ${profileOwnerName}'s profile`;
    const link = `/profile/${profileOwnerName}#message-${messageId}`;
    return this.createNotification(userId, NOTIFICATION_TYPES.PROFILE_MESSAGE_REPLY, message, link);
  },

    async createAdminBroadcastNotification(userId, broadcastTitle, broadcastId) {
    const message = `New admin broadcast: ${broadcastTitle}`;
    const link = `/broadcasts/${broadcastId}`;
    return this.createNotification(userId, NOTIFICATION_TYPES.ADMIN_BROADCAST, message, link);
  },

  async notifyAllUsersOfBroadcast(broadcastTitle, broadcastId) {
    // Fetch all users
    const { data: users, error } = await supabase
      .from('users')
      .select('id');

    if (error) {
      console.error('Error fetching users for broadcast notification:', error);
      return;
    }

    // Create a notification for each user
    for (const user of users) {
      await this.createAdminBroadcastNotification(user.id, broadcastTitle, broadcastId);
    }
  }
};