<template>
  <div class="explore-page bg-gray-100 min-h-screen p-8">
    <div class="max-w-6xl mx-auto">
      <h1 class="text-4xl font-bold mb-8">Explore Stories</h1>
      <!-- Enhanced Search Bar -->
      <div class="mb-8 flex flex-col space-y-4">
        <div class="relative w-full">
          <input 
            v-model="searchQuery" 
            type="text" 
            placeholder="Search for stories, authors, or tags" 
            class="w-full p-4 rounded-full border-2 border-gray-300 focus:border-blue-500 focus:outline-none"
            @keyup.enter="performSearch"
          />
          <button 
            @click="performSearch" 
            class="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-blue-500"
          >
            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </button>
        </div>
        <div class="flex flex-wrap items-center space-x-4">
          <select 
  v-model="selectedGenre" 
  class="bg-white border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
>
  <option value="">All Genres</option>
  <option v-for="genre in genres" :key="genre.id" :value="genre.id">{{ genre.name }}</option>
</select>
          <select 
            v-model="selectedLength" 
            class="bg-white border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Any Length</option>
            <option value="short">Short (&lt; 10k words)</option>
            <option value="medium">Medium (10k-50k words)</option>
            <option value="long">Long (&gt; 50k words)</option>
          </select>
          <select 
            v-model="selectedStatus" 
            class="bg-white border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Any Status</option>
            <option value="complete">Complete</option>
            <option value="ongoing">Ongoing</option>
          </select>
          <select 
            v-model="selectedSort" 
            class="bg-white border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="popularity">Sort by Popularity</option>
            <option value="date">Sort by Release Date</option>
            <option value="updated">Sort by Last Updated</option>
          </select>
        </div>
      </div>

      <!-- Search Results -->
      <div v-if="searchPerformed" class="mb-12">
  <h2 class="text-2xl font-bold mb-4">Search Results</h2>
  <div v-if="filteredStories.length > 0" class="grid grid-cols-1 md:grid-cols-3 gap-6">
    <div v-for="story in filteredStories" :key="story.id" class="story-card bg-white rounded-lg shadow-md overflow-hidden">
      <div class="aspect-w-2 aspect-h-3">
        <img :src="story.cover_image" :alt="story.title" class="w-full h-full object-cover cursor-pointer" @click="navigateToStory(story.id)">
      </div>
      <div class="p-4">
        <h3 class="font-semibold mb-2 cursor-pointer" @click="navigateToStory(story.id)">{{ story.title }}</h3>
        <p class="text-sm text-gray-600 mb-2 cursor-pointer" @click="navigateToUser(story.author.id)">by {{ story.author.username }}</p>
        <p class="text-sm text-gray-500">{{ story.reads }} reads • {{ story.votes }} applause • {{ story.comments }} comments</p>
        <p class="text-sm text-gray-500">Released {{ formatDate(story.published_at) }}</p>
        <div class="mt-2 flex flex-wrap gap-2">
          <span 
            v-for="tag in story.tags" 
            :key="tag" 
            class="bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded-full cursor-pointer"
            @click="searchByTag(tag)"
          >
          {{ tag }}
        </span>
      </div>
    </div>
  </div>
        </div>
        <div v-else class="text-center text-gray-600 mt-8">
      <p class="text-xl">No results found for "{{ searchQuery }}"</p>
    </div>
    <div v-if="loadingMore" class="text-center mt-4">
      <p class="text-gray-600">Loading more stories...</p>
    </div>
    <div v-if="allStoriesLoaded" class="text-center mt-4">
      <p class="text-gray-600">No more stories to load</p>
    </div>
  </div>

      <!-- Featured Stories -->   
      <div v-if="!searchPerformed">
        <div class="mb-12 bg-white rounded-lg shadow-md p-6">
  <h2 class="text-2xl font-bold mb-4">Featured Stories</h2>
  <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
    <div v-for="story in featuredStories" :key="story.id" class="story-card bg-white rounded-lg shadow-md overflow-hidden">
      <div class="aspect-w-2 aspect-h-3">
        <img :src="story.cover_image" :alt="story.title" class="w-full h-full object-cover cursor-pointer" @click="navigateToStory(story.id)">
      </div>
      <div class="p-4">
        <h3 class="font-semibold mb-2 cursor-pointer" @click="navigateToStory(story.id)">{{ story.title }}</h3>
        <p class="text-sm text-gray-600 mb-2 cursor-pointer" @click="navigateToUser(story.author.id)">by {{ story.author.username }}</p>
        <p class="text-sm text-gray-500">{{ story.reads }} reads • {{ story.votes }} applause • {{ story.comments }} comments</p>
      </div>
    </div>
  </div>
</div>
        
        <!-- Popular Categories -->
        <div class="mb-12">
  <h2 class="text-2xl font-bold mb-4">Popular Categories</h2>
  <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
    <a 
  v-for="category in popularCategories" 
  :key="category.id" 
  href="#" 
  @click.prevent="searchByCategory(category)" 
  class="bg-white rounded-lg shadow-md p-4 text-center hover:bg-blue-50 transition duration-300"
>
  {{ category.name }}
</a>

  </div>
</div>

        <!-- Trending Now -->
       <div class="mb-12">
  <h2 class="text-2xl font-bold mb-4">Trending Now</h2>
  <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
    <div v-for="story in trendingStories" :key="story.id" class="story-card bg-white rounded-lg shadow-md overflow-hidden">
      <div class="aspect-w-2 aspect-h-3">
        <img :src="story.cover_image" :alt="story.title" class="w-full h-full object-cover cursor-pointer" @click="navigateToStory(story.id)">
      </div>
      <div class="p-4">
        <h3 class="font-semibold mb-2 cursor-pointer" @click="navigateToStory(story.id)">{{ story.title }}</h3>
        <p class="text-sm text-gray-600 mb-2 cursor-pointer" @click="navigateToUser(story.author.id)">by {{ story.author.username }}</p>
        <p class="text-sm text-gray-500">{{ story.reads }} reads • {{ story.votes }} applause • {{ story.comments }} comments</p>
      </div>
    </div>
  </div>
</div>
        
        <!-- New Releases -->
        <div class="mb-12">
  <h2 class="text-2xl font-bold mb-4">New Releases</h2>
  <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
    <div v-for="story in newReleases" :key="story.id" class="story-card bg-white rounded-lg shadow-md overflow-hidden">
      <div class="aspect-w-2 aspect-h-3">
        <img :src="story.cover_image" :alt="story.title" class="w-full h-full object-cover cursor-pointer" @click="navigateToStory(story.id)">
      </div>
      <div class="p-4">
        <h3 class="font-semibold mb-2 cursor-pointer" @click="navigateToStory(story.id)">{{ story.title }}</h3>
        <p class="text-sm text-gray-600 mb-2 cursor-pointer" @click="navigateToUser(story.author.id)">by {{ story.author.username }}</p>
        <p class="text-sm text-gray-500">{{ story.reads }} reads • {{ story.votes }} applause • {{ story.comments }} comments</p>
        <p class="text-sm text-gray-500">Released {{ formatDate(story.published_at) }}</p>
      </div>
    </div>
  </div>
</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, onUnmounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import { supabase } from '@/supabase'

export default {
  name: 'ExplorePage',
  setup() {
    const route = useRoute()
    const router = useRouter()
    const searchQuery = ref('')
    const selectedGenre = ref('')
    const selectedLength = ref('')
    const selectedStatus = ref('')
    const selectedSort = ref('popularity')
    const searchPerformed = ref(false)
    const genres = ref([])
    const allStories = ref([])
    const popularCategories = ref([])
    const featuredStories = ref([]);
    const trendingStories = ref([]);
    const newReleases = ref([]);
    const page = ref(1)
    const STORIES_PER_PAGE = 20
    const loadingMore = ref(false)
    const allStoriesLoaded = ref(false)

    const navigateToStory = (storyId) => {
  recordRead(storyId)
  router.push(`/story/${storyId}`)
}

    const navigateToUser = (userId) => {
  router.push(`/user/${userId}`)
}

    const searchByTag = (tag) => {
  searchQuery.value = tag
  performSearch()
}
    
    const fetchGenres = async () => {
      const { data, error } = await supabase
        .from('genres')
        .select('id, name')
      if (error) console.error('Error fetching genres:', error)
      else {
        genres.value = data
        popularCategories.value = genres.value.slice(0, 8) // Assuming the first 8 are the most popular
      }
    }

    const loadMoreStories = async () => {
  if (loadingMore.value || allStoriesLoaded.value) return

  loadingMore.value = true
  page.value++

  let query = supabase
    .from('stories')
    .select(`
      id,
      title,
      description,
      author:users!author_id(id, username, avatar_url),
      cover_image,
      reads:story_reads(count),
      votes:story_votes(count),
      comments:story_comments(count),
      published_at,
      tags,
      genre,
      word_count,
      status
    `)
    .eq('status', 'published')
    .range((page.value - 1) * STORIES_PER_PAGE, page.value * STORIES_PER_PAGE - 1)

  // Apply filters and sorting as in fetchStories function

  const { data, error } = await query

  if (error) {
    console.error('Error fetching more stories:', error)
  } else {
    const newStories = data.map(story => ({
      ...story,
      reads: story.reads[0]?.count || 0,
      votes: story.votes[0]?.count || 0,
      comments: story.comments[0]?.count || 0
    }))
    allStories.value = [...allStories.value, ...newStories]
    
    if (newStories.length < STORIES_PER_PAGE) {
      allStoriesLoaded.value = true
    }
  }

  loadingMore.value = false
}

    const handleScroll = () => {
  const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight
  if (bottomOfWindow) {
    loadMoreStories()
  }
}

    onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})

    const fetchPopularCategories = async () => {
  const { data, error } = await supabase
    .from('stories')
    .select('genre')
    .eq('status', 'published')

  if (error) {
    console.error('Error fetching stories for popular categories:', error)
    // Fallback to genres if there's an error
    popularCategories.value = genres.value.slice(0, 8)
  } else {
    const categoryCounts = data.reduce((acc, story) => {
      if (story.genre) {
        acc[story.genre] = (acc[story.genre] || 0) + 1
      }
      return acc
    }, {})

    popularCategories.value = Object.entries(categoryCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 8)
      .map(([category]) => category)

    // If we don't have 8 categories, fill the rest with genres
    if (popularCategories.value.length < 8) {
      const remainingGenres = genres.value.filter(g => !popularCategories.value.includes(g))
      popularCategories.value = [...popularCategories.value, ...remainingGenres.slice(0, 8 - popularCategories.value.length)]
    }
  }
}

    const recordRead = async (storyId) => {
  const { data: session } = await supabase.auth.getSession()
  if (!session) return

  const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString()
  
  const { data, error } = await supabase
    .from('story_reads')
    .select('id')
    .eq('user_id', session.user.id)
    .eq('story_id', storyId)
    .gte('read_at', twentyFourHoursAgo)
    .maybeSingle()

  if (error) {
    console.error('Error checking recent reads:', error)
    return
  }

  if (!data) {
    const { error: insertError } = await supabase
      .from('story_reads')
      .insert({ user_id: session.user.id, story_id: storyId })

    if (insertError) {
      console.error('Error recording read:', insertError)
    }
  }
}

    const fetchTrendingStories = async () => {
  const thirtyDaysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString()
  const { data, error } = await supabase
    .from('story_reads')
    .select(`
      story_id,
      read_at,
      stories (
        id,
        title,
        cover_image,
        users (username)
      )
    `)
    .gte('read_at', thirtyDaysAgo)

  if (error) {
    console.error('Error fetching trending stories:', error)
  } else {
    const storyCounts = data.reduce((acc, read) => {
      if (!acc[read.story_id]) {
        acc[read.story_id] = {
          id: read.stories.id,
          title: read.stories.title,
          author: { username: read.stories.users.username },
          cover_image: read.stories.cover_image,
          reads: 0,
          votes: 0,
          comments: 0
        }
      }
      acc[read.story_id].reads++
      return acc
    }, {})

    // Fetch votes and comments counts
    const storyIds = Object.keys(storyCounts)
    const { data: voteData, error: voteError } = await supabase
      .from('story_votes')
      .select('story_id')
      .in('story_id', storyIds)

    if (voteError) {
      console.error('Error fetching vote counts:', voteError)
    } else {
      voteData.forEach(vote => {
        if (storyCounts[vote.story_id]) {
          storyCounts[vote.story_id].votes++
        }
      })
    }

    const { data: commentData, error: commentError } = await supabase
      .from('story_comments')
      .select('story_id')
      .in('story_id', storyIds)

    if (commentError) {
      console.error('Error fetching comment counts:', commentError)
    } else {
      commentData.forEach(comment => {
        if (storyCounts[comment.story_id]) {
          storyCounts[comment.story_id].comments++
        }
      })
    }

    trendingStories.value = Object.values(storyCounts)
      .sort((a, b) => b.reads - a.reads)
      .slice(0, 3)
  }
}

// Add watchers for filter values
    watch([selectedGenre, selectedLength, selectedStatus, selectedSort], () => {
      performSearch()
    })

const fetchNewReleases = async () => {
  console.log('Fetching new releases...');
  const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString();
  
  let query = supabase
    .from('stories')
    .select(`
      id,
      title,
      author:users!author_id(id, username),
      cover_image,
      published_at,
      reads:story_reads(count),
      votes:story_votes(count),
      comments:story_comments(count)
    `)
    .eq('status', 'published')
    .order('published_at', { ascending: false })
    .limit(10)  // Increased limit to get more potential stories

  // First, try to get stories from the last 24 hours
  let { data, error } = await query.gte('published_at', twentyFourHoursAgo)
  console.log('Stories from last 24 hours:', data);

  // If there are no recent stories, get the latest stories regardless of publish date
  if (!error && (!data || data.length === 0)) {
    console.log('No new releases in the last 24 hours, fetching latest stories...');
    ({ data, error } = await query)
    console.log('Latest stories:', data);
  }

  if (error) {
    console.error('Error fetching new releases:', error)
  } else if (!data || data.length === 0) {
    console.log('No stories found in the database.');
    newReleases.value = []
  } else {
    console.log('New releases data:', data);
    // Shuffle the array and take the first 3
    const shuffled = data.sort(() => 0.5 - Math.random()).slice(0, 3);
    newReleases.value = shuffled.map(story => ({
      ...story,
      reads: story.reads[0]?.count || 0,
      votes: story.votes[0]?.count || 0,
      comments: story.comments[0]?.count || 0
    }))
    console.log('Processed new releases:', newReleases.value);
  }
}

    const fetchFeaturedStories = async () => {
  const { data, error } = await supabase
    .from('featured_stories')
    .select(`
      story_id,
      stories (
        id,
        title,
        cover_image,
        author:users!author_id(id, username, avatar_url),
        reads:story_reads(count),
        votes:story_votes(count),
        comments:story_comments(count),
        published_at
      )
    `)
    .order('featured_order')
    .limit(5)

  if (error) {
    console.error('Error fetching featured stories:', error)
  } else {
    featuredStories.value = data.map(item => ({
      ...item.stories,
      reads: item.stories.reads[0]?.count || 0,
      votes: item.stories.votes[0]?.count || 0,
      comments: item.stories.comments[0]?.count || 0
    }))
  }
}

    const fetchStories = async () => {
      page.value = 1
      allStoriesLoaded.value = false
      let query = supabase
        .from('stories')
        .select(`
          id,
          title,
          description,
          author:users!author_id(id, username, avatar_url),
          cover_image,
          reads:story_reads(count),
          votes:story_votes(count),
          comments:story_comments(count),
          published_at,
          tags,
          genre_id,
          genres!inner(name),
          word_count,
          status
        `)
        .eq('status', 'published')

      if (searchQuery.value) {
        query = query.or(`title.ilike.%${searchQuery.value}%,description.ilike.%${searchQuery.value}%,users.username.ilike.%${searchQuery.value}%,tags.cs.{${searchQuery.value}}`)
      }
      if (selectedGenre.value) query = query.eq('genre_id', selectedGenre.value)
      if (selectedLength.value) {
        if (selectedLength.value === 'short') query = query.lt('word_count', 10000)
        else if (selectedLength.value === 'medium') query = query.and('word_count.gte.10000,word_count.lte.50000')
        else if (selectedLength.value === 'long') query = query.gt('word_count', 50000)
      }
      if (selectedStatus.value) query = query.eq('status', selectedStatus.value)

      if (selectedSort.value === 'popularity') query = query.order('reads', { ascending: false })
      else if (selectedSort.value === 'date') query = query.order('published_at', { ascending: false })
      else if (selectedSort.value === 'updated') query = query.order('updated_at', { ascending: false })

      query = query.range(0, STORIES_PER_PAGE - 1)

      const { data, error } = await query

      if (error) {
        console.error('Error fetching stories:', error)
      } else {
        console.log('Fetched stories:', data)
        allStories.value = data.map(story => ({
          ...story,
          genre: story.genres.name,
          reads: story.reads[0]?.count || 0,
          votes: story.votes[0]?.count || 0,
          comments: story.comments[0]?.count || 0
        }))
        
        if (data.length < STORIES_PER_PAGE) {
          allStoriesLoaded.value = true
        }
      }
    }

    onMounted(async () => {
  await Promise.all([
    fetchGenres(),
    fetchPopularCategories(),
    fetchTrendingStories(),
    fetchNewReleases(),
    fetchFeaturedStories(),
    fetchStories()
  ])

  if (route.query.tag) {
    searchQuery.value = route.query.tag
    performSearch()
  }
})

    const performSearch = () => {
  searchPerformed.value = true
  fetchStories()
}

    const filteredStories = computed(() => allStories.value)
    

    const searchByCategory = (category) => {
      selectedGenre.value = category
      performSearch()
    }

    const formatDate = (date) => {
      return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
    }

    return {
      searchQuery,
      selectedGenre,
      selectedLength,
      selectedStatus,
      selectedSort,
      searchPerformed,
      navigateToStory,
      navigateToUser,
      searchByTag,
      genres,
      allStories,
      featuredStories,
      popularCategories,
      trendingStories,
      newReleases,
      filteredStories,
      performSearch,
      loadingMore,
      allStoriesLoaded,
      recordRead,
      formatDate,
      searchByCategory
    }
  }
}
</script>

<style scoped>
.prose {
  max-width: none;
}
.prose p {
  margin-bottom: 1em;
}

/* Story card styles */
.story-card {
  display: flex;
  flex-direction: column;
}
.story-card .aspect-w-2 {
  position: relative;
  padding-bottom: 150%; /* This creates a 2:3 aspect ratio */
}
.story-card .aspect-w-2 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .explore-page {
    padding: 4px;
  }
  .grid-cols-3 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (max-width: 640px) {
  .grid-cols-3, .grid-cols-2 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
</style>