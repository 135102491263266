<template>
  <div v-if="loading">Loading recommendation lists...</div>
  <div v-else-if="error">{{ error }}</div>
  <div v-else>
    <!-- Create new list form -->
    <div class="mb-8">
      <input v-model="newListName" type="text" placeholder="New list name" class="mr-2 p-2 border rounded-md">
      <button @click="createList" class="bg-blue-600 text-white px-4 py-2 rounded-md">Create List</button>
    </div>

    <!-- Existing lists -->
    <div v-for="list in recommendationLists" :key="list.id" class="mb-6 p-4 border rounded-md">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-xl font-semibold">{{ list.title }}</h3>
        <button @click="deleteList(list.id)" class="text-red-600">Delete List</button>
      </div>
      
      <!-- List members -->
      <ul class="mt-4">
        <li v-for="member in list.members" :key="member.id" class="flex justify-between items-center mb-2">
          <router-link :to="{ name: 'VisitedUserProfile', params: { username: member.username } }" class="text-blue-600 hover:underline">
            {{ member.username }}
          </router-link>
          <button @click="removeMember(list.id, member.id)" class="text-red-600 ml-2">Remove</button>
        </li>
      </ul>
      
      <!-- Search and invite form -->
      <div class="mt-4">
        <input v-model="list.newMemberSearch" @input="searchUsers(list.id)" type="text" placeholder="Search users to invite" class="mr-2 p-2 border rounded-md">
        <ul v-if="list.searchResults.length > 0" class="mt-2 border rounded-md">
          <li v-for="user in list.searchResults" :key="user.id" class="p-2 cursor-pointer hover:bg-gray-100 flex justify-between items-center">
            <span>{{ user.username }}</span>
            <button @click="inviteUser(list.id, user)" class="bg-green-600 text-white px-2 py-1 rounded-md text-sm">Invite</button>
          </li>
        </ul>
      </div>

      <!-- Pending invites -->
      <div class="mt-4">
        <button @click="list.showPendingInvites = !list.showPendingInvites" class="text-blue-600">
          {{ list.showPendingInvites ? 'Hide' : 'Show' }} Pending Invites ({{ list.pendingInvites.length }})
        </button>
        <ul v-if="list.showPendingInvites" class="mt-2 border rounded-md">
          <li v-for="invite in list.pendingInvites" :key="invite.id" class="p-2 flex justify-between items-center">
  <router-link :to="{ name: 'VisitedUserProfile', params: { username: invite.username } }" class="text-blue-600 hover:underline">
    {{ invite.username }}
  </router-link>
  <button @click="cancelInvite(list.id, invite.id)" class="text-red-600">Cancel Invite</button>
</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { supabase } from '@/supabase';

export default {
  name: 'RecommendationListManagement',
  setup() {
    const router = useRouter();
    const loading = ref(true);
    const error = ref(null);
    const recommendationLists = ref([]);
    const newListName = ref('');

    const fetchRecommendationLists = async () => {
  try {
    console.log('Fetching recommendation lists...');
    const { data: { user }, error: authError } = await supabase.auth.getUser();
    if (authError) {
      console.error('Authentication error:', authError);
      throw authError;
    }
    if (!user) {
      console.error('No user logged in');
      throw new Error('No user logged in');
    }
    console.log('Current user:', user.id);

    const { data, error: fetchError } = await supabase
      .from('recommendation_lists')
      .select(`
        id,
        title,
        recommendation_list_items (
          id,
          users (id, username)
        ),
        recommendation_list_invites (
          id,
          invited_user:users!recommendation_list_invites_user_id_fkey (id, username)
        )
      `)
      .eq('user_id', user.id);

    if (fetchError) {
      console.error('Supabase error:', fetchError);
      throw fetchError;
    }

    console.log('Fetched data:', data);

    recommendationLists.value = data.map(list => ({
      ...list,
      members: list.recommendation_list_items.map(item => item.users),
      pendingInvites: list.recommendation_list_invites.map(invite => ({
        id: invite.id,
        username: invite.invited_user.username
      })),
      newMemberSearch: '',
      searchResults: [],
      showPendingInvites: false
    }));
    console.log('Processed recommendation lists:', recommendationLists.value);
    loading.value = false;
  } catch (err) {
    console.error('Error fetching recommendation lists:', err);
    error.value = 'Failed to load recommendation lists: ' + (err.message || 'Unknown error');
    loading.value = false;
  }
};

    const createList = async () => {
      if (newListName.value.trim()) {
        try {
          const { data: { user } } = await supabase.auth.getUser();
          if (!user) throw new Error('No user logged in');

          const { data, error: createError } = await supabase
            .from('recommendation_lists')
            .insert({ title: newListName.value.trim(), user_id: user.id })
            .select()
            .single();

          if (createError) throw createError;

          recommendationLists.value.push({ ...data, members: [], newMemberSearch: '', searchResults: [] });
          newListName.value = '';
        } catch (err) {
          console.error('Error creating list:', err);
          error.value = 'Failed to create list';
        }
      }
    };

    const deleteList = async (listId) => {
      const list = recommendationLists.value.find(l => l.id === listId);
      const memberCount = list?.members?.length || 0;
      const confirmMessage = memberCount > 0 
        ? `Are you sure you want to delete this list? It contains ${memberCount} member(s).`
        : 'Are you sure you want to delete this empty list?';

      if (confirm(confirmMessage)) {
        try {
          const { error: deleteError } = await supabase
            .from('recommendation_lists')
            .delete()
            .eq('id', listId);

          if (deleteError) throw deleteError;

          recommendationLists.value = recommendationLists.value.filter(l => l.id !== listId);
        } catch (err) {
          console.error('Error deleting list:', err);
          error.value = 'Failed to delete list';
        }
      }
    };

    const searchUsers = async (listId) => {
      const list = recommendationLists.value.find(l => l.id === listId);
      if (!list) {
        console.error('List not found:', listId);
        return;
      }

      console.log('Searching users for list:', listId);
      console.log('Search term:', list.newMemberSearch);

      if (list.newMemberSearch.trim().length < 2) {
        console.log('Search term too short, clearing results');
        list.searchResults = [];
        return;
      }

      try {
        const { data, error } = await supabase
          .from('users')
          .select('id, username, avatar_url')
          .ilike('username', `%${list.newMemberSearch}%`)
          .limit(10);

        if (error) {
          console.error('Supabase error:', error);
          throw error;
        }

        console.log('Search results:', data);

        if (data && data.length > 0) {
          list.searchResults = data;
        } else {
          console.log('No users found matching the search term');
          list.searchResults = [];
        }
      } catch (err) {
        console.error('Error searching users:', err);
        list.searchResults = [];
        error.value = 'Failed to search users';
      }
    };

    const inviteUser = async (listId, user) => {
  console.log('Inviting user:', user.id, 'to list:', listId);
  try {
    // Verify Supabase client
    const { data: { user: currentUser }, error: authError } = await supabase.auth.getUser();
    if (authError) {
      console.error('Authentication error:', authError);
      throw authError;
    }
    if (!currentUser) {
      console.error('No authenticated user found');
      throw new Error('User not authenticated');
    }
    console.log('Current authenticated user:', currentUser.id);

    // Check if the user is already a member or has a pending invite
    const [{ data: existingMember }, { data: existingInvite }] = await Promise.all([
      supabase
        .from('recommendation_list_items')
        .select('id')
        .eq('list_id', listId)
        .eq('user_id', user.id)
        .maybeSingle(),
      supabase
        .from('recommendation_list_invites')
        .select('id')
        .eq('list_id', listId)
        .eq('user_id', user.id)
        .maybeSingle()
    ]);
    
    if (existingMember) {
      console.log('User is already a member of this list');
      return;
    }
    
    if (existingInvite) {
      console.log('User already has a pending invite for this list');
      return;
    }

    // Log RPC parameters
    console.log('RPC parameters:', { p_list_id: listId, p_user_id: user.id, p_inviter_id: currentUser.id });

    // Use the RPC function to send the invite and create the notification
    console.log('Calling invite_user_to_list RPC function');
    const { data, error } = await supabase.rpc('invite_user_to_list', {
      p_list_id: listId,
      p_user_id: user.id,
      p_inviter_id: currentUser.id
    });

    if (error) {
      console.error('RPC error details:', error);
      throw error;
    }

    console.log('Invite sent and notification created:', data);
    const list = recommendationLists.value.find(l => l.id === listId);
    if (list) {
      list.pendingInvites.push({
        id: data.invite_id,
        username: user.username
      });
      list.newMemberSearch = '';
      list.searchResults = [];
    }
  } catch (err) {
    console.error('Error inviting user:', err);
    error.value = 'Failed to invite user: ' + (err.message || 'Unknown error');
  }
};

    const cancelInvite = async (listId, inviteId) => {
      try {
        const { error: deleteError } = await supabase
          .from('recommendation_list_invites')
          .delete()
          .eq('id', inviteId);

        if (deleteError) throw deleteError;

        const list = recommendationLists.value.find(l => l.id === listId);
        if (list) {
          list.pendingInvites = list.pendingInvites.filter(invite => invite.id !== inviteId);
        }
      } catch (err) {
        console.error('Error canceling invite:', err);
        error.value = 'Failed to cancel invite';
      }
    };

    const removeMember = async (listId, memberId) => {
      try {
        const { error: removeError } = await supabase
          .from('recommendation_list_items')
          .delete()
          .eq('list_id', listId)
          .eq('user_id', memberId);

        if (removeError) throw removeError;

        const list = recommendationLists.value.find(l => l.id === listId);
        if (list) {
          list.members = list.members.filter(m => m.id !== memberId);
        }
      } catch (err) {
        console.error('Error removing member:', err);
        error.value = 'Failed to remove member';
      }
    };

    const navigateToUserProfile = (username) => {
      router.push({ name: 'VisitedUserProfile', params: { username } });
    };

    onMounted(fetchRecommendationLists);

    return {
      loading,
      error,
      recommendationLists,
      newListName,
      createList,
      deleteList,
      searchUsers,
      inviteUser,
      cancelInvite,
      removeMember,
      navigateToUserProfile
    };
  }
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>