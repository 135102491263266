import { supabase } from '@/supabase';

const state = {
  stories: [],
  currentStory: null
};

const getters = {
  getStoryById: (state) => (id) => {
    return state.stories.find(story => story.id === id);
  }
};

const actions = {
  async fetchStories({ commit }) {
    const { data, error } = await supabase
      .from('stories')
      .select('*');

    if (error) {
      console.error('Error fetching stories:', error);
    } else {
      commit('setStories', data);
    }
  },

  async fetchStory({ commit }, storyId) {
    const { data, error } = await supabase
      .from('stories')
      .select(`
        *,
        author:users(name, avatar, bio),
        chapters(id, title)
      `)
      .eq('id', storyId)
      .single();

    if (error) {
      console.error('Error fetching story:', error);
    } else {
      commit('setCurrentStory', data);
    }
  }
};

const mutations = {
  setStories(state, stories) {
    state.stories = stories;
  },
  setCurrentStory(state, story) {
    state.currentStory = story;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};