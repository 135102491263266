<template>
  <div>
    <OwnedUserProfile v-if="isOwnProfile" />
    <VisitedUserProfile v-else :username="visitedUsername" />
  </div>
</template>
<script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { supabase } from '@/supabase';
import OwnedUserProfile from './OwnedUserProfile.vue';
import VisitedUserProfile from './VisitedUserProfile.vue';
export default {
  name: 'ProfileWrapper',
  components: {
    OwnedUserProfile,
    VisitedUserProfile
  },
  setup() {
    const route = useRoute();
    const isOwnProfile = ref(true);
    const visitedUsername = ref(route.params.username);
    onMounted(async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user && visitedUsername.value) {
        const { data, error } = await supabase
          .from('users')
          .select('username')
          .eq('id', user.id)
          .single();
        if (error) {
          console.error('Error fetching user:', error);
        } else if (data) {
          isOwnProfile.value = data.username === visitedUsername.value;
        }
      }
    });
    return {
      isOwnProfile,
      visitedUsername
    };
  }
};
</script>