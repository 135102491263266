<template>
  <div v-if="error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
    <strong class="font-bold">Error!</strong>
    <span class="block sm:inline">{{ error }}</span>
  </div>
  <div v-if="story" class="story-details bg-gray-100 min-h-screen py-8">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="bg-white shadow-2xl rounded-lg overflow-hidden">
        <div class="md:flex">
          <div class="md:w-1/3">
            <img :src="story.cover_image" :alt="story.title" class="w-full h-64 object-cover rounded-lg shadow-md" />
          </div>
          <div class="p-8 md:w-2/3">
            <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">{{ story.genre }}</div>
            <h1 class="mt-1 text-4xl font-extrabold text-gray-900 leading-tight">{{ story.title }}</h1>

<div class="mt-4 space-x-4">
              <button @click="publishStory" v-if="!story.published" class="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-300">
                Publish Story
              </button>
              <button @click="unpublishStory" v-else class="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600 transition duration-300">
                Unpublish Story
              </button>
              <button @click="editStory" class="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300">
                Edit Story
              </button>
            </div>
            <div class="mt-4">
              <p :class="{'line-clamp-3': !showFullBlurb}" class="text-gray-600">{{ story.blurb }}</p>
              <button @click="toggleBlurb" class="mt-2 text-indigo-600 hover:text-indigo-800">
                {{ showFullBlurb ? 'Show Less' : 'Show More' }}
              </button>
            </div>
            <div class="mt-6 flex flex-wrap">
              <span v-for="tag in story.tags" :key="tag" class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#{{ tag }}</span>
            </div>
          </div>
        </div>
        
        <div class="px-8 py-6 bg-gray-50 flex justify-between items-center">
          <button @click="editStoryDetails" class="bg-indigo-600 text-white px-6 py-2 rounded-full hover:bg-indigo-700 transition duration-300">
            Edit Story Details
          </button>
          <button @click="previewStory" class="bg-green-500 text-white px-6 py-2 rounded-full hover:bg-green-600 transition duration-300">
            Preview Story
          </button>
        </div>
        
        <div class="px-8 py-6 grid grid-cols-2 gap-8">
          <div>
            <h2 class="text-2xl font-bold text-gray-900 mb-4">Story Details</h2>
            <div class="bg-indigo-100 p-6 rounded-lg">
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <p class="font-semibold">Target Audience:</p>
                  <p>{{ story.target_audience }}</p>
                </div>
                <div>
                  <p class="font-semibold">18+ Content:</p>
                  <p>{{ story.is_adult ? 'Yes' : 'No' }}</p>
                </div>
                <div>
                  <p class="font-semibold">Status:</p>
                  <p>{{ story.status }}</p>
                </div>
                <div>
                  <p class="font-semibold">Last Updated:</p>
                  <p>{{ formatDate(story.updated_at) }}</p>
                </div>
              </div>
            </div>
          </div>
          
          <div>
            <h2 class="text-2xl font-bold text-gray-900 mb-4">Statistics</h2>
            <div class="bg-indigo-100 p-6 rounded-lg">
              <div class="grid grid-cols-3 gap-4 text-center">
                <div class="bg-white p-4 rounded-lg shadow">
                  <p class="text-3xl font-bold text-indigo-500">{{ story.reads || 0 }}</p>
                  <p class="text-gray-600">Total Reads</p>
                </div>
                <div class="bg-white p-4 rounded-lg shadow">
                  <p class="text-3xl font-bold text-indigo-500">{{ story.applause || 0 }}</p>
                  <p class="text-gray-600">Applause</p>
                </div>
                <div class="bg-white p-4 rounded-lg shadow">
                  <p class="text-3xl font-bold text-indigo-500">{{ story.comments || 0 }}</p>
                  <p class="text-gray-600">Comments</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="px-8 py-6">
  <h2 class="text-2xl font-bold text-gray-900 mb-4">Table of Contents</h2>
  <ul v-if="story.chapters && story.chapters.length > 0" class="space-y-2">
    <li v-for="chapter in story.chapters" :key="chapter.id" class="flex justify-between items-center border-b pb-2">
      <a :href="`/story/${story.id}/chapter/${chapter.id}`" class="text-blue-600 hover:text-blue-800">{{ chapter.title }}</a>
      <div>
        <button @click="editChapter(chapter.id)" class="text-green-600 hover:text-green-800 mr-2">Edit</button>
        <button @click="unpublishChapter(chapter.id)" class="text-yellow-600 hover:text-yellow-800 mr-2">Unpublish</button>
        <button @click="deleteChapter(chapter.id)" class="text-red-600 hover:text-red-800">Delete</button>
      </div>
    </li>
  </ul>
  <p v-else class="text-gray-600">No chapters available yet.</p>
  <button @click="addNewChapter" class="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">
    + Add New Chapter
  </button>
</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { storyService } from '@/services/storyService';
import { useRoute, useRouter } from 'vue-router';
import { supabase } from '@/supabase';

export default {
  name: 'StoryDetails',
  setup() {
    const router = useRouter();
    const route = useRoute();
    const showFullBlurb = ref(false);
    const story = ref(null);
    const chapters = ref([]);
    const error = ref(null);

    const fetchStory = async () => {
  const { data, error } = await supabase
    .from('stories')
    .select(`
      *,
      author:users(name, avatar, bio)
    `)
    .eq('id', route.params.id)
    .single();

  if (error) {
    console.error('Error fetching story:', error);
    error.value = 'Failed to load story. Please try again.';
    return null;
  }

  // Fetch chapters separately
  const { data: chaptersData, error: chaptersError } = await supabase
    .from('chapters')
    .select('id, title, created_at, chapter_number')
    .eq('story_id', route.params.id)
    .order('chapter_number', { ascending: true });

  if (chaptersError) {
    console.error('Error fetching chapters:', chaptersError);
  }

  // Fetch reads, votes, and comments counts separately
  const [readsData, votesData, commentsData] = await Promise.all([
    supabase.from('story_reads').select('count').eq('story_id', route.params.id),
    supabase.from('story_votes').select('count').eq('story_id', route.params.id),
    supabase.from('story_comments').select('count').eq('story_id', route.params.id)
  ]);

  return {
    ...data,
    chapters: chaptersData || [],
    reads: readsData.data?.[0]?.count || 0,
    votes: votesData.data?.[0]?.count || 0,
    comments: commentsData.data?.[0]?.count || 0
  };
};

    const fetchChapters = async () => {
      try {
        const { data, error: chaptersError } = await supabase
          .from('chapters')
          .select('*')
          .eq('story_id', route.params.id)
          .order('chapter_number', { ascending: true });

        if (chaptersError) throw chaptersError;
        chapters.value = data;
      } catch (fetchError) {
        console.error('Error fetching chapters:', fetchError);
        error.value = 'Failed to load chapters. Please try again.';
      }
    };

    const publishStory = async () => {
  try {
    const updatedStory = await storyService.publishStory(story.value.id, true);
    story.value = { ...story.value, ...updatedStory };
  } catch (error) {
    console.error('Error publishing story:', error);
  }
};

const unpublishStory = async () => {
  try {
    const updatedStory = await storyService.publishStory(story.value.id, false);
    story.value = { ...story.value, ...updatedStory };
  } catch (error) {
    console.error('Error unpublishing story:', error);
  }
};

const editStory = () => {
  router.push(`/story/${story.value.id}/edit`);
};

    onMounted(async () => {
      const storyData = await fetchStory();
      if (storyData) {
        story.value = storyData;
        await fetchChapters();
      }
    });

    const toggleBlurb = () => {
      showFullBlurb.value = !showFullBlurb.value;
    };

    const editStoryDetails = () => {
      router.push(`/story/${story.value.id}/edit`);
    };

    const previewStory = () => {
      router.push(`/preview/${story.value.id}`);
    };

    const editChapter = (chapterId) => {
      router.push(`/write/${story.value.id}/chapter/${chapterId}`);
    };

    const unpublishChapter = async (chapterId) => {
      try {
        const { error: unpublishError } = await supabase
          .from('chapters')
          .update({ status: 'draft' })
          .eq('id', chapterId);

        if (unpublishError) throw unpublishError;
        await fetchChapters();
      } catch (unpublishError) {
        console.error('Error unpublishing chapter:', unpublishError);
        error.value = 'Failed to unpublish chapter. Please try again.';
      }
    };

    const deleteChapter = async (chapterId) => {
      if (confirm('Are you sure you want to delete this chapter?')) {
        try {
          const { error: deleteError } = await supabase
            .from('chapters')
            .delete()
            .eq('id', chapterId);

          if (deleteError) throw deleteError;
          await fetchChapters();
        } catch (deleteError) {
          console.error('Error deleting chapter:', deleteError);
          error.value = 'Failed to delete chapter. Please try again.';
        }
      }
    };

    const addNewChapter = () => {
      router.push(`/write/${story.value.id}/new-chapter`);
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    };

    return {
      story,
      chapters,
      showFullBlurb,
      toggleBlurb,
      editStoryDetails,
      previewStory,
      editChapter,
      unpublishChapter,
      deleteChapter,
      addNewChapter,
      publishStory,
      unpublishStory,
      editStory,
      formatDate,
      error
    };
  }
};
</script>
<style scoped>
.story-cover {
  height: 400px;
  width: 100%;
  object-fit: cover;
}
</style>