import { supabase } from '../supabase';

export const groupService = {
  async createGroup(name, description, creatorId) {
    const { data, error } = await supabase
      .from('groups')
      .insert({ name, description, creator_id: creatorId })
      .single();

    if (error) throw error;

    // Add creator as admin
    await this.addGroupMember(data.id, creatorId, 'admin');

    return data;
  },

  async getGroups(searchQuery = '') {
  let query = supabase
    .from('groups')
    .select(`
      *,
      members:group_members(user_id)
    `);

  if (searchQuery) {
    query = query.ilike('name', `%${searchQuery}%`);
  }

  const { data, error } = await query;

  if (error) throw error;

  // Process the data to extract the count and member info
  return data.map(group => ({
    ...group,
    memberCount: group.members.length,
    members: group.members  // Keep the full members array for filtering
  }));
},

  async searchUsers(query) {
    const { data, error } = await supabase
      .from('users')
      .select('id, username, avatar_url')
      .ilike('username', `%${query}%`)
      .limit(10);

    if (error) throw error;
    return data;
  },

  async getGroupDetails(groupId) {
    try {
      console.log('Fetching group details for:', groupId);
      const { data, error } = await supabase
        .from('groups')
        .select(`
          *,
          members:group_members(count),
          posts:group_posts!group_posts_group_id_fkey(
            id,
            title,
            created_at,
            user_id,
            author:users!fk_group_posts_user(id, username, avatar_url),
            comments:group_post_comments!fk_group_post_comments_post(count),
            likes:post_likes!fk_post_likes_post(count),
            bookmarks:post_bookmarks!fk_post_bookmarks_post(count)
          ),
          creator:users!fk_groups_creator(id, username)
        `)
        .eq('id', groupId)
        .single();

      if (error) {
        console.error('Supabase error:', error);
        throw error;
      }

      // Process the data to ensure all properties exist and have default values
      const processedData = {
        ...data,
        posts: data.posts.map(post => ({
          ...post,
          likes: post.likes?.[0]?.count ?? 0,
          comments: post.comments?.[0]?.count ?? 0,
          bookmarks: post.bookmarks?.[0]?.count ?? 0
        }))
      };

      console.log('Fetched and processed group details:', processedData);
      return processedData;
    } catch (error) {
      console.error('Error in getGroupDetails:', error);
      throw error;
    }
  },

  async updateGroup(groupId, name, description, rules) {
  const { data, error } = await supabase
    .from('groups')
    .update({ name, description, rules })
    .eq('id', groupId)
    .single();

  if (error) throw error;
  return data;
},

  async deleteGroup(groupId) {
    const { error } = await supabase
      .from('groups')
      .delete()
      .eq('id', groupId);

    if (error) throw error;
  },

  async addGroupMember(groupId, userId, role = 'member') {
  try {
    // First, check if the user is already a member
    const { data: existingMember, error: checkError } = await supabase
      .from('group_members')
      .select('id')
      .eq('group_id', groupId)
      .eq('user_id', userId)
      .single();

    if (checkError && checkError.code !== 'PGRST116') {
      // PGRST116 means no rows returned, which is expected if user is not a member
      throw checkError;
    }

    if (existingMember) {
      // User is already a member, return without trying to add again
      console.log('User is already a member of this group');
      return existingMember;
    }

    // If not a member, proceed with adding
    const { data, error } = await supabase
      .from('group_members')
      .insert({ group_id: groupId, user_id: userId, role })
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error in addGroupMember:', error);
    throw error;
  }
},

  async removeGroupMember(groupId, userId) {
    const { error } = await supabase
      .from('group_members')
      .delete()
      .eq('group_id', groupId)
      .eq('user_id', userId);

    if (error) throw error;
  },

  async updateMemberRole(groupId, userId, newRole) {
    const { data, error } = await supabase
      .from('group_members')
      .update({ role: newRole })
      .eq('group_id', groupId)
      .eq('user_id', userId)
      .single();

    if (error) throw error;
    return data;
  },

  async getGroupMembers(groupId) {
    try {
      const { data, error } = await supabase
        .from('group_members')
        .select(`
          *,
          user:users!fk_group_members_user(id, username, avatar_url)
        `)
        .eq('group_id', groupId);

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error in getGroupMembers:', error);
      throw error;
    }
  },

  async getRecentGroupComments(groupId, limit = 50) {
    try {
      const { data: posts, error: postsError } = await supabase
        .from('group_posts')
        .select('id')
        .eq('group_id', groupId);

      if (postsError) throw postsError;

      const postIds = posts.map(post => post.id);

      const { data, error } = await supabase
        .from('group_post_comments')
        .select(`
          id,
          content,
          created_at,
          user_id,
          post_id
        `)
        .in('post_id', postIds)
        .order('created_at', { ascending: false })
        .limit(limit);

      if (error) throw error;

      // Fetch user information separately
      const userIds = [...new Set(data.map(comment => comment.user_id))];
      const { data: users, error: usersError } = await supabase
        .from('users')
        .select('id, username')
        .in('id', userIds);

      if (usersError) throw usersError;

      // Combine comment data with user information
      const commentsWithUsers = data.map(comment => {
        const user = users.find(u => u.id === comment.user_id);
        return {
          ...comment,
          author: user
        };
      });

      return commentsWithUsers;
    } catch (error) {
      console.error('Error in getRecentGroupComments:', error);
      throw error;
    }
  },

  async createGroupPost(groupId, userId, title, content, options = {}) {
    try {
      const { data, error } = await supabase
        .from('group_posts')
        .insert({
          group_id: groupId,
          user_id: userId,
          title,
          content,
          created_at: new Date(),
          updated_at: new Date(),
          notifications_enabled: options.notifications || false
        })
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error in createGroupPost:', error);
      throw error;
    }
  },

  async getGroupPosts(groupId, userId, page = 1, limit = 10) {
  try {
    const { data, error } = await supabase
      .from('group_posts')
      .select(`
        *,
        author:users!fk_group_posts_user(id, username, avatar_url),
        comments:group_post_comments!fk_group_post_comments_post(count),
        likes:post_likes!fk_post_likes_post(count),
        user_like:post_likes!fk_post_likes_post(user_id)
      `)
      .eq('group_id', groupId)
      .order('created_at', { ascending: false })
      .range((page - 1) * limit, page * limit - 1);

    if (error) throw error;

    return data.map(post => ({
      ...post,
      likes: post.likes[0]?.count || 0,
      comments: post.comments[0]?.count || 0,
      isLiked: post.user_like.some(like => like.user_id === userId)
    }));
  } catch (error) {
    console.error('Error in getGroupPosts:', error);
    throw error;
  }
},

  async getPostDetails(postId) {
    const { data, error } = await supabase
      .from('group_posts')
      .select(`
        *,
        author:users(id, username, avatar_url),
        group:groups(id, name),
        comments:group_post_comments(count),
        likes:post_likes!fk_post_likes_post(count),
        bookmarks:post_bookmarks!fk_post_bookmarks_post(count),
        followers:post_followers!fk_post_followers_post(count)
      `)
      .eq('id', postId)
      .single();

    if (error) {
      console.error('Error fetching post details:', error);
      throw error;
    }

    // Increment views
    await this.incrementViews(postId);

    return data;
  },

  async isPostFollowed(postId, userId) {
  try {
    const { data, error } = await supabase
      .from('post_followers')
      .select('id')
      .eq('post_id', postId)
      .eq('user_id', userId)
      .single();

    if (error && error.code !== 'PGRST116') throw error;
    return !!data;
  } catch (error) {
    console.error('Error in isPostFollowed:', error);
    throw error;
  }
},

  async updateGroupPost(postId, title, content) {
    const { data, error } = await supabase
      .from('group_posts')
      .update({ title, content })
      .eq('id', postId)
      .single();

    if (error) throw error;
    return data;
  },

  async deleteGroupPost(postId) {
    const { error } = await supabase
      .from('group_posts')
      .delete()
      .eq('id', postId);

    if (error) throw error;
  },

  async createComment(postId, userId, content, parentCommentId = null) {
    const { data, error } = await supabase
      .from('group_post_comments')
      .insert({ post_id: postId, user_id: userId, content, parent_comment_id: parentCommentId })
      .single();

    if (error) throw error;
    return data;
  },

  async getComments(postId, page = 1, limit = 20) {
    const { data, error } = await supabase
      .from('group_post_comments')
      .select(`
        *,
        author:users(id, username, avatar_url),
        replies:group_post_comments(
          id,
          content,
          created_at,
          author:users(id, username, avatar_url)
        )
      `)
      .eq('post_id', postId)
      .is('parent_comment_id', null)
      .order('created_at', { ascending: true })
      .range((page - 1) * limit, page * limit - 1);

    if (error) throw error;
    return data;
  },

  async updateComment(commentId, content) {
    const { data, error } = await supabase
      .from('group_post_comments')
      .update({ content })
      .eq('id', commentId)
      .single();

    if (error) throw error;
    return data;
  },

  async deleteComment(commentId) {
    const { error } = await supabase
      .from('group_post_comments')
      .delete()
      .eq('id', commentId);

    if (error) throw error;
  },

  async isGroupMember(groupId, userId) {
    const { data, error } = await supabase
      .from('group_members')
      .select('role')
      .eq('group_id', groupId)
      .eq('user_id', userId)
      .single();

    if (error && error.code !== 'PGRST116') throw error; // PGRST116 is the error code for no rows returned
    return data ? data.role : null;
  },

  async submitGroupProposal(name, description, creatorId, targetUsers, willModerate) {
    const { data, error } = await supabase
      .from('group_proposals')
      .insert({ 
        name, 
        description, 
        creator_id: creatorId, 
        target_users: targetUsers,
        will_moderate: willModerate,
        status: 'pending'
      })
      .single();

    if (error) throw error;
    return data;
  },

  async getGroupProposals() {
    const { data, error } = await supabase
      .from('group_proposals')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data;
  },

  async approveGroupProposal(proposalId) {
    // Fetch the proposal
    const { data: proposal, error: fetchError } = await supabase
      .from('group_proposals')
      .select('*')
      .eq('id', proposalId)
      .single();

    if (fetchError) throw fetchError;

    // Create the group
    const { data: group, error: createError } = await supabase
      .from('groups')
      .insert({
        name: proposal.name,
        description: proposal.description,
        creator_id: proposal.creator_id,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      });

    if (createError) throw createError;

    // Update the proposal status to 'approved'
    const { error: updateError } = await supabase
      .from('group_proposals')
      .update({ status: 'approved' })
      .eq('id', proposalId);

    if (updateError) throw updateError;

    return group;
  },

  async incrementViews(postId) {
    const { error } = await supabase.rpc('increment_post_views', { post_id: postId });
    if (error) throw error;
  },

  async isPostLiked(postId, userId) {
  try {
    console.log(`Checking if post ${postId} is liked by user ${userId}`);
    const { data, error, status } = await supabase
      .from('post_likes')
      .select('id')
      .eq('post_id', postId)
      .eq('user_id', userId)
      .single();

    console.log('Response:', { data, error, status });

    if (error && error.code !== 'PGRST116') {
      console.error('Error in isPostLiked:', error);
      throw error;
    }
    return !!data;
  } catch (error) {
    console.error('Error in isPostLiked:', error);
    throw error;
  }
},

  async isPostBookmarked(postId, userId) {
    const { data, error } = await supabase
      .from('post_bookmarks')
      .select('id')
      .eq('post_id', postId)
      .eq('user_id', userId)
      .single();

    if (error && error.code !== 'PGRST116') throw error;
    return !!data;
  },

  async getBookmarkedPosts(userId, groupId) {
    try {
      const { data, error } = await supabase
        .from('post_bookmarks')
        .select(`
          post_id,
          group_posts!fk_post_bookmarks_post(
            id,
            title,
            content,
            created_at,
            group_id,
            author:users!inner(id, username, avatar_url),
            likes:post_likes!fk_post_likes_post(count),
            comments:group_post_comments!fk_group_post_comments_post(count)
          )
        `)
        .eq('user_id', userId)
        .eq('group_posts.group_id', groupId);

      if (error) throw error;

      return data
        .filter(item => item.group_posts !== null)
        .map(item => ({
          ...item.group_posts,
          likes: item.group_posts.likes?.[0]?.count ?? 0,
          comments: item.group_posts.comments?.[0]?.count ?? 0
        }));
    } catch (error) {
      console.error('Error in getBookmarkedPosts:', error);
      return []; // Return an empty array if there's an error
    }
  },

  async likePost(postId, userId) {
    const { data, error } = await supabase
      .from('post_likes')
      .upsert({ post_id: postId, user_id: userId }, { onConflict: 'post_id,user_id' });

    if (error) throw error;
    return data;
  },

  async unlikePost(postId, userId) {
    const { error } = await supabase
      .from('post_likes')
      .delete()
      .eq('post_id', postId)
      .eq('user_id', userId);

    if (error) throw error;
  },

  async bookmarkPost(postId, userId) {
    const { data, error } = await supabase
      .from('post_bookmarks')
      .upsert({ post_id: postId, user_id: userId }, { onConflict: 'post_id,user_id' });

    if (error) throw error;
    return data;
  },

  async unbookmarkPost(postId, userId) {
    const { error } = await supabase
      .from('post_bookmarks')
      .delete()
      .eq('post_id', postId)
      .eq('user_id', userId);

    if (error) throw error;
  },

  async followPost(postId, userId) {
    const { data, error } = await supabase
      .from('post_followers')
      .upsert({ post_id: postId, user_id: userId }, { onConflict: 'post_id,user_id' });

    if (error) throw error;
    return data;
  },

  async unfollowPost(postId, userId) {
    const { error } = await supabase
      .from('post_followers')
      .delete()
      .eq('post_id', postId)
      .eq('user_id', userId);

    if (error) throw error;
  },

  async likeComment(commentId, userId) {
  try {
    const { data, error } = await supabase
      .from('comment_likes')
      .upsert({ comment_id: commentId, user_id: userId }, { onConflict: 'comment_id,user_id' });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error in likeComment:', error);
    throw error;
  }
},

  async isCommentLiked(commentId, userId) {
  try {
    const { data, error } = await supabase
      .from('comment_likes')
      .select('id')
      .eq('comment_id', commentId)
      .eq('user_id', userId)
      .single();

    if (error && error.code !== 'PGRST116') throw error;
    return !!data;
  } catch (error) {
    console.error('Error in isCommentLiked:', error);
    return false;
  }
},

  async rejectGroupProposal(proposalId) {
    const { error } = await supabase
      .from('group_proposals')
      .update({ status: 'rejected' })
      .eq('id', proposalId);

    if (error) throw error;
  },

  async createPendingInvitation(groupId, inviterId, inviteeId) {
    const { data, error } = await supabase
      .from('group_invitations')
      .insert({
        group_id: groupId,
        inviter_id: inviterId,
        invitee_id: inviteeId,
        status: 'pending'
      })
      .single();

    if (error) throw error;
    return data;
  },

  async inviteUserToGroup(groupId, userId) {
  const { data, error } = await supabase
    .from('group_invitations')
    .insert({
      group_id: groupId,
      invitee_id: userId,
      status: 'pending'
    })
    .single();

  if (error) throw error;
  return data;
},

  async getPendingInvitations(userId) {
    const { data, error } = await supabase
      .from('group_invitations')
      .select(`
        *,
        group:groups(id, name),
        inviter:users!inviter_id(id, username, avatar_url)
      `)
      .eq('invitee_id', userId)
      .eq('status', 'pending');

    if (error) throw error;
    return data;
  },

  async acceptInvitation(invitationId) {
    const { data: invitation, error: fetchError } = await supabase
      .from('group_invitations')
      .select('*')
      .eq('id', invitationId)
      .single();

    if (fetchError) throw fetchError;

    // Add user to group
    await this.addGroupMember(invitation.group_id, invitation.invitee_id);

    // Update invitation status
    const { error: updateError } = await supabase
      .from('group_invitations')
      .update({ status: 'accepted' })
      .eq('id', invitationId);

    if (updateError) throw updateError;
  },

  async declineInvitation(invitationId) {
    const { error } = await supabase
      .from('group_invitations')
      .update({ status: 'declined' })
      .eq('id', invitationId);

    if (error) throw error;
  }
};