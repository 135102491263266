<template>
  <div class="personalized-dashboard bg-gray-100 min-h-screen py-8">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h1 class="text-3xl font-bold text-gray-900 mb-8">
        Welcome, {{ displayUsername }}!
      </h1>
      
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <!-- Main Content Column -->
        <div class="lg:col-span-2 space-y-8">
          <!-- Featured Stories Carousel -->
          <div class="bg-white rounded-lg shadow-md p-6">
            <h2 class="text-2xl font-bold text-gray-900 mb-4">Featured Stories</h2>
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
              <div v-for="story in featuredStories" :key="story.id" class="bg-gray-50 rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-300">
                <router-link :to="{ name: 'StoryPage', params: { id: story.id.toString() } }">
                  <img :src="story.coverImage" :alt="story.title" class="w-full h-auto object-cover">
                  <div class="p-4">
                    <h3 class="font-semibold text-lg mb-2 truncate">{{ story.title }}</h3>
                    <p class="text-sm text-gray-600 mb-2">
                      <router-link :to="{ name: 'VisitedUserProfile', params: { username: story.authorUsername } }">
                        by {{ story.authorUsername }}
                      </router-link>
                    </p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          
          <!-- Current Challenge (Collapsible) -->
          <div class="bg-gradient-to-r from-purple-500 to-indigo-600 rounded-lg shadow-md p-6 text-white">
            <h2 @click="toggleChallenge" class="text-2xl font-bold mb-4 cursor-pointer flex items-center">
              Current Writing Challenge
              <svg :class="{'rotate-180': challengeExpanded}" class="ml-2 h-5 w-5 transform transition-transform duration-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </h2>
            <div v-if="currentChallenge && challengeExpanded" class="bg-white bg-opacity-20 p-4 rounded-lg">
              <h3 class="font-semibold text-xl">{{ currentChallenge.title }}</h3>
              <p class="mt-2">{{ currentChallenge.description }}</p>
              <p class="mt-2 font-semibold">Deadline: {{ currentChallenge.deadline }}</p>
              <div class="mt-4 space-x-4">
                <router-link 
                  :to="{ name: 'Challenges' }" 
                  class="inline-block bg-white text-purple-600 px-6 py-2 rounded-full hover:bg-gray-100 transition duration-300"
                >
                  Write
                </router-link>
                <router-link 
                  :to="{ name: 'Challenges' }" 
                  class="inline-block bg-purple-700 text-white px-6 py-2 rounded-full hover:bg-purple-800 transition duration-300"
                >
                  Vote
                </router-link>
              </div>
            </div>
            <p v-else-if="!currentChallenge" class="text-white">No active challenges at the moment. Check back soon!</p>
          </div>
          
          <!-- Personalized Recommendations -->
          <div class="bg-white rounded-lg shadow-md p-6">
            <h2 class="text-2xl font-bold text-gray-900 mb-4">Recommended for You</h2>
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div v-for="book in recommendations" :key="book.id" class="bg-gray-50 rounded-lg p-4 flex items-center space-x-4">
                <router-link :to="{ name: 'StoryPage', params: { id: book.id.toString() } }" class="flex-shrink-0">
                  <img :src="book.coverImage" :alt="book.title" class="w-20 h-auto object-cover rounded">
                </router-link>
                <div>
                  <h3 class="font-semibold">{{ book.title }}</h3>
                  <p class="text-sm text-gray-600">
                    <router-link :to="{ name: 'VisitedUserProfile', params: { username: book.authorUsername } }">
                      by {{ book.authorUsername }}
                    </router-link>
                  </p>
                  <button 
  @click="addToBookshelf(book.id)" 
  :disabled="bookshelfStatus[book.id] === 'added'"
  class="mt-2 px-4 py-1 rounded-full text-sm transition duration-300"
  :class="{
    'bg-green-600 text-white hover:bg-green-700': !bookshelfStatus[book.id],
    'bg-gray-400 text-white': bookshelfStatus[book.id] === 'added',
    'bg-red-600 text-white': bookshelfStatus[book.id] === 'error'
  }"
>
  {{ bookshelfStatus[book.id] === 'added' ? 'Added to Bookshelf' : 
     bookshelfStatus[book.id] === 'error' ? 'Error' : 'Add to Bookshelf' }}
</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- Sidebar Column -->
      <div class="space-y-8">
        <!-- Reading Progress -->
        <div class="bg-white rounded-lg shadow-md p-6">
          <h2 class="text-xl font-semibold mb-4">Continue Reading</h2>
          <div v-if="currentlyReading.length > 0" class="space-y-4 max-h-96 overflow-y-auto pr-2">
            <div v-for="book in currentlyReading" :key="book.id" class="bg-gray-50 p-4 rounded-lg flex items-start">
              <router-link :to="{ name: 'StoryPage', params: { id: book.id.toString() } }" class="flex-shrink-0 mr-4">
                <img :src="book.coverImage" :alt="book.title" class="w-24 h-36 object-cover rounded">
              </router-link>
              <div class="flex-grow">
                <h3 class="font-semibold">{{ book.title }}</h3>
                <p class="text-sm text-gray-600">
                  <router-link :to="{ name: 'VisitedUserProfile', params: { username: book.authorUsername } }">
                    by {{ book.authorUsername }}
                  </router-link>
                </p>
                <div class="mt-2 bg-gray-200 h-2 rounded-full">
                  <div class="bg-blue-600 h-2 rounded-full" :style="{ width: `${book.progress}%` }"></div>
                </div>
                <p class="text-sm mt-1">{{ book.chaptersRead }} / {{ book.totalChapters }} chapters read</p>
              </div>
            </div>
          </div>
          <p v-else class="text-gray-600">You're not currently reading any books. Why not start one?</p>
        </div>
          
          <!-- User Groups -->
          <div class="bg-white rounded-lg shadow-md p-6">
            <h2 class="text-xl font-semibold mb-4">Your Groups</h2>
            <div class="space-y-4">
              <div v-for="group in userGroups" :key="group.id" class="bg-gray-50 p-4 rounded-lg">
                <h3 class="font-semibold">{{ group.name }}</h3>
                <p class="text-sm text-gray-600 mt-1">{{ group.description }}</p>
                <router-link 
                  :to="{ name: 'GroupDetails', params: { id: group.id.toString() } }" 
                  class="mt-2 inline-block bg-green-600 text-white px-4 py-1 rounded-full text-sm hover:bg-green-700 transition duration-300"
                >
                  View Group
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { supabase } from '@/supabase'

export default {
  name: 'PersonalizedDashboard',
  setup() {
    const store = useStore()
    const user = computed(() => store.getters['auth/currentUser'])
    const isLoggedIn = computed(() => !!user.value && Object.keys(user.value).length > 0)
    const displayUsername = ref('Guest')
    const featuredStories = ref([])
    const currentChallenge = ref(null)
    const userGroups = ref([])
    const currentlyReading = ref([])
    const recommendations = ref([])
    const challengeExpanded = ref(true)

    onMounted(async () => {
      await Promise.all([
        fetchFeaturedStories(),
        fetchCurrentChallenge(),
        fetchUserGroups(),
        fetchCurrentlyReading(),
        fetchRecommendations(),
        fetchBookshelfStatus()
      ])
    })

    onMounted(() => {
      console.log('Current user:', user.value)
      console.log('Is logged in:', isLoggedIn.value)
      fetchCurrentUsername()
    })

    const fetchCurrentUsername = async () => {
      if (user.value && user.value.id) {
        try {
          const { data, error } = await supabase
            .from('users')
            .select('username')
            .eq('id', user.value.id)
            .single()

          if (error) throw error

          if (data && data.username) {
            displayUsername.value = data.username
          } else {
            console.warn('Username not found in database, falling back to auth data')
            displayUsername.value = user.value.user_metadata?.username || user.value.email?.split('@')[0] || 'Guest'
          }
        } catch (error) {
          console.error('Error fetching current username:', error)
          displayUsername.value = user.value.user_metadata?.username || user.value.email?.split('@')[0] || 'Guest'
        }
      }
    }

    async function fetchFeaturedStories() {
  const { data, error } = await supabase
    .from('featured_stories')
    .select(`
      id,
      story_id,
      stories:story_id (
        id,
        title,
        cover_image,
        users:author_id (username)
      )
    `)
    .order('featured_order', { ascending: true })
    .limit(4)

  if (error) {
    console.error('Error fetching featured stories:', error)
    return
  }

  featuredStories.value = data.map(item => ({
    id: item.stories.id,
    title: item.stories.title,
    authorUsername: item.stories.users.username,
    coverImage: item.stories.cover_image
  }))
}
    async function fetchCurrentChallenge() {
  const { data, error } = await supabase
    .from('challenges')
    .select('*')
    .eq('status', 'active')
    .order('created_at', { ascending: false })
    .limit(1)
    .single()

  if (error) {
    console.error('Error fetching current challenge:', error)
    return
  }

  if (data) {
    currentChallenge.value = {
      title: data.title,
      description: data.description,
      deadline: new Date(data.end_date).toLocaleDateString()
    }
  }
}
    async function fetchUserGroups() {
  const { data, error } = await supabase
    .from('group_members')
    .select(`
      groups (
        id,
        name,
        description
      )
    `)
    .eq('user_id', user.value.id)
    .limit(3)

  if (error) {
    console.error('Error fetching user groups:', error)
    return
  }

  userGroups.value = data.map(item => ({
    id: item.groups.id,
    name: item.groups.name,
    description: item.groups.description
  }))
}
    async function fetchCurrentlyReading() {
  const { data, error } = await supabase
    .from('user_reading_progress')
    .select(`
      id,
      progress,
      last_read_chapter,
      stories:story_id (
        id,
        title,
        cover_image,
        users:author_id (username),
        chapters (count)
      )
    `)
    .eq('user_id', user.value.id)
    .order('last_read_at', { ascending: false })
    .limit(5)

  if (error) {
    console.error('Error fetching currently reading:', error)
    return
  }

  currentlyReading.value = data.map(item => ({
    id: item.stories.id,
    title: item.stories.title,
    authorUsername: item.stories.users.username,
    coverImage: item.stories.cover_image,
    progress: Math.round((item.last_read_chapter / item.stories.chapters.count) * 100),
    chaptersRead: item.last_read_chapter,
    totalChapters: item.stories.chapters.count
  }))
}
    async function fetchRecommendations() {
  // This is a simplified recommendation system
  // In a real-world scenario, you'd use more sophisticated algorithms
  const { data, error } = await supabase
    .from('stories')
    .select(`
      id,
      title,
      cover_image,
      users:author_id (username)
    `)
    .order('reads', { ascending: false })
    .limit(4)

  if (error) {
    console.error('Error fetching recommendations:', error)
    return
  }

  recommendations.value = data.map(item => ({
    id: item.id,
    title: item.title,
    authorUsername: item.users.username,
    coverImage: item.cover_image
  }))
}

  function toggleChallenge() {
      challengeExpanded.value = !challengeExpanded.value
    }

  const bookshelfStatus = ref({});

const fetchBookshelfStatus = async () => {
  const { data, error } = await supabase
    .from('bookshelf')
    .select('story_id, status')
    .eq('user_id', user.value.id);

  if (error) {
    console.error('Error fetching bookshelf status:', error);
  } else {
    data.forEach(item => {
      bookshelfStatus.value[item.story_id] = 'added';
    });
  }
};

const addToBookshelf = async (storyId, status = 'Want to Read') => {
  const { error } = await supabase
    .from('bookshelf')
    .upsert({
      user_id: user.value.id,
      story_id: storyId,
      status: status
    }, {
      onConflict: 'user_id,story_id',
      returning: 'minimal'
    });

  if (error) {
    console.error('Error adding to bookshelf:', error);
    bookshelfStatus.value[storyId] = 'error';
  } else {
    console.log('Successfully added/updated bookshelf entry');
    bookshelfStatus.value[storyId] = 'added';
    // Reset status after 3 seconds
    setTimeout(() => {
      bookshelfStatus.value[storyId] = null;
    }, 3000);
  }
};

    return {
      user,
      isLoggedIn,
      displayUsername,
      featuredStories,
      currentChallenge,
      userGroups,
      currentlyReading,
      recommendations,
      challengeExpanded,
      toggleChallenge,
      addToBookshelf,
      bookshelfStatus
    }
  }
}
</script>