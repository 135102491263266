import { supabase } from '@/supabase';

export const adminService = {
  // Group Proposals
  async getGroupProposals() {
    const { data, error } = await supabase
      .from('group_proposals')
      .select(`
        *,
        creator:users!creator_id(username)
      `)
      .eq('status', 'pending')
      .order('created_at', { ascending: false });
    
    if (error) throw error;
    return data;
  },

  async logAdminAction(actionType, targetType, targetId, details = {}) {
  const { data: { user } } = await supabase.auth.getUser();
  const { data, error } = await supabase
    .from('admin_audit_log')
    .insert({
      admin_id: user.id,
      action_type: actionType,
      target_type: targetType,
      target_id: targetId,
      details: details
    });

  if (error) throw error;
  return data;
},

  async approveGroupProposal(proposalId) {
  const { data: proposal, error: fetchError } = await supabase
    .from('group_proposals')
    .select('*')
    .eq('id', proposalId)
    .single();

  if (fetchError) throw fetchError;

  const { data: group, error: insertError } = await supabase
    .from('groups')
    .insert({
      name: proposal.name,
      description: proposal.description,
      creator_id: proposal.creator_id,
      target_users: proposal.target_users
    })
    .select()
    .single();

  if (insertError) throw insertError;

  const { error: updateError } = await supabase
    .from('group_proposals')
    .update({ status: 'approved' })
    .eq('id', proposalId);

  if (updateError) throw updateError;

  await this.logAdminAction('approve_group_proposal', 'group_proposal', proposalId, { group_id: group.id });
},

async rejectGroupProposal(proposalId) {
  const { error } = await supabase
    .from('group_proposals')
    .update({ status: 'rejected' })
    .eq('id', proposalId);

  if (error) throw error;

  await this.logAdminAction('reject_group_proposal', 'group_proposal', proposalId);
},

  // User Management
  async getUsers() {
    const { data, error } = await supabase
      .from('users')
      .select('*')
      .order('created_at', { ascending: false });
    
    if (error) throw error;
    return data;
  },

  async banUser(userId) {
  console.log('Banning user:', userId);
  const { data, error } = await supabase
    .from('users')
    .update({ is_banned: true })
    .eq('id', userId)
    .select();

  if (error) {
    console.error('Error banning user:', error);
    throw error;
  }
  if (!data || data.length === 0) {
    console.error('User not found:', userId);
    throw new Error(`User not found: ${userId}`);
  }
  console.log('User banned:', data[0]);
  return data[0];
},

async unbanUser(userId) {
  console.log('Unbanning user:', userId);
  const { data, error } = await supabase
    .from('users')
    .update({ is_banned: false })
    .eq('id', userId)
    .select();

  if (error) {
    console.error('Error unbanning user:', error);
    throw error;
  }
  if (!data || data.length === 0) {
    console.error('User not found:', userId);
    throw new Error(`User not found: ${userId}`);
  }
  console.log('User unbanned:', data[0]);
  return data[0];
},

async suspendUser(userId) {
  console.log('Suspending user:', userId);
  const { data, error } = await supabase
    .from('users')
    .update({ is_suspended: true })
    .eq('id', userId)
    .select();

  if (error) {
    console.error('Error suspending user:', error);
    throw error;
  }
  if (!data || data.length === 0) {
    console.error('User not found:', userId);
    throw new Error(`User not found: ${userId}`);
  }
  console.log('User suspended:', data[0]);
  return data[0];
},

async unsuspendUser(userId) {
  console.log('Unsuspending user:', userId);
  const { data, error } = await supabase
    .from('users')
    .update({ is_suspended: false })
    .eq('id', userId)
    .select();

  if (error) {
    console.error('Error unsuspending user:', error);
    throw error;
  }
  if (!data || data.length === 0) {
    console.error('User not found:', userId);
    throw new Error(`User not found: ${userId}`);
  }
  console.log('User unsuspended:', data[0]);
  return data[0];
},

async setUserAsAdmin(userId) {
  console.log('Setting user as admin:', userId);
  const { data, error } = await supabase
    .from('users')
    .update({ is_admin: true })
    .eq('id', userId)
    .select();

  if (error) {
    console.error('Error setting user as admin:', error);
    throw error;
  }
  if (!data || data.length === 0) {
    console.error('User not found:', userId);
    throw new Error(`User not found: ${userId}`);
  }
  console.log('User set as admin:', data[0]);
  return data[0];
},

async removeUserAsAdmin(userId) {
  console.log('Removing admin status from user:', userId);
  const { data, error } = await supabase
    .from('users')
    .update({ is_admin: false })
    .eq('id', userId)
    .select();

  if (error) {
    console.error('Error removing admin status from user:', error);
    throw error;
  }
  if (!data || data.length === 0) {
    console.error('User not found:', userId);
    throw new Error(`User not found: ${userId}`);
  }
  console.log('Admin status removed from user:', data[0]);
  return data[0];
},

  // Featured Stories
  async getFeaturedStories() {
  const { data, error } = await supabase
    .from('featured_stories')
    .select(`
      *,
      story:stories!story_id(
        id,
        title,
        cover_image,
        author:users!author_id(username)
      )
    `)
    .order('featured_order');
  
  if (error) {
    console.error('Error fetching featured stories:', error);
    throw error;
  }
  return data;
},

  async getAvailableStories() {
    const { data, error } = await supabase
      .from('stories')
      .select(`
        id,
        title,
        author:users!author_id(username)
      `)
      .order('title');
    
    if (error) {
      console.error('Error fetching available stories:', error);
      throw error;
    }
    return data;
  },

  async addFeaturedStory(storyId, order) {
    const { error } = await supabase
      .from('featured_stories')
      .insert({ 
        story_id: storyId, 
        featured_order: order 
      });
    
    if (error) throw error;
    await this.logAdminAction('add_featured_story', 'story', storyId, { order });
  },

  async removeFeaturedStory(storyId) {
    const { error } = await supabase
      .from('featured_stories')
      .delete()
      .eq('story_id', storyId);
    
    if (error) throw error;
    await this.logAdminAction('remove_featured_story', 'story', storyId);
  },

  async updateFeaturedStoryOrder(storyId, newOrder) {
    const { error } = await supabase
      .from('featured_stories')
      .update({ featured_order: newOrder })
      .eq('story_id', storyId);
    
    if (error) throw error;
    await this.logAdminAction('update_featured_story_order', 'story', storyId, { new_order: newOrder });
  },

  // Admin Broadcasts
  async getBroadcasts() {
    const { data, error } = await supabase
      .from('admin_broadcasts')
      .select(`
        *,
        creator:users!created_by(username)
      `)
      .order('created_at', { ascending: false });
    
    if (error) throw error;
    return data;
  },

  async createBroadcast(content, expiresAt, createdBy) {
    const { data, error } = await supabase
      .from('admin_broadcasts')
      .insert({
        content,
        expires_at: expiresAt,
        created_by: createdBy,
        is_active: true
      })
      .select();
    
    if (error) throw error;
    await this.logAdminAction('create_broadcast', 'admin_broadcast', data[0].id);
    return data[0];
  },

  async deleteBroadcast(id) {
    const { error } = await supabase
      .from('admin_broadcasts')
      .delete()
      .eq('id', id);
    
    if (error) throw error;
    await this.logAdminAction('delete_broadcast', 'admin_broadcast', id);
  },

  // New function to search stories
  async searchStories(query) {
  console.log('Searching for:', query);
  const { data, error } = await supabase
    .from('stories')
    .select(`
      id,
      title,
      author:users!author_id(username)
    `)
    .filter('title', 'ilike', `%${query}%`)
    .order('title')
    .limit(20);
  
  if (error) {
    console.error('Error searching stories by title:', error);
    throw error;
  }

  console.log('Search results by title:', data);

  // If we didn't find any results by title, search by author username
  if (data.length === 0) {
    const { data: authorData, error: authorError } = await supabase
      .from('stories')
      .select(`
        id,
        title,
        author:users!author_id(username)
      `)
      .filter('users.username', 'ilike', `%${query}%`)
      .order('title')
      .limit(20);

    if (authorError) {
      console.error('Error searching stories by author:', authorError);
      throw authorError;
    }

    console.log('Search results by author:', authorData);
    return authorData;
  }

  return data;
},

  // New function to get story details
  async getStoryDetails(storyId) {
    const { data, error } = await supabase
      .from('stories')
      .select(`
        *,
        author:users!author_id(username)
      `)
      .eq('id', storyId)
      .single();
    
    if (error) {
      console.error('Error fetching story details:', error);
      throw error;
    }
    return data;
  }
};