import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { refreshSupabaseSchema } from './supabase'
import './assets/tailwind.css'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const app = createApp(App)

// Toast options (you need to define these)
const options = {
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnHover: true,
};

app.use(store).use(router).use(Toast, options);

// Refresh Supabase schema before mounting the app
refreshSupabaseSchema().then(() => {
  app.mount('#app')
})