<template>
  <div class="bookshelf bg-gray-100 min-h-screen py-8">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h1 class="text-3xl font-bold text-gray-900 mb-8">My Bookshelf</h1>

      <div class="mb-6 flex justify-between items-center">
        <div class="flex space-x-4">
          <button
            v-for="shelf in shelves"
            :key="shelf"
            @click="currentShelf = shelf"
            :class="[
              'px-4 py-2 rounded-full font-medium',
              currentShelf === shelf
                ? 'bg-indigo-600 text-white'
                : 'bg-white text-gray-700 hover:bg-gray-100'
            ]"
          >
            {{ shelf }}
          </button>
        </div>
        <div class="flex space-x-4">
          <input
            v-model="searchQuery"
            type="text"
            placeholder="Search books..."
            class="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          <button
            @click="showAddBookModal = true"
            class="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition duration-300"
          >
            Add Book
          </button>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div
          v-for="book in filteredBooks"
          :key="book.id"
          class="bg-white rounded-lg shadow-md overflow-hidden transition duration-300 hover:shadow-lg"
        >
          <img :src="book.cover_image" :alt="book.title" class="w-full h-64 object-cover">
          <div class="p-4">
            <h3 class="font-semibold text-lg mb-2">{{ book.title }}</h3>
            <p class="text-gray-600 mb-2">by {{ book.author }}</p>
            <div class="flex justify-between items-center">
              <span :class="getStatusClass(book.status)">{{ book.status }}</span>
              <button
                @click="openBookMenu(book)"
                class="text-gray-500 hover:text-gray-700"
              >
                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                </svg>
              </button>
            </div>
            <div class="mt-4 flex justify-between">
              <button @click="readBook(book)" class="text-indigo-600 hover:text-indigo-800">Read</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Book Modal -->
    <div v-if="showAddBookModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div class="bg-white rounded-lg p-8 max-w-md w-full">
        <h2 class="text-2xl font-bold mb-4">Add a Book to Your Bookshelf</h2>
        <form @submit.prevent="addBook">
          <div class="mb-4">
            <label for="search" class="block text-sm font-medium text-gray-700">Search for a book</label>
            <input
              v-model="bookSearch"
              @input="searchBooks"
              type="text"
              id="search"
              placeholder="Enter book title or author..."
              class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
          </div>
          <div v-if="searchResults.length > 0" class="mb-4">
            <label class="block text-sm font-medium text-gray-700">Select a book</label>
            <select
              v-model="selectedBook"
              class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option v-for="book in searchResults" :key="book.id" :value="book">
                {{ book.title }} by {{ book.author }}
              </option>
            </select>
          </div>
          <div class="mb-4">
            <label for="status" class="block text-sm font-medium text-gray-700">Status</label>
            <select
              v-model="newBookStatus"
              id="status"
              required
              class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="Want to Read">Want to Read</option>
              <option value="Currently Reading">Currently Reading</option>
              <option value="Read">Read</option>
            </select>
          </div>
          <div class="flex justify-end">
            <button
              type="submit"
              class="bg-indigo-600 text-white px-4 py-2 rounded-lg"
              :disabled="!selectedBook"
            >
              Add to Bookshelf
            </button>
            <button @click="closeAddBookModal" class="ml-2 text-gray-600">Cancel</button>
          </div>
        </form>
      </div>
    </div>

    <!-- Book Menu Modal -->
    <div v-if="selectedBook" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div class="bg-white rounded-lg p-8 max-w-md w-full">
        <h2 class="text-2xl font-bold mb-4">{{ selectedBook.title }}</h2>
        <div class="space-y-4">
          <button @click="updateBookStatus('Want to Read')" class="w-full text-left px-4 py-2 hover:bg-gray-100">
            Mark as Want to Read
          </button>
          <button @click="updateBookStatus('Currently Reading')" class="w-full text-left px-4 py-2 hover:bg-gray-100">
            Mark as Currently Reading
          </button>
          <button @click="updateBookStatus('Read')" class="w-full text-left px-4 py-2 hover:bg-gray-100">
            Mark as Read
          </button>
          <button @click="removeBook" class="w-full text-left px-4 py-2 text-red-600 hover:bg-gray-100">
            Remove from Bookshelf
          </button>
        </div>
        <button @click="closeBookMenu" class="mt-4 text-gray-600">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { supabase } from '@/supabase'

export default {
  name: 'MyBookshelf',
  setup() {
    const router = useRouter()
    const shelves = ['All', 'Want to Read', 'Currently Reading', 'Read']
    const currentShelf = ref('All')
    const searchQuery = ref('')
    const books = ref([])
    const showAddBookModal = ref(false)
    const selectedBook = ref(null)
    const newBookStatus = ref('Want to Read')
    const bookSearch = ref('')
    const searchResults = ref([])

    const fetchBooks = async () => {
      const { data: { user } } = await supabase.auth.getUser()
      const { data, error } = await supabase
        .from('bookshelf')
        .select(`
          id,
          status,
          stories (
            id,
            title,
            cover_image,
            users!author_id (username)
          )
        `)
        .eq('user_id', user.id)

      if (error) {
        console.error('Error fetching books:', error)
      } else {
        books.value = data.map(item => ({
          id: item.stories.id,
          title: item.stories.title,
          author: item.stories.users.username,
          status: item.status,
          cover_image: item.stories.cover_image
        }))
      }
    }

    onMounted(fetchBooks)

    const filteredBooks = computed(() => {
      return books.value
        .filter(book => currentShelf.value === 'All' || book.status === currentShelf.value)
        .filter(book => 
          book.title.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          book.author.toLowerCase().includes(searchQuery.value.toLowerCase())
        )
    })

    const getStatusClass = (status) => {
      switch (status) {
        case 'Read': return 'text-green-600'
        case 'Currently Reading': return 'text-blue-600'
        case 'Want to Read': return 'text-yellow-600'
        default: return 'text-gray-600'
      }
    }

    const openBookMenu = (book) => {
      selectedBook.value = book
    }

    const closeBookMenu = () => {
      selectedBook.value = null
    }

    const updateBookStatus = async (newStatus) => {
      if (selectedBook.value) {
        const { data: { user } } = await supabase.auth.getUser()
        const { error } = await supabase
          .from('bookshelf')
          .update({ status: newStatus })
          .eq('user_id', user.id)
          .eq('story_id', selectedBook.value.id)

        if (error) {
          console.error('Error updating book status:', error)
        } else {
          const book = books.value.find(b => b.id === selectedBook.value.id)
          if (book) {
            book.status = newStatus
          }
        }
      }
      closeBookMenu()
    }

    const removeBook = async () => {
      if (selectedBook.value) {
        const { data: { user } } = await supabase.auth.getUser()
        const { error } = await supabase
          .from('bookshelf')
          .delete()
          .eq('user_id', user.id)
          .eq('story_id', selectedBook.value.id)

        if (error) {
          console.error('Error removing book:', error)
        } else {
          books.value = books.value.filter(b => b.id !== selectedBook.value.id)
        }
      }
      closeBookMenu()
    }

    const searchBooks = async () => {
      if (bookSearch.value.length > 2) {
        const { data, error } = await supabase
          .from('stories')
          .select(`
            id,
            title,
            users!author_id (username)
          `)
          .or(`title.ilike.%${bookSearch.value}%,users.username.ilike.%${bookSearch.value}%`)
          .limit(10)

        if (error) {
          console.error('Error searching books:', error)
        } else {
          searchResults.value = data.map(item => ({
            id: item.id,
            title: item.title,
            author: item.users.username
          }))
        }
      } else {
        searchResults.value = []
      }
    }

    const addBook = async () => {
  if (selectedBook.value) {
    const { data: { user } } = await supabase.auth.getUser()
    if (!user) {
      console.error('No authenticated user found');
      return;
    }
    const { error } = await supabase
      .from('bookshelf')
      .insert({
        user_id: user.id,
        story_id: selectedBook.value.id,
        status: newBookStatus.value
      })
      .select()

    if (error) {
      console.error('Error adding book to bookshelf:', error)
    } else {
      books.value.push({
        id: selectedBook.value.id,
        title: selectedBook.value.title,
        author: selectedBook.value.author,
        status: newBookStatus.value,
        cover_image: selectedBook.value.cover_image
      })
      closeAddBookModal()
    }
  }
}

    const closeAddBookModal = () => {
      showAddBookModal.value = false
      bookSearch.value = ''
      searchResults.value = []
      selectedBook.value = null
      newBookStatus.value = 'Want to Read'
    }

    const readBook = (book) => {
      router.push(`/read/${book.id}`)
    }

    return {
      shelves,
      currentShelf,
      searchQuery,
      books,
      showAddBookModal,
      selectedBook,
      newBookStatus,
      bookSearch,
      searchResults,
      filteredBooks,
      getStatusClass,
      openBookMenu,
      closeBookMenu,
      updateBookStatus,
      removeBook,
      searchBooks,
      addBook,
      closeAddBookModal,
      readBook
    }
  }
}
</script>