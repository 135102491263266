<template>
  <div class="messages-page bg-gray-100 min-h-screen p-6">
    <div class="max-w-6xl mx-auto bg-white rounded-lg shadow-md overflow-hidden">
      <div class="flex h-[calc(100vh-12rem)]">
        <!-- Conversations List -->
        <div class="w-1/3 border-r flex flex-col">
          <div class="p-4 border-b flex justify-between items-center">
            <h2 class="text-xl font-semibold">Messages</h2>
            <button @click="showNewMessageModal = true" class="bg-blue-500 text-white px-3 py-1 rounded-full hover:bg-blue-600 transition duration-300">
              New Message
            </button>
          </div>
          <div class="p-4 border-b">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Search messages..."
              class="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div class="overflow-y-auto flex-grow">
            <div
              v-for="conversation in filteredConversations"
              :key="conversation.id"
              @click="selectConversation(conversation)"
              class="p-4 border-b cursor-pointer hover:bg-gray-100 flex justify-between items-center transition duration-300"
              :class="{ 'bg-blue-50': selectedConversation?.id === conversation.id }"
            >
              <div class="flex items-center">
                <img :src="conversation.avatar" :alt="conversation.name" class="w-10 h-10 rounded-full mr-3">
                <div>
                  <h3 class="font-semibold">{{ conversation.name }}</h3>
                  <p class="text-sm text-gray-600 truncate">{{ conversation.lastMessage }}</p>
                </div>
              </div>
              <button @click.stop="deleteConversation(conversation.id)" class="text-red-500 hover:text-red-700">
                <TrashIcon class="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>

        <!-- Message View -->
        <div class="w-2/3 flex flex-col">
          <div v-if="selectedConversation" class="flex-grow overflow-y-auto p-4">
            <div v-for="message in selectedConversation.messages" :key="message.id" class="mb-4">
              <div :class="['max-w-[70%] rounded-lg p-3', message.sent ? 'bg-blue-500 text-white ml-auto' : 'bg-gray-200']">
                {{ message.content }}
              </div>
              <div :class="['text-xs mt-1', message.sent ? 'text-right' : '']">
                {{ message.timestamp }}
              </div>
            </div>
          </div>
          <div v-else class="flex-grow flex items-center justify-center text-gray-500">
            Select a conversation or start a new one
          </div>
          <div class="p-4 border-t">
            <form @submit.prevent="sendMessage" class="flex">
              <input
                v-model="newMessage"
                type="text"
                placeholder="Type a message..."
                class="flex-grow p-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
              <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded-r-md hover:bg-blue-600 transition duration-300">Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- New Message Modal -->
    <div v-if="showNewMessageModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div class="bg-white rounded-lg p-6 w-96">
        <h3 class="text-xl font-semibold mb-4">New Message</h3>
        <div class="relative">
    <input
      v-model="newMessageRecipient"
      @input="handleRecipientInput"
      type="text"
      placeholder="Recipient's name"
      class="w-full p-2 border rounded-md mb-4"
    >
    <div v-if="showAutocomplete" class="absolute z-10 w-full bg-white border rounded-md shadow-lg max-h-48 overflow-y-auto">
      <div
        v-for="user in filteredUsers"
        :key="user.id"
        @click="selectUser(user)"
        class="p-2 hover:bg-gray-100 cursor-pointer"
      >
        {{ user.username }}
      </div>
    </div>
  </div>
        <textarea
          v-model="newMessageContent"
          placeholder="Type your message..."
          class="w-full p-2 border rounded-md mb-4 h-32"
        ></textarea>
        <div class="flex justify-end">
    <button @click="showNewMessageModal = false" class="mr-2 px-4 py-2 text-gray-600 hover:text-gray-800">Cancel</button>
    <button @click="startNewConversation" class="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300">Send</button>
  </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { supabase } from '@/supabase';
import { useStore } from 'vuex';
import { TrashIcon } from 'lucide-vue-next';

export default {
  name: 'MessagesPage',
  components: {
    TrashIcon,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const currentUser = computed(() => store.getters['auth/currentUser']);

    const conversations = ref([]);
    const selectedConversation = ref(null);
    const newMessage = ref('');
    const showNewMessageModal = ref(false);
    const newMessageRecipient = ref('');
    const newMessageContent = ref('');
    const showAutocomplete = ref(false);
    const searchQuery = ref('');

    const fetchConversations = async () => {
      try {
        const { data, error } = await supabase
          .from('conversations')
          .select(`
            id,
            users!conversations_user2_id_fkey(id, username, avatar_url),
            last_message,
            updated_at
          `)
          .eq('user1_id', currentUser.value.id)
          .order('updated_at', { ascending: false });

        if (error) throw error;

        conversations.value = data.map(conv => ({
          id: conv.id,
          name: conv.users.username,
          avatar: conv.users.avatar_url || "/api/placeholder/100/100",
          lastMessage: conv.last_message,
        }));
      } catch (error) {
        console.error('Error fetching conversations:', error);
      }
    };

    const selectConversation = async (conversation) => {
      selectedConversation.value = conversation;
      try {
        const { data, error } = await supabase
          .from('messages')
          .select('*')
          .eq('conversation_id', conversation.id)
          .order('created_at', { ascending: true });

        if (error) throw error;

        selectedConversation.value.messages = data.map(msg => ({
          id: msg.id,
          content: msg.content,
          sent: msg.sender_id === currentUser.value.id,
          timestamp: new Date(msg.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        }));
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    const sendMessage = async () => {
      if (newMessage.value.trim() && selectedConversation.value) {
        try {
          const { data, error } = await supabase
            .from('messages')
            .insert({
              conversation_id: selectedConversation.value.id,
              sender_id: currentUser.value.id,
              content: newMessage.value,
            })
            .select()
            .single();

          if (error) throw error;

          selectedConversation.value.messages.push({
            id: data.id,
            content: data.content,
            sent: true,
            timestamp: new Date(data.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
          });
          selectedConversation.value.lastMessage = newMessage.value;
          newMessage.value = '';

          // Update last_message in conversations table
          await supabase
            .from('conversations')
            .update({ last_message: data.content, updated_at: new Date() })
            .eq('id', selectedConversation.value.id);

        } catch (error) {
          console.error('Error sending message:', error);
        }
      }
    };

    const deleteConversation = async (id) => {
      try {
        // Delete messages first
        const { error: messagesError } = await supabase
          .from('messages')
          .delete()
          .eq('conversation_id', id);

        if (messagesError) throw messagesError;

        // Then delete the conversation
        const { error: conversationError } = await supabase
          .from('conversations')
          .delete()
          .eq('id', id);

        if (conversationError) throw conversationError;

        conversations.value = conversations.value.filter(c => c.id !== id);
        if (selectedConversation.value && selectedConversation.value.id === id) {
          selectedConversation.value = null;
        }
      } catch (error) {
        console.error('Error deleting conversation:', error);
      }
    };

    const handleRecipientInput = async () => {
      if (newMessageRecipient.value.length > 0) {
        try {
          const { data, error } = await supabase
            .from('users')
            .select('id, username')
            .ilike('username', `%${newMessageRecipient.value}%`)
            .limit(5);

          if (error) throw error;

          showAutocomplete.value = data.length > 0;
          filteredUsers.value = data;
        } catch (error) {
          console.error('Error searching users:', error);
        }
      } else {
        showAutocomplete.value = false;
      }
    };

    const selectUser = (user) => {
      newMessageRecipient.value = user.username;
      showAutocomplete.value = false;
    };

    const startNewConversation = async () => {
  console.log('Starting new conversation');
  if (newMessageRecipient.value.trim() && newMessageContent.value.trim()) {
    try {
      console.log('Recipient:', newMessageRecipient.value);
      console.log('Content:', newMessageContent.value);
      
      // Find the recipient user
      const { data: recipientUser, error: userError } = await supabase
        .from('users')
        .select('id')
        .eq('username', newMessageRecipient.value)
        .single();

      if (userError) throw userError;
      console.log('Recipient user:', recipientUser);

      // Create a new conversation
      const { data: newConv, error: convError } = await supabase
        .from('conversations')
        .insert({
          user1_id: currentUser.value.id,
          user2_id: recipientUser.id,
          last_message: newMessageContent.value,
        })
        .select()
        .single();

      if (convError) throw convError;
      console.log('New conversation:', newConv);

      // Send the first message
      const { error: msgError } = await supabase
        .from('messages')
        .insert({
          conversation_id: newConv.id,
          sender_id: currentUser.value.id,
          content: newMessageContent.value,
        });

      if (msgError) throw msgError;
      console.log('First message sent');

      // Add the new conversation to the list and select it
      const newConversation = {
        id: newConv.id,
        name: newMessageRecipient.value,
        avatar: "/api/placeholder/100/100",
        lastMessage: newMessageContent.value,
        messages: [{
          id: Date.now(),
          content: newMessageContent.value,
          sent: true,
          timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        }],
      };
      conversations.value.unshift(newConversation);
      selectConversation(newConversation);
      showNewMessageModal.value = false;
      newMessageRecipient.value = '';
      newMessageContent.value = '';
    } catch (error) {
      console.error('Error starting new conversation:', error);
    }
  } else {
    console.log('Recipient or content is empty');
  }
};


    const filteredConversations = computed(() => {
      return conversations.value.filter(conv => 
        conv.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        conv.lastMessage.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const filteredUsers = ref([]);

    onMounted(async () => {
      await fetchConversations();
      if (route.query.new === 'true' && route.params.userId) {
        showNewMessageModal.value = true;
        // Fetch user details and set as recipient
        const { data } = await supabase
          .from('users')
          .select('username')
          .eq('id', route.params.userId)
          .single();
        if (data) {
          newMessageRecipient.value = data.username;
        }
      }
    });

    return {
      conversations,
      selectedConversation,
      newMessage,
      showNewMessageModal,
      newMessageRecipient,
      newMessageContent,
      showAutocomplete,
      filteredUsers,
      searchQuery,
      filteredConversations,
      selectConversation,
      sendMessage,
      deleteConversation,
      handleRecipientInput,
      selectUser,
      startNewConversation,
    };
  }
};
</script>