// src/supabase.js
import { createClient } from '@supabase/supabase-js'

const supabaseUrl = 'https://jmadplkomccuhtikyamf.supabase.co'
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImptYWRwbGtvbWNjdWh0aWt5YW1mIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjE0Mzk4NDcsImV4cCI6MjAzNzAxNTg0N30.9bb0lbvaG-4W-kNOeL3gWynTz_s3VwJgETRXFLU102I'

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  persistSession: true,
  autoRefreshToken: true,
})

// Force a refresh of the schema cache
export const refreshSupabaseSchema = async () => {
  try {
    await supabase.rpc('accept_recommendation_list_invite', { p_invite_id: '00000000-0000-0000-0000-000000000000' })
  } catch (error) {
    console.log('Schema refreshed')
  }
}

// Call this function immediately to refresh the schema when this file is imported
refreshSupabaseSchema()

const token = localStorage.getItem('supabase.auth.token')
if (token) {
  supabase.auth.setSession(token)
}