<template>
  <div class="challenges-page bg-gray-100 min-h-screen">
    <div class="max-w-6xl mx-auto px-4 py-12">
      <h1 class="text-4xl font-bold mb-8 text-center">Writing Challenges</h1>

      <!-- Current Challenge -->
      <div v-if="currentChallenge" class="bg-white rounded-lg shadow-md p-8 mb-12">
        <div class="flex items-start justify-between">
          <div>
            <h2 class="text-3xl font-bold mb-4">{{ currentChallenge.title }}</h2>
            <p class="text-lg text-gray-600 mb-8">{{ currentChallenge.description }}</p>
          </div>
          <div class="flex flex-col items-end">
            <div class="bg-blue-100 text-blue-600 px-4 py-2 rounded-full text-sm font-medium mb-4">
              Submission Deadline: {{ currentChallenge.deadline }}
            </div>
          </div>
        </div>

        <div class="grid md:grid-cols-2 gap-8 mt-8">
          <!-- Rules Section -->
          <div class="bg-gray-50 p-6 rounded-lg">
            <h3 class="text-2xl font-semibold mb-4">Rules</h3>
            <ul class="list-disc pl-5 space-y-2">
              <li>Write 3,000-10,000 words based on one of the provided prompts</li>
              <li>Submit original, unpublished work only</li>
              <li>One entry per participant</li>
            </ul>
          </div>
          <!-- Rewards Section -->
          <div class="bg-gray-50 p-6 rounded-lg">
            <h3 class="text-2xl font-semibold mb-4">Rewards</h3>
            <ul class="list-disc pl-5 space-y-2">
              <li>Unique "Challenge Winner" profile badge</li>
              <li>Your choice of story featured on the homepage for a month</li>
              <li>Group "takeover" for a day</li>
            </ul>
          </div>
        </div>

        <div class="mt-8">
          <h3 class="text-2xl font-semibold mb-4">Choose your prompt:</h3>
          <div class="grid md:grid-cols-3 gap-4">
            <div
              v-for="(prompt, index) in currentChallenge.prompts"
              :key="prompt.id"
              class="border p-4 rounded-lg hover:bg-blue-50 cursor-pointer transition-colors duration-200"
              :class="{ 'border-blue-500 bg-blue-50': selectedPromptIndex === index }"
              @click="selectPrompt(index)"
            >
              <p class="font-semibold mb-2">{{ prompt.genre }}</p>
              <p>{{ prompt.description }}</p>
              <router-link
                v-if="selectedPromptIndex === index"
                :to="{ name: 'CreateStory', query: { challenge: currentChallenge.id, prompt: prompt.id } }"
                class="mt-4 inline-block bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300"
              >
                Write
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- Entries List -->
      <div class="bg-white rounded-lg shadow-md p-8 mb-12">
        <h2 class="text-3xl font-bold mb-6">Current Entries</h2>
        <div v-if="entries.length === 0" class="text-center text-gray-600 text-lg">
          No entries yet. Be the first to submit!
      </div>
        <div v-else class="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div
            v-for="entry in entries"
            :key="entry.id"
            class="bg-gray-50 p-6 rounded-lg hover:shadow-md transition-shadow duration-200"
          >
            <h3 class="text-xl font-semibold mb-2">{{ entry.title }}</h3>
            <p class="text-sm text-gray-600 mb-3">By: {{ entry.author }}</p>
            <p class="mb-4 line-clamp-3">{{ entry.content }}</p>
            <div class="flex justify-between items-center">
              <button
                @click="voteForEntry(entry.id)"
                :disabled="hasVoted"
                class="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition-colors duration-200 disabled:bg-gray-400 disabled:cursor-not-allowed"
              >
                Vote ({{ entry.votes }})
              </button>
              <span v-if="entry.winner" class="text-green-600 font-semibold">Winner! 🏆</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { supabase } from '@/supabase';

export default {
  name: 'ChallengesPage',
  setup() {
    const store = useStore();
    const currentUser = computed(() => store.getters['auth/currentUser']);
    const currentChallenge = ref(null);
    const selectedPromptIndex = ref(null);
    const hasVoted = ref(false);
    const entries = ref([]);

    const fetchCurrentChallenge = async () => {
      const { data, error } = await supabase
        .from('challenges')
        .select(`
          *,
          prompts:challenge_prompts(*)
        `)
        .eq('status', 'active')
        .order('created_at', { ascending: false })
        .limit(1)
        .single();

      if (error) {
        console.error('Error fetching current challenge:', error);
      } else if (data) {
        currentChallenge.value = {
          ...data,
          deadline: new Date(data.end_date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })
        };
        fetchEntries(data.id);
      }
    };

    const fetchEntries = async (challengeId) => {
      const { data, error } = await supabase
        .from('challenge_entries')
        .select(`
          *,
          stories:story_id(title),
          users:user_id(username)
        `)
        .eq('challenge_id', challengeId);

      if (error) {
        console.error('Error fetching challenge entries:', error);
      } else {
        entries.value = data.map(entry => ({
          id: entry.id,
          title: entry.stories.title,
          author: entry.users.username,
          content: entry.excerpt || 'No excerpt available',
          votes: entry.votes || 0,
          winner: entry.is_winner || false
        }));
      }
    };

    const selectPrompt = (index) => {
      selectedPromptIndex.value = index;
    };

    const voteForEntry = async (entryId) => {
      if (!hasVoted.value) {
        const { error } = await supabase
          .from('challenge_votes')
          .insert({
            entry_id: entryId,
            user_id: currentUser.value.id
          });

        if (error) {
          console.error('Error voting for entry:', error);
        } else {
          // Update local state
          const entry = entries.value.find(e => e.id === entryId);
          if (entry) {
            entry.votes++;
            hasVoted.value = true;
          }

          // Update vote count in challenge_entries table
          await supabase
            .from('challenge_entries')
            .update({ votes: entry.votes })
            .eq('id', entryId);
        }
      }
    };

    const checkUserVote = async (challengeId) => {
      const { data, error } = await supabase
        .from('challenge_votes')
        .select('id')
        .eq('challenge_id', challengeId)
        .eq('user_id', currentUser.value.id)
        .limit(1);

      if (error) {
        console.error('Error checking user vote:', error);
      } else {
        hasVoted.value = data.length > 0;
      }
    };

    onMounted(() => {
      fetchCurrentChallenge();
    });

    return {
      currentChallenge,
      entries,
      selectedPromptIndex,
      hasVoted,
      selectPrompt,
      voteForEntry,
      checkUserVote
    };
  }
};
</script>

<style scoped>
.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>