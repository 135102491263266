<template>
  <div class="unlogged-homepage min-h-screen bg-gradient-to-br from-purple-600 via-indigo-700 to-blue-800 text-white">
    <div class="container mx-auto px-4 py-8">
      <header class="flex justify-between items-center mb-16">
        <h1 class="text-3xl font-bold">NarraNook</h1>
        <div class="space-x-4">
          <button @click="openAuthModal('login')" class="text-white hover:text-gray-200 transition duration-300">Log In</button>
          <button @click="openAuthModal('signup')" class="bg-white text-indigo-600 px-6 py-2 rounded-full hover:bg-gray-100 transition duration-300 font-semibold">Sign Up</button>
        </div>
      </header>

      <main class="text-center mb-16">
        <h2 class="text-5xl sm:text-6xl font-bold mb-6 animate-fade-in-up">Where Words Become Worlds</h2>
        <p class="text-xl sm:text-2xl mb-12 animate-fade-in-up animation-delay-300 max-w-3xl mx-auto">Join NarraNook - Your Portal to Endless Stories</p>
        
        <div class="grid md:grid-cols-3 gap-8 mb-16">
          <div v-for="(feature, index) in features" :key="index" class="bg-white bg-opacity-10 p-6 rounded-lg transform hover:scale-105 transition duration-300 animate-fade-in-up shadow-xl" :class="`animation-delay-${(index + 2) * 300}`">
            <component :is="feature.icon" class="w-16 h-16 mx-auto mb-4" :class="feature.iconColor" />
            <h3 class="text-2xl font-semibold mb-4">{{ feature.title }}</h3>
            <p>{{ feature.description }}</p>
          </div>
        </div>

        <button @click="openAuthModal('signup')" class="bg-white text-indigo-600 px-8 py-4 rounded-full text-xl font-semibold hover:bg-gray-100 transition duration-300 animate-pulse shadow-lg hover:shadow-xl">
          Start Your Journey Today
        </button>
      </main>
    </div>

    <!-- Auth Modal -->
    <teleport to="body">
      <div v-if="isAuthModalOpen" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" @click="closeAuthModal">
        <div class="bg-white rounded-lg p-6 max-w-md w-full relative" @click.stop>
          <button @click="closeAuthModal" class="absolute top-2 right-2 text-red-600 hover:text-red-800">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <h2 class="text-2xl font-bold mb-4 text-gray-800">
            {{ authMode === 'login' ? 'Log In' : 'Sign Up' }}
          </h2>
          <AuthForm 
            :mode="authMode" 
            @success="handleAuthSuccess" 
            @switch-mode="switchAuthMode"
            @google-auth="handleGoogleAuth"
            @phone-auth="handlePhoneAuth"
          />
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { BookOpen, Pen, Users } from 'lucide-vue-next';
import AuthForm from './AuthForm.vue';

export default {
  name: 'UnloggedHomepage',
  components: {
    AuthForm,
    BookOpen,
    Pen,
    Users,
  },
  setup() {
    const router = useRouter();
    const isAuthModalOpen = ref(false);
    const authMode = ref('login');

    const features = [
      { 
        title: 'Read', 
        description: 'Dive into a world of captivating stories across all genres.', 
        icon: BookOpen, 
        iconColor: 'text-yellow-400' 
      },
      { 
        title: 'Write', 
        description: 'Craft your masterpiece with our intuitive writing tools.', 
        icon: Pen, 
        iconColor: 'text-green-400' 
      },
      { 
        title: 'Connect', 
        description: 'Join a vibrant community of readers and writers.', 
        icon: Users, 
        iconColor: 'text-pink-400' 
      },
    ];

    const openAuthModal = (mode) => {
      authMode.value = mode;
      isAuthModalOpen.value = true;
    };

    const closeAuthModal = () => {
      isAuthModalOpen.value = false;
    };

    const switchAuthMode = (mode) => {
      authMode.value = mode;
    };

    const handleAuthSuccess = () => {
      closeAuthModal();
      router.push('/dashboard');
    };

    const handleGoogleAuth = () => {
      console.log('Google authentication');
    };

    const handlePhoneAuth = () => {
      console.log('Phone authentication');
    };

    return {
      isAuthModalOpen,
      authMode,
      features,
      openAuthModal,
      closeAuthModal,
      switchAuthMode,
      handleAuthSuccess,
      handleGoogleAuth,
      handlePhoneAuth,
    };
  },
};
</script>

<style scoped>
.animate-fade-in-up {
  animation: fadeInUp 0.6s ease-out;
}

.animation-delay-300 {
  animation-delay: 0.3s;
}

.animation-delay-600 {
  animation-delay: 0.6s;
}

.animation-delay-900 {
  animation-delay: 0.9s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>