// src/store/modules/auth.js
import { supabase } from '@/supabase'
import { eventBus } from '@/eventBus'

async function fetchUserData() {
  const { data: { user }, error } = await supabase.auth.getUser();
  if (error) throw error;
  console.log('Fetched user data:', user);
  return user;
}

export default {
  namespaced: true,
  state: () => ({
    user: null,
  }),
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
    },
  },
  actions: {
    async setUser({ commit }, user) {
      try {
        const userData = await fetchUserData(user.id);
        console.log('User metadata:', userData.user_metadata);
        const mergedUser = { 
          ...userData,
          is_admin: userData.user_metadata?.is_admin === true
        };
        console.log('Merged user with admin status:', mergedUser);
        commit('setUser', mergedUser);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },

    async logout({ commit }) {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      commit('clearUser');
      eventBus.emit('logged-out');
      return true;
    } catch (error) {
      console.error('Error during logout:', error);
      return false;
    }
  },

    async refreshUser({ commit }) {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          const { data, error } = await supabase
            .from('users')
            .select('*')
            .eq('id', user.id)
            .single();

          if (error) throw error;

          commit('setUser', data);
        }
      } catch (error) {
        console.error('Error refreshing user:', error);
      }
    },

    async initAuthListener({ dispatch }) {
    supabase.auth.onAuthStateChange((event, session) => {
      dispatch('handleAuthChange', { event, session });
      if (event === 'SIGNED_OUT') {
        eventBus.emit('logged-out');
      }
    });
  },

    async handleAuthChange({ commit, dispatch }, { event, session }) {
    if (event === 'SIGNED_IN' && session) {
      await dispatch('setUser', session.user);
    } else if (event === 'SIGNED_OUT') {
      commit('clearUser');
    }
  },

    async checkAuth({ dispatch }) {
    const { data: { session }, error } = await supabase.auth.getSession();
    if (error) {
      console.error('Error getting session:', error);
      return false;
    }
    await dispatch('initAuthListener');
    if (session) {
      await dispatch('setUser', session.user);
      return true;
    }
    return false;
  },
},

  getters: {
    isLoggedIn: (state) => !!state.user,
    isAdmin: (state) => {
      console.log('Checking admin status. User:', state.user);
      return state.user?.is_admin === true;
    },
    currentUser: (state) => state.user,
    getCurrentUserId: (state) => state.user ? state.user.id : null
  }
};
