<template>
  <div class="auth-form">
    <form @submit.prevent="handleSubmit" class="space-y-4">
      <div v-if="mode === 'signup'" class="grid grid-cols-2 gap-4">
        <div>
          <label for="firstName" class="block text-sm font-medium text-gray-700">First Name</label>
          <input
            type="text"
            id="firstName"
            v-model="firstName"
            required
            class="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          >
        </div>
        <div>
          <label for="lastName" class="block text-sm font-medium text-gray-700">Last Name</label>
          <input
            type="text"
            id="lastName"
            v-model="lastName"
            required
            class="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          >
        </div>
      </div>
      <div v-if="mode === 'signup'">
        <label for="username" class="block text-sm font-medium text-gray-700">Username</label>
        <input
          type="text"
          id="username"
          v-model="username"
          required
          class="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        >
      </div>
      <div>
        <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
        <input
          type="email"
          id="email"
          v-model="email"
          required
          class="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        >
      </div>
      <div>
        <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
        <input
          type="password"
          id="password"
          v-model="password"
          required
          class="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        >
      </div>
      <div v-if="mode === 'signup'">
        <label for="confirmPassword" class="block text-sm font-medium text-gray-700">Confirm Password</label>
        <input
          type="password"
          id="confirmPassword"
          v-model="confirmPassword"
          required
          class="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        >
      </div>
      <div v-if="error" class="text-red-600 text-sm">{{ error }}</div>
      <div v-if="successMessage" class="text-green-600 text-sm">{{ successMessage }}</div>
      <div>
        <button
          type="submit"
          :disabled="isLoading"
          class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <span v-if="!isLoading">{{ mode === 'login' ? 'Log In' : 'Sign Up' }}</span>
          <span v-else>Loading...</span>
        </button>
      </div>
    </form>
    <div class="mt-4">
      <p class="text-center text-sm text-gray-600 my-2">Or continue with</p>
      <div class="flex justify-center space-x-4">
        <button @click="handleGoogleAuth" class="flex items-center justify-center bg-white text-gray-700 px-4 py-2 rounded-full border border-gray-300 hover:bg-gray-100 transition duration-300">
          <img src="@/assets/google-logo.svg" alt="Google" class="w-5 h-5 mr-2">
          Google
        </button>
        <button @click="handlePhoneAuth" class="flex items-center justify-center bg-white text-gray-700 px-4 py-2 rounded-full border border-gray-300 hover:bg-gray-100 transition duration-300">
          <PhoneIcon class="w-5 h-5 mr-2" />
          Phone
        </button>
      </div>
    </div>
    <div class="mt-4 text-center text-sm">
      <p v-if="mode === 'login'">
        Don't have an account?
        <a @click="$emit('switch-mode', 'signup')" class="text-indigo-600 hover:text-indigo-500 cursor-pointer">Sign up</a>
      </p>
      <p v-else>
        Already have an account?
        <a @click="$emit('switch-mode', 'login')" class="text-indigo-600 hover:text-indigo-500 cursor-pointer">Log in</a>
      </p>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { supabase } from '@/supabase';
import { Phone as PhoneIcon } from 'lucide-vue-next';

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export default {
  name: 'AuthForm',
  components: {
    PhoneIcon,
  },
  props: {
    mode: {
      type: String,
      required: true,
      validator: (value) => ['login', 'signup'].includes(value)
    }
  },
  emits: ['success', 'switch-mode'],
  setup(props, { emit }) {
    const router = useRouter();
    const firstName = ref('');
    const lastName = ref('');
    const username = ref('');
    const email = ref('');
    const password = ref('');
    const confirmPassword = ref('');
    const error = ref('');
    const successMessage = ref('');
    const isLoading = ref(false);

    const handleSubmit = async () => {
      if (isLoading.value) return;
      
      error.value = '';
      successMessage.value = '';
      isLoading.value = true;

      console.log(`Starting ${props.mode} process`);

      const timeout = (ms) => new Promise((_, reject) => setTimeout(() => reject(new Error('Operation timed out')), ms));

      try {
        if (props.mode === 'signup') {
          if (password.value !== confirmPassword.value) {
            throw new Error('Passwords do not match.');
          }
          
          await delay(1000);
          console.log('Checking if username is taken');
          const checkUsername = await Promise.race([
            supabase.from('users').select('username').eq('username', username.value).single(),
            timeout(10000)
          ]);

          if (checkUsername.error && checkUsername.error.code !== 'PGRST116') {
            console.error('Error checking username:', checkUsername.error);
            throw checkUsername.error;
          }

          if (checkUsername.data) {
            throw new Error('Username is already taken. Please choose a different one.');
          }

          await delay(1000);
          console.log('Signing up user');
          const signUp = await Promise.race([
            supabase.auth.signUp({
              email: email.value,
              password: password.value,
              options: {
                data: {
                  first_name: firstName.value,
                  last_name: lastName.value,
                  username: username.value
                }
              }
            }),
            timeout(10000)
          ]);

          if (signUp.error) {
            console.error('Sign-up error:', signUp.error);
            throw signUp.error;
          }

          console.log('Sign-up response:', signUp.data);

          if (!signUp.data || !signUp.data.user) {
            console.log('No user object returned from sign-up');
            successMessage.value = 'Sign-up initiated. Please check your email to verify your account.';
          } else {
            console.log('User object returned from sign-up:', signUp.data.user);

            await delay(1000);
            console.log('Creating user profile');
            const insertProfile = await Promise.race([
              supabase.from('users').insert([
                { 
                  id: signUp.data.user.id, 
                  email: email.value,
                  username: username.value,
                  first_name: firstName.value,
                  last_name: lastName.value,
                  created_at: new Date(),
                  updated_at: new Date()
                }
              ]),
              timeout(10000)
            ]);

            if (insertProfile.error) {
              console.error('Error inserting user profile:', insertProfile.error);
              throw insertProfile.error;
            }

            console.log('User profile created successfully');
            successMessage.value = 'Sign up successful! Please check your email to confirm your account.';
          }
        } else {
          // Login logic
          await delay(1000);
          console.log('Attempting login');
          const { data, error: signInError } = await supabase.auth.signInWithPassword({
            email: email.value,
            password: password.value,
          });
          
          if (signInError) {
            console.error('Sign in error:', signInError);
            throw signInError;
          }

          console.log('Login response:', data);

          if (!data.user) {
            console.error('No user returned from sign in');
            throw new Error('No user returned from sign in');
          }

          console.log('User logged in successfully:', data.user);
          successMessage.value = 'Login successful!';

          // Redirect to the AuthCallback route
          router.push('/auth/callback');
        }
      } catch (err) {
        console.error('Auth error:', err);
        error.value = err.message || 'An error occurred. Please try again.';
      } finally {
        isLoading.value = false;
        console.log('Form submission completed');
      }
    };

    const handleGoogleAuth = async () => {
      try {
        const { data, error } = await supabase.auth.signInWithOAuth({
          provider: 'google',
        });
        
        if (error) throw error;

        if (data) {
          successMessage.value = 'Google sign-in initiated. Please wait...';
        }
      } catch (err) {
        console.error('Error with Google sign in:', err);
        error.value = 'Failed to sign in with Google. Please try again.';
      }
    };

    const handlePhoneAuth = () => {
      console.log('Phone authentication not implemented yet');
      emit('phone-auth');
    };

    return {
      firstName,
      lastName,
      username,
      email,
      password,
      confirmPassword,
      error,
      successMessage,
      isLoading,
      handleSubmit,
      handleGoogleAuth,
      handlePhoneAuth
    };
  }
};
</script>