<template>
  <div v-if="story" class="story-page bg-gray-100 min-h-screen p-8">
    <div class="max-w-4xl mx-auto bg-white rounded-lg shadow-md overflow-hidden">
      <div class="bg-gradient-to-r from-blue-500 to-purple-600 p-6 text-white">
        <h1 class="text-3xl font-bold">{{ story.title }}</h1>
        <router-link :to="{ name: 'VisitedUserProfile', params: { username: story.author?.username } }" class="hover:underline">
  {{ story.author?.username }}
</router-link>
      </div>

      <div class="p-6">
        <div class="flex mb-6">
          <img :src="story.cover_image" :alt="story.title" class="w-1/3 h-auto rounded-lg shadow-md mr-6" />
          <div>
            <p class="text-gray-600 mb-4">Last updated: {{ formatDate(story.updated_at) }}</p>
            <div class="flex flex-wrap gap-2 mb-4">
  <router-link 
    v-for="tag in story.tags" 
    :key="tag" 
    :to="{ name: 'ExplorePage', query: { tag: tag } }"
    class="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-sm hover:bg-blue-200"
  >
    {{ tag }}
  </router-link>
</div>
            <div class="grid grid-cols-2 gap-4 mb-4">
              <div class="text-center p-2 bg-gray-100 rounded">
                <p class="text-2xl font-bold text-blue-600">{{ story.reads || 0 }}</p>
                <p class="text-sm text-gray-600">Reads</p>
              </div>
              <div class="text-center p-2 bg-gray-100 rounded">
                <p class="text-2xl font-bold text-blue-600">{{ story.votes || 0 }}</p>
                <p class="text-sm text-gray-600">Votes</p>
              </div>
              <div class="text-center p-2 bg-gray-100 rounded">
                <p class="text-2xl font-bold text-blue-600">{{ story.chapters?.length || 0 }}</p>
                <p class="text-sm text-gray-600">Chapters</p>
              </div>
              <div class="text-center p-2 bg-gray-100 rounded">
                <p class="text-2xl font-bold text-blue-600">{{ story.comments || 0 }}</p>
                <p class="text-sm text-gray-600">Comments</p>
              </div>
            </div>
            <div class="flex space-x-4">
              <button @click="showRecommendModal = true" class="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition duration-300">
                <UserPlusIcon class="w-5 h-5 inline-block mr-2" />
                Recommend to Friend
              </button>
              <button @click="handleSaveToBookshelf" class="bg-green-600 text-white px-4 py-2 rounded-full hover:bg-green-700 transition duration-300">
                <BookOpen class="w-5 h-5 inline-block mr-2" />
                {{ isInBookshelf ? 'Saved' : 'Add to Bookshelf' }}
              </button>
            </div>
            <div class="mt-4 flex items-center">
              <input 
                type="checkbox" 
                id="hideActivity" 
                v-model="hideActivity" 
                class="mr-2"
                @change="toggleActivityVisibility"
              >
              <label for="hideActivity" class="text-sm text-gray-700">Hide activity for this story from public profile</label>
            </div>
          </div>
        </div>

        <h2 class="text-2xl font-bold mb-2">Story Blurb</h2>
        <p class="mb-6">{{ story.blurb }}</p>

        <h2 class="text-2xl font-bold mb-2">Chapters</h2>
        <ul v-if="story.chapters && story.chapters.length" class="space-y-2 mb-6">
          <li v-for="chapter in story.chapters" :key="chapter.id" class="flex justify-between items-center">
            <a @click="navigateToChapter(chapter.id)" class="text-blue-600 hover:underline cursor-pointer">
              Chapter {{ chapter.chapter_number }} - {{ chapter.title }}
            </a>
            <span class="text-gray-500 text-sm">{{ formatDate(chapter.created_at) }}</span>
          </li>
        </ul>
        <p v-else class="text-gray-600">No chapters available yet.</p>

        <h2 class="text-2xl font-bold mb-2">About the Author</h2>
        <div class="flex items-center space-x-4 mb-6">
          <img :src="story.author?.avatar" :alt="story.author?.username" class="w-16 h-16 rounded-full" />
          <div>
            <router-link :to="{ name: 'VisitedUserProfile', params: { username: story.author?.username } }" class="hover:underline">
  {{ story.author?.username }}
</router-link>
            <p class="text-gray-600">{{ story.author?.bio }}</p>
          </div>
        </div>

        <h2 class="text-2xl font-bold mb-2">Recommended Stories</h2>
        <div class="grid grid-cols-3 gap-4">
  <div v-for="recommendedStory in recommendedStories" :key="recommendedStory.id" class="bg-gray-100 rounded-lg p-4">
    <router-link :to="{ name: 'StoryPage', params: { id: recommendedStory.id } }">
      <img :src="recommendedStory.cover_image" :alt="recommendedStory.title" class="w-full h-32 object-cover rounded-md mb-2" />
      <h3 class="font-semibold">{{ recommendedStory.title }}</h3>
      <p class="text-sm text-gray-600">by {{ recommendedStory.author }}</p>
    </router-link>
  </div>
</div>
      </div>
    </div>
  </div>
  <div v-else class="text-center py-8">
    <p class="text-2xl text-gray-600">Loading story...</p>
  </div>

  <!-- Recommend to Friend Modal -->
  <div v-if="showRecommendModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div class="bg-white rounded-lg p-8 w-full max-w-md shadow-2xl">
      <h2 class="text-3xl font-bold mb-6 text-indigo-800">Recommend to a Friend</h2>
      <form @submit.prevent="submitRecommendation" class="space-y-6">
        <div>
          <label class="block text-sm font-medium text-gray-700 mb-2">Select Recommendation List</label>
          <select 
  v-model="selectedList" 
  class="w-full px-4 py-2 rounded-md border-2 border-indigo-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-gray-700"
>
  <option value="" disabled selected>Select a list</option>
  <option v-for="list in recommendationLists" :key="list.id" :value="list.id">
    {{ list.title }}
  </option>
</select>
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700 mb-2">Personal Message (optional)</label>
          <textarea 
            v-model="recommendationMessage" 
            rows="3" 
            class="w-full px-4 py-2 rounded-md border-2 border-indigo-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            placeholder="Add a personal note to your recommendation"
          ></textarea>
        </div>
        <div class="flex justify-end space-x-4">
          <button 
            @click="showRecommendModal = false" 
            type="button" 
            class="px-6 py-2 bg-gray-200 text-gray-800 rounded-full hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
          >
            Cancel
          </button>
          <button 
            type="submit" 
            class="px-6 py-2 bg-indigo-600 text-white rounded-full hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
          >
            Send Recommendation
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { UserPlusIcon, BookOpen } from 'lucide-vue-next';
import { supabase } from '@/supabase';

export default {
  name: 'StoryPage',
  components: {
    UserPlusIcon,
    BookOpen,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const story = ref(null);
    const isInBookshelf = ref(false);
    const recommendedStories = ref([]);
    const showRecommendModal = ref(false);
    const selectedList = ref(null);
    const recommendationMessage = ref('');
    const recommendationLists = ref([]);
    const hideActivity = ref(false);
    const readProgress = ref(0);
    let readTrackingInterval;

    const fetchStory = async () => {
  const { data, error } = await supabase
    .from('stories')
    .select(`
      *,
      author:users!author_id(username, avatar, bio),
      chapters(id, title, created_at, chapter_number),
      story_reads(count),
      story_votes(count),
      story_comments(count)
    `)
    .eq('id', route.params.id)
    .single();

  if (error) {
    console.error('Error fetching story:', error);
    return null;
  }

  console.log('Fetched story data:', data);

  return {
    ...data,
    reads: data.story_reads[0]?.count || 0,
    votes: data.story_votes[0]?.count || 0,
    comments: data.story_comments[0]?.count || 0
  };
};

    const truncatedBio = computed(() => {
  const bio = story.value?.author?.bio || '';
  return bio.split('.')[0] + '.';
});

    const fetchRecommendedStories = async (genre) => {
  if (!genre) {
    console.error('Genre is not available');
    return [];
  }

  const { data: recommendedStoriesData, error } = await supabase
    .from('stories')
    .select('id, title, author:users!author_id(username), cover_image')
    .eq('genre', genre)
    .neq('id', route.params.id)
    .limit(3);

  if (error) {
    console.error('Error fetching recommended stories:', error);
    return [];
  }

  return recommendedStoriesData.map(story => ({
    id: story.id,
    title: story.title,
    author: story.author.username,
    cover_image: story.cover_image
  }));
};

    const fetchRecommendationLists = async () => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) {
    console.error('No authenticated user found');
    return;
  }
  const { data, error } = await supabase
    .from('recommendation_lists')
    .select('id, title')
    .eq('user_id', user.id);

  if (error) {
    console.error('Error fetching recommendation lists:', error);
  } else {
    recommendationLists.value = data;
    console.log('Fetched recommendation lists:', data);
  }
};

    const updateReadProgress = async () => {
  const { data: user } = await supabase.auth.getUser();
  const { error } = await supabase  // Remove 'data' from destructuring
    .from('story_reads')
    .upsert({
      story_id: story.value.id,
      user_id: user.id,
      progress: readProgress.value,
      last_read_at: new Date().toISOString()
    }, { onConflict: 'story_id, user_id' });

  if (error) {
    console.error('Error updating read progress:', error);
  }
};

    const trackReadProgress = () => {
      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
      const progress = (window.scrollY / totalHeight) * 100;
      readProgress.value = Math.min(Math.max(progress, readProgress.value), 100);
    };

    const handleSaveToBookshelf = async () => {
  const { data: user } = await supabase.auth.getUser();
  const { error } = await supabase
    .from('bookshelf')
    .upsert({
      user_id: user.id,
      story_id: story.value.id
    }, { onConflict: 'user_id, story_id' });

  if (error) {
    console.error('Error saving to bookshelf:', error);
    // Show error message to user
  } else {
    isInBookshelf.value = true;
    // Show success message to user
  }
};

    const toggleActivityVisibility = async () => {
  const { data: { user } } = await supabase.auth.getUser();
  const { error } = await supabase
    .from('user_preferences')
    .upsert({
      user_id: user.id,
      story_id: story.value.id,
      hide_activity: hideActivity.value
    }, { onConflict: 'user_id, story_id' });

  if (error) {
    console.error('Error updating activity visibility:', error);
  } else {
    console.log('Activity visibility updated:', hideActivity.value);
  }
};

    onMounted(async () => {
  const storyId = route.params.id;
  
  // Fetch story first
  const storyData = await fetchStory(storyId);
  story.value = storyData;

  // Now fetch recommended stories using the genre from the loaded story
  if (story.value && story.value.genre) {
    recommendedStories.value = await fetchRecommendedStories(story.value.genre);
  }

  console.log('Story stats:', {
    reads: story.value.reads,
    votes: story.value.votes,
    comments: story.value.comments,
    chapters: story.value.chapters?.length
  });

  // Fetch recommendation lists after ensuring we have a logged-in user
  await fetchRecommendationLists();

  const { data: user } = await supabase.auth.getUser();
  if (user) {
    const [bookshelfEntry, userPreference] = await Promise.all([
      supabase.from('bookshelf').select().eq('user_id', user.id).eq('story_id', storyId).single(),
      supabase.from('user_preferences').select('hide_activity').eq('user_id', user.id).eq('story_id', storyId).single()
    ]);

    isInBookshelf.value = !!bookshelfEntry.data;
    hideActivity.value = userPreference.data?.hide_activity || false;
  }

  window.addEventListener('scroll', trackReadProgress);
  readTrackingInterval = setInterval(updateReadProgress, 30000);
});

    onUnmounted(() => {
      window.removeEventListener('scroll', trackReadProgress);
      clearInterval(readTrackingInterval);
    });

    const submitRecommendation = async () => {
  if (selectedList.value) {
    const { data: { user } } = await supabase.auth.getUser()
    const { error } = await supabase
      .from('recommendations')
      .insert({
        list_id: selectedList.value,
        story_id: story.value.id,
        message: recommendationMessage.value,
        user_id: user.id
      })

    if (error) {
      console.error('Error submitting recommendation:', error)
      // Show error message to user
    } else {
      // Show success message to user
      selectedList.value = null
      recommendationMessage.value = ''
      showRecommendModal.value = false
    }
  }
};

    const formatDate = (dateString) => {
      if (!dateString) return '';
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const navigateToChapter = (chapterId) => {
      if (story.value && story.value.id) {
        router.push(`/story/${story.value.id}/chapter/${chapterId}`);
      }
    };

    return {
      story,
      isInBookshelf,
      recommendedStories,
      showRecommendModal,
      selectedList,
      recommendationMessage,
      recommendationLists,
      hideActivity,
      submitRecommendation,
      handleSaveToBookshelf,
      navigateToChapter,
      toggleActivityVisibility,
      formatDate,
      truncatedBio
    };
  }
};
</script>