<template>
  <div class="groups-page bg-gray-100 min-h-screen py-8">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- Search Bar -->
      <div class="mb-8">
    <input
  v-model="searchQuery"
  type="text"
  placeholder="Search groups..."
  class="w-full px-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
  @input="handleSearch"
>
  </div>

      <div v-if="loading">Loading groups...</div>
      <div v-else-if="error">{{ error }}</div>
      <div v-else>
        <!-- Joined Groups Section -->
        <div class="mb-12">
          <h2 class="text-2xl font-bold mb-4">My Groups</h2>
          <div v-if="joinedGroups.length === 0" class="text-gray-600">
            You haven't joined any groups yet.
          </div>
          <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
  <div v-for="group in joinedGroups" :key="group.id" class="bg-white rounded-lg shadow-md p-6">
    <router-link 
      :to="{ name: 'GroupDetails', params: { id: group.id } }"
      class="text-xl font-semibold mb-2 hover:text-blue-600"
    >
      {{ group.name }}
    </router-link>
    <p class="text-gray-600 mb-4">{{ group.description }}</p>
    <div class="flex justify-between items-center">
      <span class="text-sm text-gray-500">{{ group.memberCount }} {{ group.memberCount === 1 ? 'member' : 'members' }}</span>
      <div class="space-x-2">
        <button @click="leaveGroup(group.id)" class="bg-red-600 text-white px-4 py-2 rounded-full hover:bg-red-700 transition duration-300">
          Leave
        </button>
        <router-link 
          :to="{ name: 'GroupDetails', params: { id: group.id } }" 
          class="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition duration-300"
        >
          View
        </router-link>
      </div>
    </div>
  </div>
</div>

<div class="py-6"></div>

        <!-- Available Groups Section -->
        <div>
          <h2 class="text-2xl font-bold mb-4">Available Groups</h2>
          
          <!-- Group Creation CTA -->
          <div class="bg-blue-100 border-l-4 border-blue-500 p-4 mb-8">
            <p class="font-semibold">Have an idea for a new group?</p>
            <p class="mb-2">Share your passion and connect with like-minded readers and writers!</p>
            <button @click="showGroupCreationModal = true" class="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition duration-300">
              Propose a New Group
            </button>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
  <div v-for="group in availableGroups" :key="group.id" class="bg-white rounded-lg shadow-md p-6">
    <router-link 
      :to="{ name: 'GroupDetails', params: { id: group.id } }"
      class="text-xl font-semibold mb-2 hover:text-blue-600"
    >
      {{ group.name }}
    </router-link>
    <p class="text-gray-600 mb-4">{{ group.description }}</p>
    <div class="flex justify-between items-center">
      <span class="text-sm text-gray-500">{{ group.memberCount }} {{ group.memberCount === 1 ? 'member' : 'members' }}</span>
      <div class="space-x-2">
        <button @click="joinGroup(group.id)" class="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition duration-300">
          Join
        </button>
        <router-link 
          :to="{ name: 'GroupDetails', params: { id: group.id } }" 
          class="bg-gray-200 text-gray-800 px-4 py-2 rounded-full hover:bg-gray-300 transition duration-300"
        >
          View
        </router-link>
      </div>
    </div>
  </div>
</div>
</div>
</div>
</div>
</div>

    <!-- Group Creation Modal -->
    <div v-if="showGroupCreationModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div class="bg-white rounded-lg shadow-xl w-full max-w-md">
        <div class="p-6">
          <h2 class="text-2xl font-bold mb-4 text-indigo-700">Propose a New Group</h2>
          <form @submit.prevent="submitGroupProposal">
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="groupName">
                Group Name
              </label>
              <input v-model="newGroup.name" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500" id="groupName" type="text" placeholder="Enter group name">
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="groupDescription">
                Description
              </label>
              <textarea v-model="newGroup.description" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500" id="groupDescription" rows="3" placeholder="Describe your group"></textarea>
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="targetUsers">
                Target Users
              </label>
              <input v-model="newGroup.targetUsers" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-indigo-500" id="targetUsers" type="text" placeholder="Who is this group for?">
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2">
                Are you willing to moderate this group?
              </label>
              <div class="mt-2">
                <label class="inline-flex items-center">
                  <input type="radio" class="form-radio" name="moderation" value="yes" v-model="newGroup.willModerate">
                  <span class="ml-2">Yes</span>
                </label>
                <label class="inline-flex items-center ml-6">
                  <input type="radio" class="form-radio" name="moderation" value="no" v-model="newGroup.willModerate">
                  <span class="ml-2">No</span>
                </label>
              </div>
            </div>
          </form>
        </div>
        <div class="bg-gray-50 px-6 py-4 flex justify-end">
          <button @click="showGroupCreationModal = false" class="mr-2 px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400">
            Cancel
          </button>
          <button @click="submitGroupProposal" class="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500">
            Submit Proposal
          </button>
        </div>
      </div>
    </div>

    <!-- Proposal Submitted Confirmation -->
    <div v-if="showProposalConfirmation" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div class="bg-white rounded-lg p-8 max-w-md w-full">
        <h2 class="text-2xl font-bold mb-4 text-green-600">Proposal Submitted!</h2>
        <p class="mb-6">Thank you for your group proposal. Our moderation team will review it shortly and get back to you.</p>
        <button @click="showProposalConfirmation = false" class="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { groupService } from '@/services/groupService';

export default {
  name: 'GroupsPage',
  setup() {
    const store = useStore();
    const searchQuery = ref('');
    const showGroupCreationModal = ref(false);
    const showProposalConfirmation = ref(false);
    const newGroup = ref({ 
      name: '', 
      description: '', 
      targetUsers: '',
      willModerate: false
    });

    const joinedGroups = ref([]);
    const availableGroups = ref([]);
    const loading = ref(true);
    const error = ref(null);

    const fetchGroups = async () => {
  try {
    loading.value = true;
    error.value = null;
    console.log('Fetching groups...');
    const allGroups = await groupService.getGroups(searchQuery.value);
    console.log('All groups:', allGroups);

    const userId = store.getters['auth/getCurrentUserId'];
    console.log('Current user ID:', userId);

    if (!Array.isArray(allGroups)) {
      console.error('Fetched groups is not an array:', allGroups);
      error.value = 'Failed to load groups: Invalid data received';
      return;
    }

    joinedGroups.value = allGroups.filter(group => 
      group.members && group.members.some(member => member.user_id === userId)
    );
    console.log('Joined groups:', joinedGroups.value);

    availableGroups.value = allGroups.filter(group => 
      !group.members || !group.members.some(member => member.user_id === userId)
    );
    console.log('Available groups:', availableGroups.value);

    // Log the member counts for each group
    allGroups.forEach(group => {
      console.log(`Group ${group.id} - Member count: ${group.memberCount}`);
    });

  } catch (err) {
    console.error('Error in fetchGroups:', err);
    error.value = 'Failed to load groups: ' + (err.message || 'Unknown error');
  } finally {
    loading.value = false;
  }
};

    onMounted(() => {
      console.log('GroupsPage mounted, calling fetchGroups');
      fetchGroups();
    });

    const joinGroup = async (groupId) => {
  try {
    const userId = store.getters['auth/getCurrentUserId'];
    console.log(`Attempting to join group ${groupId} for user ${userId}`);
    await groupService.addGroupMember(groupId, userId);
    console.log(`Successfully joined group ${groupId}. Refetching groups...`);
    
    // Refetch groups to ensure local state matches backend
    await fetchGroups();
    console.log('Groups refetched after joining.');
  } catch (error) {
    console.error('Error joining group:', error);
    // Handle error (e.g., show error message to user)
  }
};

    const submitGroupProposal = async () => {
      try {
        const userId = store.getters['auth/getCurrentUserId'];
        await groupService.submitGroupProposal(
          newGroup.value.name, 
          newGroup.value.description, 
          userId, 
          newGroup.value.targetUsers, 
          newGroup.value.willModerate
        );
        
        // Reset form and show confirmation
        newGroup.value = { name: '', description: '', targetUsers: '', willModerate: false };
        showGroupCreationModal.value = false;
        showProposalConfirmation.value = true;
        
        // Simulate sending a notification to the admin
        store.dispatch('sendAdminNotification', {
          type: 'groupProposal',
          message: `New group proposal: ${newGroup.value.name}`,
          data: newGroup.value
        });
      } catch (error) {
        console.error('Error submitting group proposal:', error);
        // Handle error (e.g., show error message to user)
      }
    };

let debounceTimer = null;

const handleSearch = () => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        fetchGroups();
      }, 300); // Wait for 300ms after the last keystroke before fetching
    };

    onUnmounted(() => {
      clearTimeout(debounceTimer);
    });

    const leaveGroup = async (groupId) => {
  try {
    const userId = store.getters['auth/getCurrentUserId'];
    console.log(`Attempting to leave group ${groupId} for user ${userId}`);
    await groupService.removeGroupMember(groupId, userId);
    console.log(`Successfully left group ${groupId}. Updating local state...`);
    
    // Update local state immediately
    joinedGroups.value = joinedGroups.value.filter(group => group.id !== groupId);
    const leavedGroup = availableGroups.value.find(group => group.id === groupId);
    if (leavedGroup) {
      leavedGroup.memberCount -= 1;
      availableGroups.value.push(leavedGroup);
    }
    
    // Refetch groups to ensure local state matches backend
    await fetchGroups();
    console.log('Groups refetched after leaving.');
  } catch (error) {
    console.error('Error leaving group:', error);
    // Handle error (e.g., show error message to user)
  }
};

    return {
      searchQuery,
      showGroupCreationModal,
      showProposalConfirmation,
      newGroup,
      joinedGroups,
      availableGroups,
      joinGroup,
      submitGroupProposal,
      loading,
      leaveGroup,
      handleSearch,
      error
    };
  }
};
</script>