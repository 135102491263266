<!-- src/components/UserAuth.vue -->
<template>
  <div class="user-auth bg-gray-100 min-h-screen flex items-center justify-center">
    <div class="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
      <h2 class="text-2xl font-bold mb-6 text-center">
        {{ isRegistering ? 'Create an Account' : 'Log In' }}
      </h2>
      <AuthForm 
        :mode="isRegistering ? 'signup' : 'login'" 
        @success="handleAuthSuccess"
        @switch-mode="switchMode"
      />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import AuthForm from './AuthForm.vue';

export default {
  name: 'UserAuth',
  components: {
    AuthForm
  },
  setup() {
    const router = useRouter();
    const isRegistering = ref(false);

    const switchMode = (mode) => {
      isRegistering.value = mode === 'signup';
    };

    const handleAuthSuccess = () => {
      router.push('/dashboard');
    };

    return {
      isRegistering,
      switchMode,
      handleAuthSuccess
    };
  }
};
</script>