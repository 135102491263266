<template>
  <div id="app" @click="closeUserMenu">
    <nav v-if="isLoggedIn" class="bg-white shadow-md">
      <div class="container mx-auto px-4 py-4 flex justify-between items-center">
        <router-link to="/" class="text-2xl font-bold text-indigo-600">NarraNook</router-link>
        
        <!-- Mobile menu button with notification indicator -->
        <button @click="toggleMobileMenu" class="md:hidden relative">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
          <span v-if="unreadNotifications || unreadMessages" class="absolute top-0 right-0 block h-2 w-2 rounded-full bg-red-400"></span>
        </button>

        <!-- Desktop menu -->
        <div class="hidden md:flex items-center space-x-4">
          <router-link to="/explore" class="text-gray-700 hover:text-indigo-600">Explore</router-link>
          <router-link to="/my-works" class="text-gray-700 hover:text-indigo-600">My Works</router-link>
          <router-link to="/groups" class="text-gray-700 hover:text-indigo-600">Groups</router-link>
          <router-link to="/challenges" class="text-gray-700 hover:text-indigo-600">Challenges</router-link>
          <router-link to="/bookshelf" class="text-gray-700 hover:text-indigo-600">Bookshelf</router-link>
          <button @click.stop="toggleNotifications" class="relative bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">View notifications</span>
            <BellIcon class="h-6 w-6" />
            <span v-if="unreadNotifications" class="absolute top-0 right-0 block h-2 w-2 rounded-full bg-red-400 ring-2 ring-white"></span>
          </button>
          <router-link to="/messages" class="relative bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">Messages</span>
            <MessageSquareIcon class="h-6 w-6" />
            <span v-if="unreadMessages" class="absolute top-0 right-0 block h-2 w-2 rounded-full bg-red-400 ring-2 ring-white"></span>
          </router-link>
          <div class="ml-3 relative">
            <div>
              <button @click.stop="toggleUserMenu" class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" id="user-menu" aria-haspopup="true">
                <span class="sr-only">Open user menu</span>
                <img class="h-8 w-8 rounded-full" :src="userAvatar" alt="">
              </button>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <div v-if="showUserMenu" class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                <router-link :to="{ name: 'CurrentUserProfile' }" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
  Your Profile
</router-link>
                <router-link to="/settings" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Settings</router-link>
                <router-link v-if="isAdmin" to="/admin" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Admin Dashboard</router-link>
                <a @click="logout" href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Sign out</a>
              </div>
            </transition>
          </div>
        </div>
      </div>

      <!-- Mobile menu -->
      <div v-if="showMobileMenu" class="md:hidden">
        <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <router-link @click="closeMobileMenu" to="/explore" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50">Explore</router-link>
          <router-link @click="closeMobileMenu" to="/my-works" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50">My Works</router-link>
          <router-link @click="closeMobileMenu" to="/groups" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50">Groups</router-link>
          <router-link @click="closeMobileMenu" to="/challenges" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50">Challenges</router-link>
          <router-link @click="closeMobileMenu" to="/bookshelf" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50">Bookshelf</router-link>
          <router-link @click="closeMobileMenu" to="/messages" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50">
            Messages
            <span v-if="unreadMessages" class="ml-2 inline-block h-2 w-2 rounded-full bg-red-400"></span>
          </router-link>
          <button @click.stop="toggleNotifications" class="w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50">
            Notifications
            <span v-if="unreadNotifications" class="ml-2 inline-block h-2 w-2 rounded-full bg-red-400"></span>
          </button>
          <router-link @click="closeMobileMenu" to="/profile" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50">Your Profile</router-link>
          <router-link @click="closeMobileMenu" to="/settings" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50">Settings</router-link>
          <router-link v-if="isAdmin" @click="closeMobileMenu" to="/admin" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50">Admin Dashboard</router-link>
          <button @click="logoutAndCloseMobileMenu" class="w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50">Sign out</button>
        </div>
      </div>
    </nav>

    <router-view></router-view>

    <!-- Notification Panel -->
<div v-if="showNotifications" class="notification-panel fixed top-16 right-4 mt-2 w-96 bg-white rounded-lg shadow-lg z-50 max-h-[80vh] overflow-y-auto">
  <div class="p-4">
    <h3 class="text-lg font-semibold mb-2">Notifications ({{ notifications.length }})</h3>
    <p v-if="notificationError" class="text-red-500 mb-2">{{ notificationError }}</p>
    <pre v-if="notifications.length === 0" class="text-sm text-gray-500">No notifications</pre>
    <ul v-else class="space-y-2">
      <li 
        v-for="notification in notifications" 
        :key="notification.id" 
        class="flex items-start space-x-2 p-2 hover:bg-gray-100 rounded cursor-pointer"
      >
        <span class="mt-1">
          <MessageSquareIcon v-if="notification.type === NOTIFICATION_TYPES.COMMENT" class="w-5 h-5 text-blue-500" />
          <HeartIcon v-if="notification.type === NOTIFICATION_TYPES.APPLAUSE" class="w-5 h-5 text-red-500" />
          <UserPlusIcon v-if="notification.type === NOTIFICATION_TYPES.FOLLOW" class="w-5 h-5 text-indigo-500" />
          <AtSignIcon v-if="notification.type === NOTIFICATION_TYPES.MENTION" class="w-5 h-5 text-yellow-500" />
          <UsersIcon v-if="notification.type === NOTIFICATION_TYPES.ANNOUNCEMENT" class="w-5 h-5 text-purple-500" />
          <MessageCircleIcon v-if="notification.type === NOTIFICATION_TYPES.GROUP_COMMENT" class="w-5 h-5 text-orange-500" />
          <UserIcon v-if="notification.type === NOTIFICATION_TYPES.RL_INVITE" class="w-5 h-5 text-green-500" />
          <CheckIcon v-if="notification.type === NOTIFICATION_TYPES.RL_ACCEPT" class="w-5 h-5 text-green-500" />
          <BookIcon v-if="notification.type === NOTIFICATION_TYPES.RECOMMENDATION" class="w-5 h-5 text-blue-500" />
          <PenToolIcon v-if="notification.type === NOTIFICATION_TYPES.NEW_CHAPTER" class="w-5 h-5 text-cyan-500" />
          <TrophyIcon v-if="notification.type === NOTIFICATION_TYPES.CHALLENGE_WINNER" class="w-5 h-5 text-yellow-500" />
          <FlagIcon v-if="notification.type === NOTIFICATION_TYPES.CHALLENGE_INVITE" class="w-5 h-5 text-pink-500" />
          <BellIcon v-if="notification.type === NOTIFICATION_TYPES.ADMIN_BROADCAST" class="w-5 h-5 text-red-500" />
        </span>
        <div class="flex-grow">
          <p class="text-sm" :class="{ 'font-semibold': !notification.read }">
            {{ notification.message }}
          </p>
          <p class="text-xs text-gray-500">{{ formatTime(notification.created_at) }}</p>
        </div>
        <div v-if="notification.type === NOTIFICATION_TYPES.RL_INVITE" class="flex space-x-2">
          <button @click.stop="acceptInvite(notification)" class="text-green-500 hover:text-green-700">
            <CheckIcon class="w-5 h-5" />
          </button>
          <button @click.stop="rejectInvite(notification)" class="text-red-500 hover:text-red-700">
            <XIcon class="w-5 h-5" />
          </button>
        </div>
        <div v-else-if="notification.link" @click="handleNotificationClick(notification)" class="w-full h-full absolute top-0 left-0"></div>
      </li>
    </ul>
  </div>
</div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { formatDistanceToNow } from 'date-fns';
import { supabase } from '@/supabase';
import { onBeforeMount } from 'vue'
import { refreshSupabaseSchema } from './supabase'
import { eventBus } from '@/eventBus'
import { 
  BellIcon, 
  MessageSquareIcon, 
  HeartIcon, 
  UserPlusIcon, 
  AtSignIcon, 
  UsersIcon, 
  MessageCircleIcon,
  UserIcon,
  CheckIcon,
  BookIcon,
  PenToolIcon,
  TrophyIcon,
  FlagIcon,
  XIcon
} from 'lucide-vue-next';

// Define notification types
const NOTIFICATION_TYPES = {
  COMMENT: 'comment',
  APPLAUSE: 'applause',
  FOLLOW: 'follow',
  MENTION: 'mention',
  ANNOUNCEMENT: 'announcement',
  GROUP_COMMENT: 'group_comment',
  RL_INVITE: 'rl_invite',
  RL_ACCEPT: 'rl_accept',
  RECOMMENDATION: 'recommendation',
  NEW_CHAPTER: 'new_chapter',
  CHALLENGE_INVITE: 'challenge_invite',
  CHALLENGE_WINNER: 'challenge_winner',
  ADMIN_BROADCAST: 'admin_broadcast'
};

export default {
  name: 'App',
  components: {
    BellIcon,
    MessageSquareIcon,
    HeartIcon,
    UserPlusIcon,
    AtSignIcon,
    UsersIcon,
    MessageCircleIcon,
    UserIcon,
    CheckIcon,
    BookIcon,
    PenToolIcon,
    TrophyIcon,
    FlagIcon,
    XIcon
  },
  setup() {
    onBeforeMount(async () => {
      await refreshSupabaseSchema()
    })
    const store = useStore();
    const router = useRouter();
    const notificationError = ref(null);

    eventBus.on('logged-out', () => {
      router.push('/auth')
    })

    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);
    const isAdmin = computed(() => {
  const adminStatus = store.getters['auth/isAdmin'];
  console.log('Is admin in App.vue:', adminStatus);
  console.log('Current user in App.vue:', store.getters['auth/currentUser']);
  return adminStatus;
});
    const userAvatar = computed(() => store.getters['auth/currentUser']?.avatar || '/default-avatar.png');
    const showUserMenu = ref(false);
    const showNotifications = ref(false);
    const showMobileMenu = ref(false);
    const notifications = ref([]);
    const unreadNotifications = computed(() => notifications.value.some(n => !n.read));
    const unreadMessages = ref(false);
    const pendingInvitesCount = ref(0);

    const initializeAuth = async () => {
      const { data: { session } } = await supabase.auth.getSession()
      if (session) {
        store.commit('auth/setUser', session.user)
      }

      supabase.auth.onAuthStateChange((event, session) => {
        if (event === 'SIGNED_IN') {
          store.commit('auth/setUser', session.user)
        } else if (event === 'SIGNED_OUT') {
          store.commit('auth/setUser', null)
        }
      })
    }

    onBeforeMount(async () => {
      await refreshSupabaseSchema()
      await initializeAuth() // Add this line to call initializeAuth
    })

    const profileRoute = computed(() => {
  const user = store.getters['auth/currentUser'];
  console.log('Navbar profileRoute, currentUser:', user);
  return user ? '/profile' : '/';
});

    const toggleUserMenu = () => {
      showUserMenu.value = !showUserMenu.value;
    };

    const closeUserMenu = () => {
      showUserMenu.value = false;
    };

    const toggleNotifications = () => {
      showNotifications.value = !showNotifications.value;
    };

    const closeNotifications = (event) => {
      if (!event.target.closest('.notification-panel') && !event.target.closest('button')) {
        showNotifications.value = false;
      }
    };

    const handleNotificationClick = async (notification) => {
      if (!notification.read) {
        await markNotificationAsRead(notification.id);
      }
      router.push(notification.link);
      showNotifications.value = false;
    };

    const formatTime = (timestamp) => {
      return formatDistanceToNow(new Date(timestamp), { addSuffix: true });
    };

    const toggleMobileMenu = () => {
      showMobileMenu.value = !showMobileMenu.value;
      if (showMobileMenu.value) {
        showUserMenu.value = false;
        showNotifications.value = false;
      }
    };

    const closeMobileMenu = () => {
      showMobileMenu.value = false;
    };

    const logoutAndCloseMobileMenu = async () => {
      await logout();
      closeMobileMenu();
    };

    const logout = async () => {
      try {
        await store.dispatch('auth/logout');
        // Force logout to ensure all states are cleared
        store.dispatch('auth/forceLogout');
        // Navigate to auth page
        router.push('/auth');
        // Force a page reload to ensure all components update
        window.location.reload();
      } catch (error) {
        console.error('Logout failed:', error);
        // Optionally, show an error message to the user
      }
    };

    const fetchNotifications = async () => {
  console.log('Fetching notifications...');
  try {
    const { data: { user }, error: authError } = await supabase.auth.getUser();
    if (authError) {
      console.error('Auth error:', authError);
      throw authError;
    }
    console.log('Current user:', user);
    if (!user) throw new Error('No user logged in');

    console.log('Querying notifications for user:', user.id);
    const { data, error: fetchError } = await supabase
      .from('notifications')
      .select(`
        *,
        recommendation_list_invites (
          id
        )
      `)
      .eq('user_id', user.id)
      .eq('read', false)
      .order('created_at', { ascending: false });

    if (fetchError) {
      console.error('Supabase error:', fetchError);
      throw fetchError;
    }

    console.log('Fetched notifications:', data);
    notifications.value = data.map(notification => ({
      ...notification,
      invite_id: notification.recommendation_list_invites?.id
    }));
  } catch (err) {
    console.error('Error fetching notifications:', err);
    notificationError.value = 'Failed to fetch notifications: ' + (err.message || 'Unknown error');
  }
};

    const fetchPendingInvitesCount = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { count, error } = await supabase
          .from('recommendation_list_invites')
          .select('id', { count: 'exact' })
          .eq('user_id', user.id)
          .eq('status', 'pending');

        if (error) {
          console.error('Error fetching pending invites count:', error);
        } else {
          pendingInvitesCount.value = count;
        }
      }
    };

    onMounted(async () => {
      console.log('Component mounted, setting up notifications...');
      await fetchNotifications();
      await fetchPendingInvitesCount();

      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        console.log('Setting up real-time subscription for user:', user.id);
        const notificationsSubscription = supabase
          .channel('public:notifications')
          .on('postgres_changes', 
            { event: 'INSERT', schema: 'public', table: 'notifications', filter: `user_id=eq.${user.id}` },
            (payload) => {
              console.log('Real-time notification received:', payload.new);
              notifications.value.unshift(payload.new);
            }
          )
          .subscribe((status) => {
            console.log('Subscription status:', status);
          });

        const invitesSubscription = supabase
          .channel('public:recommendation_list_invites')
          .on('postgres_changes', 
            { event: '*', schema: 'public', table: 'recommendation_list_invites' },
            fetchPendingInvitesCount
          )
          .subscribe();

        onUnmounted(() => {
          console.log('Component unmounting, removing notification subscription');
          supabase.removeChannel(notificationsSubscription);
          supabase.removeChannel(invitesSubscription);
        });
      } else {
        console.log('No user logged in, skipping real-time subscription');
      }
    });

    const markNotificationAsRead = async (notificationId) => {
      const { error } = await supabase
        .from('notifications')
        .update({ read: true })
        .eq('id', notificationId);

      if (error) {
        console.error('Error marking notification as read:', error);
      } else {
        const index = notifications.value.findIndex(n => n.id === notificationId);
      if (index !== -1) {
          notifications.value[index].read = true;
      }
      }
    };
    
    const acceptInvite = async (notification) => {
  try {
    console.log('Accepting invite with ID:', notification.invite_id);
    const { data, error } = await supabase.rpc('accept_recommendation_list_invite', {
      p_invite_id: notification.invite_id
    });
    if (error) {
      console.error('Supabase RPC error:', error);
      throw error;
    }
    console.log('Supabase RPC response:', data);
    if (data === true) {
      notifications.value = notifications.value.filter(n => n.id !== notification.id);
      pendingInvitesCount.value = Math.max(0, pendingInvitesCount.value - 1);
      console.log('Invite accepted successfully');
    } else {
      throw new Error('Invite acceptance failed on the server');
    }
  } catch (err) {
    console.error('Error accepting invite:', err);
    notificationError.value = 'Failed to accept invite: ' + (err.message || 'Unknown error');
  }
};

const rejectInvite = async (notification) => {
  try {
    const { data, error } = await supabase.rpc('reject_recommendation_list_invite', {
      p_invite_id: notification.invite_id
    });
    if (error) throw error;
    
    if (data === true) {
      notifications.value = notifications.value.filter(n => n.id !== notification.id);
      pendingInvitesCount.value = Math.max(0, pendingInvitesCount.value - 1);
      console.log('Invite rejected successfully');
      // You might want to perform additional actions here, such as:
      // - Updating any relevant UI components
      // - Showing a success message to the user
    } else {
      throw new Error('Invite rejection failed on the server');
    }
  } catch (err) {
    console.error('Error rejecting invite:', err);
    notificationError.value = 'Failed to reject invite: ' + (err.message || 'Unknown error');
    // You might want to show this error to the user in the UI
  }
};

    const checkUnreadMessages = async () => {
      const user = store.getters['auth/currentUser'];
      if (user) {
        const { count, error } = await supabase
          .from('messages')
          .select('*', { count: 'exact', head: true })
          .eq('recipient_id', user.id)
          .eq('read', false);

        if (error) {
          console.error('Error checking unread messages:', error);
        } else {
          unreadMessages.value = count > 0;
        }
      }
    };

    onMounted(async () => {
  const isAuthenticated = await store.dispatch('auth/checkAuth');
  console.log('Is authenticated:', isAuthenticated);
  if (isAuthenticated) {
    console.log('Current user after checkAuth:', store.getters['auth/currentUser']);
    console.log('Is admin after checkAuth:', store.getters['auth/isAdmin']);
  }

  if (!isAuthenticated && router.currentRoute.value.meta.requiresAuth) {
    router.push('/auth');
  }

  const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
    console.log('Auth state changed:', event);
    if (event === 'SIGNED_IN') {
      await store.dispatch('auth/setUser', session.user);
      console.log('User set after sign in:', store.getters['auth/currentUser']);
      console.log('Is admin after sign in:', store.getters['auth/isAdmin']);
      if (router.currentRoute.value.path === '/auth') {
        router.push('/dashboard');
      }
    } else if (event === 'SIGNED_OUT') {
      await store.dispatch('auth/logout');
      if (router.currentRoute.value.meta.requiresAuth) {
        router.push('/auth');
      }
    }
  });

  document.addEventListener('click', closeNotifications);
  
  watchEffect(() => {
    if (isLoggedIn.value) {
      fetchNotifications();
      checkUnreadMessages();
      fetchPendingInvitesCount();
    }
  });

  const updateInterval = setInterval(() => {
    if (isLoggedIn.value) {
      fetchNotifications();
      checkUnreadMessages();
      fetchPendingInvitesCount();
    }
  }, 60000); // Update every minute

  // Handle Supabase session changes
  supabase.auth.onAuthStateChange((event, session) => {
    console.log('Supabase auth state changed:', event);
    if (event === 'SIGNED_IN') {
      store.commit('auth/setUser', session.user);
      console.log('User set after Supabase sign in:', store.getters['auth/currentUser']);
      console.log('Is admin after Supabase sign in:', store.getters['auth/isAdmin']);
    } else if (event === 'SIGNED_OUT') {
      store.commit('auth/setUser', null);
    }
  });

      onUnmounted(() => {
        document.removeEventListener('click', closeNotifications);
        clearInterval(updateInterval);
        authListener.unsubscribe();
      });
    });

    return {
      isLoggedIn,
      userAvatar,
      showUserMenu,
      showNotifications,
      showMobileMenu,
      notifications,
      unreadNotifications,
      unreadMessages,
      pendingInvitesCount,
      profileRoute,
      toggleUserMenu,
      closeUserMenu,
      toggleNotifications,
      toggleMobileMenu,
      closeMobileMenu,
      logoutAndCloseMobileMenu,
      handleNotificationClick,
      logout,
      formatTime,
      notificationError,
      isAdmin,
      NOTIFICATION_TYPES,
      acceptInvite,
      rejectInvite
    };
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .notification-panel {
    width: 95%;
    left: 2.5%;
    right: 2.5%;
  }
}
</style>