<template>
  <div class="min-h-screen p-6 bg-gray-100 text-gray-900">
    <div class="max-w-6xl mx-auto">
      <div class="flex justify-between items-center mb-6">
        <h1 class="text-3xl font-bold">My Works</h1>
        <button @click="createNewStory" class="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300">
          Create New Story
        </button>
      </div>

      <!-- Regular Stories -->
      <h2 class="text-2xl font-bold mb-4">My Stories</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        <div v-for="story in regularStories" :key="story.id" class="bg-white p-4 rounded-md shadow-md">
          <a :href="`/story/${story.id}/details`" class="block">
            <img :src="story.cover_image" :alt="story.title" class="w-full h-64 object-cover rounded-t-md" />
            <div class="p-4">
              <h2 class="text-xl font-semibold mb-2">{{ story.title }}</h2>
              <div class="flex justify-between items-center">
                <span :class="['px-2 py-1 rounded-full text-sm', story.status === 'published' ? 'bg-green-200 text-green-800' : 'bg-yellow-200 text-yellow-800']">
                  {{ story.status }}
                </span>
                <div>
                  <button @click.prevent="goToWritingInterface(story.id)" class="text-blue-600 hover:text-blue-800 mr-2">Write</button>
                  <button @click.prevent="editStory(story.id)" class="text-blue-600 hover:text-blue-800 mr-2">Edit</button>
                  <button @click.prevent="viewStory(story.id)" class="text-green-600 hover:text-green-800 mr-2">View</button>
                  <button @click.prevent="deleteStory(story.id)" class="text-red-600 hover:text-red-800">Delete</button>
                </div>
              </div>
              <div class="mt-4 flex justify-between items-center">
                <div class="flex items-center text-gray-600">
                  <EyeIcon class="w-5 h-5 mr-1 text-blue-500" />
                  <span>{{ story.reads }}</span>
                </div>
                <div class="flex items-center text-gray-600">
                  <HeartIcon class="w-5 h-5 mr-1 text-red-500" />
                  <span>{{ story.votes }}</span>
                </div>
                <div class="flex items-center text-gray-600">
                  <MessageSquareIcon class="w-5 h-5 mr-1 text-green-500" />
                  <span>{{ story.comments }}</span>
                </div>
              </div>
              <button @click.prevent="moveToChallenge(story)" class="mt-2 text-blue-600 hover:text-blue-800">Move to Challenge</button>
            </div>
          </a>
        </div>
      </div>

      <!-- Challenge Stories -->
      <h2 class="text-2xl font-bold mb-4">Challenge Stories</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div v-for="story in challengeStories" :key="story.id" class="bg-white p-4 rounded-md shadow-md">
          <a :href="`/story/${story.id}/details`" class="block">
            <img :src="story.cover_image" :alt="story.title" class="w-full h-64 object-cover rounded-t-md" />
            <div class="p-4">
              <h2 class="text-xl font-semibold mb-2">{{ story.title }}</h2>
              <div class="flex justify-between items-center">
                <span :class="['px-2 py-1 rounded-full text-sm', story.status === 'published' ? 'bg-green-200 text-green-800' : 'bg-yellow-200 text-yellow-800']">
                  {{ story.status }}
                </span>
                <span class="text-blue-600">Challenge: {{ story.challenge_name }}</span>
              </div>
              <div class="mt-4 flex justify-between items-center">
                <div class="flex items-center text-gray-600">
                  <EyeIcon class="w-5 h-5 mr-1 text-blue-500" />
                  <span>{{ story.reads }}</span>
                </div>
                <div class="flex items-center text-gray-600">
                  <HeartIcon class="w-5 h-5 mr-1 text-red-500" />
                  <span>{{ story.votes }}</span>
                </div>
                <div class="flex items-center text-gray-600">
                  <MessageSquareIcon class="w-5 h-5 mr-1 text-green-500" />
                  <span>{{ story.comments }}</span>
                </div>
              </div>
              <button @click.prevent="removeFromChallenge(story)" class="mt-2 text-blue-600 hover:text-blue-800">Remove from Challenge</button>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { supabase } from '@/supabase';
import { Eye as EyeIcon, Heart as HeartIcon, MessageSquare as MessageSquareIcon } from 'lucide-vue-next';
import { useStore } from 'vuex';

export default {
  name: 'MyWorksPage',
  components: {
    EyeIcon,
    HeartIcon,
    MessageSquareIcon,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const stories = ref([]);

    const fetchStories = async () => {
      const user = store.getters['auth/currentUser'];
      if (!user) return;

      const { data, error } = await supabase
        .from('stories')
        .select(`
          *,
          story_reads(count),
          story_votes(count),
          story_comments(count)
        `)
        .eq('author_id', user.id)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching stories:', error);
      } else {
        stories.value = data.map(story => ({
          ...story,
          reads: story.story_reads[0]?.count || 0,
          votes: story.story_votes[0]?.count || 0,
          comments: story.story_comments[0]?.count || 0,
        }));
      }
    };

    onMounted(fetchStories);

    const regularStories = computed(() => stories.value.filter(story => !story.challenge_id));
    const challengeStories = computed(() => stories.value.filter(story => story.challenge_id));

    const createNewStory = () => {
      router.push('/create-story');
    };

    const goToWritingInterface = (id) => {
      router.push(`/write/${id}/new-chapter`);
    };

    const editStory = (id) => {
      router.push(`/story/${id}/details`);
    };

    const viewStory = (id) => {
      router.push(`/story/${id}`);
    };

    const deleteStory = async (id) => {
      if (confirm('Are you sure you want to delete this story?')) {
        const { error } = await supabase
          .from('stories')
          .delete()
          .eq('id', id);

        if (error) {
          console.error('Error deleting story:', error);
        } else {
          await fetchStories();
        }
      }
    };

    const moveToChallenge = async (story) => {
      const { error } = await supabase
        .from('stories')
        .update({ challenge_id: 'placeholder_challenge_id' })
        .eq('id', story.id);

      if (error) {
        console.error('Error moving story to challenge:', error);
      } else {
        await fetchStories();
      }
    };

    const removeFromChallenge = async (story) => {
      const { error } = await supabase
        .from('stories')
        .update({ challenge_id: null })
        .eq('id', story.id);

      if (error) {
        console.error('Error removing story from challenge:', error);
      } else {
        await fetchStories();
      }
    };

    return {
      stories,
      regularStories,
      challengeStories,
      createNewStory,
      goToWritingInterface,
      editStory,
      viewStory,
      deleteStory,
      moveToChallenge,
      removeFromChallenge,
    };
  }
};
</script>