import { supabase } from '@/supabase';

export const storyService = {
  async saveChapter(chapterId, chapterData) {
    const { data, error } = await supabase
      .from('chapters')
      .upsert({ id: chapterId, ...chapterData })
      .select()
      .single();

    if (error) throw error;
    return data;
  },

  async publishStory(storyId, publish) {
    const { data, error } = await supabase
      .from('stories')
      .update({ published: publish })
      .eq('id', storyId)
      .select()
      .single();

    if (error) throw error;
    return data;
  },

  async moveStory(storyId, challengeId) {
    const { data, error } = await supabase
      .from('stories')
      .update({ challenge_id: challengeId })
      .eq('id', storyId)
      .select()
      .single();

    if (error) throw error;
    return data;
  },

  async getStoryStats(storyId) {
    const { data, error } = await supabase
      .rpc('get_story_stats', { story_id: storyId });

    if (error) throw error;
    return data;
  }
};