<template>
  <div>
    <p v-if="loading">Processing authentication...</p>
    <p v-if="error">{{ error }}</p>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { supabase } from '@/supabase';
import { useStore } from 'vuex'; // Assuming you're using Vuex for state management

export default {
  name: 'AuthCallback',
  setup() {
    const router = useRouter();
    const store = useStore();
    const loading = ref(true);
    const error = ref('');

    onMounted(async () => {
      try {
        const { data, error: sessionError } = await supabase.auth.getSession();

        if (sessionError) throw sessionError;

        if (data && data.session) {
          // Set the user in your store
          await store.dispatch('auth/setUser', data.session.user);
          
          console.log('Session set successfully:', data.session);
          router.push('/dashboard');
        } else {
          throw new Error('No session data found');
        }
      } catch (err) {
        console.error('Error setting session:', err);
        error.value = 'Authentication failed. Please try logging in again.';
        await store.dispatch('auth/logout'); // Clear any potentially inconsistent state
        router.push('/auth');
      } finally {
        loading.value = false;
      }
    });

    return { loading, error };
  }
}
</script>