<template>
  <div class="bg-gray-100 min-h-screen">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div class="flex flex-col lg:flex-row gap-8">
        <!-- Left Sidebar -->
        <div class="lg:w-1/4">
          <!-- Profile Card -->
          <div class="bg-white rounded-lg shadow-md overflow-hidden mb-6">
  <div class="bg-gradient-to-r from-blue-500 to-purple-600 h-40 relative">
    <img 
      :src="user.avatar_url || defaultAvatar" 
      :alt="user.name" 
      class="absolute left-1/2 transform -translate-x-1/2 -bottom-16 w-32 h-32 rounded-full border-4 border-white shadow-lg object-cover"
    />
  </div>
  <div class="pt-20 px-6 pb-6 text-center">
    <h1 class="text-2xl font-bold">{{ user.name }}</h1>
    <p class="text-gray-600 mt-1">@{{ user.username }}</p>
    <p class="text-gray-600 mt-1">{{ user.tagline }}</p>
    <div class="flex justify-center mt-4 space-x-4">
      <div class="cursor-pointer" @click="viewFollowers">
        <p class="font-semibold">{{ user.followers }}</p>
        <p class="text-sm text-gray-600">Followers</p>
      </div>
      <div class="cursor-pointer" @click="viewFollowing">
        <p class="font-semibold">{{ user.following }}</p>
        <p class="text-sm text-gray-600">Following</p>
      </div>
      <div>
        <p class="font-semibold">{{ user.publishedStories.length }}</p>
        <p class="text-sm text-gray-600">Stories</p>
      </div>
    </div>
  </div>
</div>

          <!-- Writing Stats -->
          <div class="bg-white rounded-lg shadow-md p-6 mb-6">
            <h2 class="text-xl font-bold mb-4">Writing Stats</h2>
            <div class="space-y-4">
              <div class="flex justify-between items-center">
                <span class="text-gray-600">Total Words</span>
                <span class="font-semibold">{{ user.stats.totalWords.toLocaleString() }}</span>
              </div>
              <div class="flex justify-between items-center">
                <span class="text-gray-600">Stories Published</span>
                <span class="font-semibold">{{ user.stats.storiesPublished }}</span>
              </div>
              <div class="flex justify-between items-center">
                <span class="text-gray-600">Total Chapters</span>
                <span class="font-semibold">{{ user.stats.totalChapters }}</span>
              </div>
            </div>
          </div>
         </div>
          

        <!-- Main Content -->
        <div class="lg:flex-1">
          <div class="bg-white rounded-lg shadow-md overflow-hidden">
            <div class="flex border-b">
              <button
                v-for="tab in visibleTabs"
                :key="tab"
                @click="currentTab = tab"
                class="px-4 py-2 font-medium"
                :class="{ 'text-blue-600 border-b-2 border-blue-600': currentTab === tab }"
              >
                {{ tab }}
              </button>
            </div>

            <!-- About, Books, and Messages -->
            <div v-if="currentTab === 'About'" class="p-6">
              <div class="mb-8">
                <div class="flex justify-between items-center mb-4">
                  <h2 class="text-2xl font-bold">About Me</h2>
                  <button @click="toggleAboutMe" class="text-blue-600 hover:underline">
                    {{ showAboutMe ? 'Hide' : 'Show' }}
                  </button>
                </div>
                <p v-if="showAboutMe">{{ user.bio }}</p>
              </div>

              <div class="mb-8">
                <h2 class="text-2xl font-bold mb-4">My Books</h2>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  <div v-for="story in user.publishedStories" :key="story.id" class="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer" @click="viewBook(story.id)">
  <div class="relative pt-[150%]"> <!-- This creates a 2:3 aspect ratio container -->
    <img 
      :src="story.cover_image" 
      :alt="story.title"
      class="absolute top-0 left-0 w-full h-full object-cover"
      @error="handleImageError"
    />
  </div>
  <div class="p-4">
    <h3 class="font-semibold text-lg mb-2">{{ story.title }}</h3>
    <p class="text-gray-600 mb-2 line-clamp-2">{{ story.description }}</p>
    <span class="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-xs">{{ story.genre }}</span>
    <div class="mt-3 flex items-center text-sm text-gray-500">
      <EyeIcon class="w-4 h-4 mr-1" />
      <span>{{ story.reads.toLocaleString() }} reads</span>
    </div>
  </div>
</div>
</div>
</div>

              <div>
                <div class="flex justify-between items-center mb-4">
                  <h2 class="text-2xl font-bold">Message Board</h2>
                  <button @click="showAnnouncementModal = true" class="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition duration-300">
                    Make Announcement
                  </button>
                </div>
                <!-- Pinned Announcements -->
                <div v-if="user.pinnedAnnouncements.length > 0" class="mb-6">
                  <h3 class="text-xl font-semibold mb-2">Pinned Announcements</h3>
                  <div v-for="announcement in user.pinnedAnnouncements" :key="announcement.id" class="bg-yellow-100 p-4 rounded-lg mb-2">
                    <div v-html="announcement.content"></div>
                    <div class="text-sm text-gray-500 mt-2">{{ announcement.createdAt }}</div>
                    <button @click="unpinAnnouncement(announcement.id)" class="text-blue-600 hover:underline mt-2">Unpin</button>
                  </div>
                </div>
                <div class="space-y-6 mb-6">
  <div v-for="message in user.messages" :key="message.id" class="bg-white p-4 rounded-lg shadow-md">
    <div class="flex items-center justify-between mb-2">
      <div class="flex items-center">
        <img 
  :src="message.author.avatar_url || defaultAvatar" 
  :alt="message.author.name"
  class="w-10 h-10 rounded-full mr-3 object-cover"
/>
        <div>
          <router-link :to="{ name: 'VisitedUserProfile', params: { username: message.author.username } }" class="font-semibold text-lg hover:underline">{{ message.author.name }}</router-link>
          <p class="text-gray-500 text-sm">{{ formatDate(message.created_at) }}</p>
        </div>
      </div>
      <button v-if="currentUser.id === user.id" @click="deleteMessage(message.id)" class="text-red-600 hover:text-red-800">
        <TrashIcon class="w-5 h-5" />
      </button>
    </div>
    <div v-html="message.content" class="text-gray-800 mt-2 mb-4"></div>
    <div class="space-y-4 ml-8 border-l-2 border-gray-200 pl-4">
      <div v-for="reply in message.replies" :key="reply.id" class="bg-gray-50 p-3 rounded-lg">
        <div class="flex items-center justify-between mb-2">
          <div class="flex items-center">
            <img 
  :src="reply.author.avatar_url || defaultAvatar" 
  :alt="reply.author.name"
  class="w-8 h-8 rounded-full mr-2 object-cover"
/>
            <div>
              <router-link :to="{ name: 'VisitedUserProfile', params: { username: reply.author.username } }" class="font-semibold hover:underline">{{ reply.author.name }}</router-link>
              <p class="text-gray-500 text-xs">{{ formatDate(reply.created_at) }}</p>
            </div>
          </div>
        </div>
        <p class="text-gray-800">{{ reply.content }}</p>
      </div>
    </div>
    <div class="mt-4">
      <button @click="showReplyForm(message.id)" class="text-blue-600 hover:underline">Reply</button>
    </div>
    <div v-if="replyingToMessageId === message.id" class="mt-2">
      <textarea
        v-model="newReply"
        rows="2"
        class="w-full border rounded-lg p-2 mb-2"
        placeholder="Write a reply..."
      ></textarea>
      <button
        @click="addReply(message.id)"
        class="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-300"
      >
        Submit Reply
      </button>
    </div>
  </div>
</div>
              </div>
            </div>

            <!-- Bookshelf -->
            <div v-if="currentTab === 'Bookshelf'" class="p-6">
  <h2 class="text-2xl font-bold mb-4">Currently Reading</h2>
  <div v-if="user.currentlyReading && user.currentlyReading.length > 0" class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
    <div v-for="book in user.currentlyReading" :key="book.id" class="bg-white rounded-lg shadow-md overflow-hidden">
      <div class="aspect-w-2 aspect-h-3 relative">
        <img 
          :src="book.cover_image" 
          :alt="book.title" 
          class="absolute inset-0 w-full h-full object-cover"
        />
      </div>
      <div class="p-4">
        <h3 class="font-semibold text-lg mb-2 line-clamp-2">{{ book.title }}</h3>
        <p class="text-gray-600 line-clamp-1">by {{ book.author }}</p>
      </div>
    </div>
  </div>
  <p v-else class="text-gray-600">Not currently reading any books.</p>
</div>

            <!-- Activity Tab Content -->
            <div v-if="currentTab === 'Activity'" class="p-6">
              <h2 class="text-2xl font-bold mb-4">Recent Activity</h2>
              <ul class="space-y-4">
                <li v-for="activity in user.activityFeed" :key="activity.id" class="border-b pb-4 last:border-b-0">
                  <div class="flex items-center">
                    <span class="mr-2">
                      <HeartIcon v-if="activity.type === 'applause'" class="w-5 h-5 text-red-500" />
                      <MessageSquareIcon v-if="activity.type === 'comment'" class="w-5 h-5 text-blue-500" />
                      <UserPlusIcon v-if="activity.type === 'follow'" class="w-5 h-5 text-green-500" />
                      <UserIcon v-if="activity.type === 'recommendation_invite'" class="w-5 h-5 text-purple-500" />
                      <BookmarkIcon v-if="activity.type === 'added_to_list'" class="w-5 h-5 text-yellow-500" />
                    </span>
                    <span>
                      {{ activity.description }}
                      <router-link :to="activity.link" class="text-blue-600 hover:underline">
                        {{ activity.linkText }}
                      </router-link>
                      <span class="text-gray-500 text-sm ml-2">{{ activity.time }}</span>
                    </span>
                  </div>
                </li>
              </ul>
            </div>

            <!-- Recommendation Lists Tab -->
            <div v-if="currentTab === 'Recommendation Lists'" class="p-6">
              <RecommendationListManagement 
                :lists="user.recommendationLists"
                @deleteList="deleteRecommendationList"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Announcement Modal -->
    <div v-if="showAnnouncementModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div class="bg-white rounded-lg p-6 w-3/4 max-w-3xl">
        <h2 class="text-2xl font-bold mb-4">Make Announcement</h2>
        <form @submit.prevent="sendAnnouncement">
          <div id="announcement-editor" class="mb-4 h-40"></div>
          <div class="flex items-center mb-4">
            <input type="checkbox" id="pin-announcement" v-model="pinAnnouncement" class="mr-2">
            <label for="pin-announcement">Pin this announcement</label>
          </div>
          <div class="flex justify-end">
            <button
              type="button"
              @click="closeAnnouncementModal"
              class="mr-2 px-4 py-2 border rounded-lg"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-300"
            >
              Send to Followers
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed, onMounted, onUnmounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { EyeIcon, HeartIcon, MessageSquareIcon, UserPlusIcon, TrashIcon, UserIcon, BookmarkIcon } from 'lucide-vue-next';
import RecommendationListManagement from './RecommendationListManagement.vue';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { supabase } from '@/supabase';
import defaultAvatar from '@/assets/default-avatar-svg.png';

export default {
  name: 'OwnedUserProfile',
  components: {
    EyeIcon,
    HeartIcon,
    MessageSquareIcon,
    UserPlusIcon,
    TrashIcon,
    UserIcon,
    BookmarkIcon,
    RecommendationListManagement
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const currentUser = computed(() => store.getters['auth/currentUser']);
    
    const user = reactive({
      id: '',
      username: '',
      name: '',
      tagline: '',
      avatar_url: '',
      bio: '',
      followers: 0,
      following: 0,
      stories: 0,
      stats: {
        totalWords: 0,
        storiesPublished: 0,
        totalChapters: 0
      },
      achievements: [],
      publishedStories: [],
      bookshelves: [],
      activityFeed: [],
      messages: [],
      pinnedAnnouncements: [],
      recommendationLists: [],
    });

    const visibleTabs = ref(['About', 'Bookshelf', 'Activity', 'Recommendation Lists']);
    const currentTab = ref('About');
    const showAboutMe = ref(true);
    const showAnnouncementModal = ref(false);
    const pinAnnouncement = ref(false);
    const replyingToMessageId = ref(null);
    const newReply = ref('');
    const newMessage = ref('');
    const newListTitle = ref('');
    const newListDescription = ref('');
    let quill = null;

    const fetchUserProfile = async () => {
      try {
        const { data, error } = await supabase
          .from('users')
          .select('*')
          .eq('id', currentUser.value.id)
          .single();

        if (error) throw error;

        if (data) {
          Object.assign(user, data);
          await Promise.all([
            fetchFollowCounts(),
            fetchPublishedStories(),
            fetchBookshelves(),
            fetchActivityFeed(),
            fetchMessages(),
            fetchPinnedAnnouncements(),
            fetchRecommendationLists(),
          ]);
        } else {
          throw new Error('User not found');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    const fetchPublishedStories = async () => {
  try {
    const { data, error } = await supabase
      .from('stories')
      .select(`
        id,
        title,
        description,
        genre,
        cover_image,
        reads,
        word_count,
        published_at
      `)
      .eq('author_id', user.id)
      .eq('status', 'published')
      .order('published_at', { ascending: false });

    if (error) throw error;
    
    user.publishedStories = data.map(story => ({
      ...story,
      reads: story.reads || 0 // Ensure reads is always a number, defaulting to 0 if null
    }));
    
    user.stats.storiesPublished = data.length;
    user.stats.totalWords = data.reduce((sum, story) => sum + (story.word_count || 0), 0);
    user.stats.totalReads = data.reduce((sum, story) => sum + (story.reads || 0), 0);
    user.stats.totalChapters = await fetchTotalChapters(user.id);
  } catch (error) {
    console.error('Error fetching published stories:', error);
  }
};

const fetchTotalChapters = async (userId) => {
  try {
    const { count, error } = await supabase
      .from('chapters')
      .select('*', { count: 'exact' })
      .join('stories', 'chapters.story_id', 'stories.id')
      .eq('stories.user_id', userId);
    if (error) throw error;
    return count;
  } catch (error) {
    console.error('Error fetching total chapters for user:', error);
    return 0;
  }
};

    const fetchBookshelves = async () => {
  try {
    const { data, error } = await supabase
      .from('bookshelf')
      .select(`
        id,
        status,
        stories (
          id,
          title,
          cover_image,
          users!author_id (username)
        )
      `)
      .eq('user_id', user.id)
      .eq('status', 'Currently Reading');

    if (error) throw error;
    
    user.currentlyReading = data.map(item => ({
      id: item.stories.id,
      title: item.stories.title,
      author: item.stories.users.username,
      cover_image: item.stories.cover_image
    }));

    console.log('Currently reading books:', user.currentlyReading);
  } catch (error) {
    console.error('Error fetching currently reading books:', error);
  }
};

    const fetchActivityFeed = async () => {
  try {
    // Fetch votes (likes)
    const { data: votes, error: votesError } = await supabase
      .from('chapter_votes')
      .select(`
        id, 
        chapter:chapter_id (
          story:story_id (
            id,
            title
          )
        ),
        created_at
      `)
      .eq('user_id', user.id)
      .order('created_at', { ascending: false })
      .limit(10);
    if (votesError) throw votesError;

    // Fetch comments
    const { data: comments, error: commentsError } = await supabase
      .from('chapter_comments')
      .select(`
        id, 
        chapter:chapter_id (
          story:story_id (
            id,
            title
          )
        ),
        created_at
      `)
      .eq('user_id', user.id)
      .order('created_at', { ascending: false })
      .limit(10);
    if (commentsError) throw commentsError;

    // Fetch follows
    const { data: follows, error: followsError } = await supabase
      .from('followers')
      .select('id, followed:followed_id(username), created_at')
      .eq('follower_id', user.id)
      .order('created_at', { ascending: false })
      .limit(10);
    if (followsError) throw followsError;

    // Fetch recommendation invites
    const { data: invites, error: invitesError } = await supabase
      .from('recommendation_list_invites')
      .select('id, list:recommendation_lists(title), created_at')
      .eq('invitee_id', user.id)
      .order('created_at', { ascending: false })
      .limit(10);
    if (invitesError) throw invitesError;

    // Fetch added to list activities
    const { data: addedToList, error: addedToListError } = await supabase
      .from('recommendation_list_items')
      .select('id, list:recommendation_lists(title), story:stories(title), created_at')
      .eq('story:stories.author_id', user.id)
      .order('created_at', { ascending: false })
      .limit(10);
    if (addedToListError) throw addedToListError;

    // Combine and format all activities
    const allActivities = [
      ...votes.map(v => ({
        id: `vote_${v.id}`,
        type: 'vote',
        description: 'Liked the story',
        linkText: v.story.title,
        link: `/story/${v.story.id}`,
        time: formatRelativeTime(v.created_at)
      })),
      ...comments.map(c => ({
        id: `comment_${c.id}`,
        type: 'comment',
        description: 'Commented on',
        linkText: c.story.title,
        link: `/story/${c.story.id}`,
        time: formatRelativeTime(c.created_at)
      })),
      ...follows.map(f => ({
        id: `follow_${f.id}`,
        type: 'follow',
        description: 'Followed author',
        linkText: f.followed.username,
        link: `/profile/${f.followed.username}`,
        time: formatRelativeTime(f.created_at)
      })),
      ...invites.map(i => ({
        id: `invite_${i.id}`,
        type: 'recommendation_invite',
        description: 'Invited you to their recommendation list',
        linkText: i.list.title,
        link: `/recommendation-list/${i.list.id}`,
        time: formatRelativeTime(i.created_at)
      })),
      ...addedToList.map(a => ({
        id: `added_to_list_${a.id}`,
        type: 'added_to_list',
        description: 'Added your story to their list',
        linkText: a.list.title,
        link: `/recommendation-list/${a.list.id}`,
        time: formatRelativeTime(a.created_at)
      }))
    ];

    // Sort all activities by time (most recent first) and limit to 10
    user.activityFeed = allActivities
      .sort((a, b) => new Date(b.time) - new Date(a.time))
      .slice(0, 10);

    console.log('User activity feed:', user.activityFeed);
  } catch (error) {
    console.error('Error fetching activity feed:', error);
  }
};

const formatRelativeTime = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);

  if (diffInSeconds < 60) return 'just now';
  if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
  if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
  if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)} days ago`;
  return `${Math.floor(diffInSeconds / 604800)} weeks ago`;
};

    const fetchMessages = async () => {
  try {
    const { data, error } = await supabase
      .from('profile_messages')
      .select(`
        *,
        author:users!author_id(name, username, avatar_url),
        replies:profile_message_replies(
          *,
          author:users!author_id(name, username, avatar_url)
        )
      `)
      .eq('profile_id', user.id)
      .order('created_at', { ascending: false });

    if (error) throw error;
    user.messages = data;
  } catch (error) {
    console.error('Error fetching messages:', error);
  }
};

    const fetchPinnedAnnouncements = async () => {
  try {
    if (user && user.id) {
      const { data, error } = await supabase
        .from('announcements')
        .select('*')
        .eq('user_id', user.id)
        .eq('is_pinned', true)
        .order('created_at', { ascending: false });
      if (error) throw error;
      user.pinnedAnnouncements = data;
    }
  } catch (error) {
    console.error('Error fetching pinned announcements:', error);
  }
};

    const fetchRecommendationLists = async () => {
      try {
        const { data, error } = await supabase
          .from('recommendation_lists')
          .select('*')
          .eq('user_id', user.id);

        if (error) throw error;
        user.recommendationLists = data;
      } catch (error) {
        console.error('Error fetching recommendation lists:', error);
      }
    };

    const initQuill = () => {
      quill = new Quill('#announcement-editor', {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'header': [1, 2, 3, false] }],
          ]
        },
        placeholder: 'Write your announcement here...',
      });
    };

    const destroyQuill = () => {
      if (quill) {
        quill = null;
      }
    };

    const toggleAboutMe = () => {
      showAboutMe.value = !showAboutMe.value;
    };

    const showReplyForm = (messageId) => {
      replyingToMessageId.value = messageId;
    };

    const addReply = async (messageId) => {
      if (newReply.value.trim()) {
        try {
          const { data, error } = await supabase
            .from('profile_message_replies')
            .insert({
              message_id: messageId,
              author_id: currentUser.value.id,
              content: newReply.value
            })
            .select(`
              *,
              author:users!author_id(name, avatar)
            `)
            .single();

          if (error) throw error;

          const message = user.messages.find(m => m.id === messageId);
          if (message) {
            message.replies.push(data);
          }
          newReply.value = '';
          replyingToMessageId.value = null;
        } catch (error) {
          console.error('Error adding reply:', error);
        }
      }
    };

    const fetchFollowCounts = async () => {
  try {
    if (user && user.id) {
      const { count: followersCount, error: followersError } = await supabase
        .from('followers')
        .select('*', { count: 'exact' })
        .eq('followed_id', user.id);
      const { count: followingCount, error: followingError } = await supabase
        .from('followers')
        .select('*', { count: 'exact' })
        .eq('follower_id', user.id);
      if (followersError) throw followersError;
      if (followingError) throw followingError;
      user.followers = followersCount;
      user.following = followingCount;
    }
  } catch (error) {
    console.error('Error fetching follow counts:', error);
  }
};

    const deleteMessage = async (messageId) => {
      try {
        const { error } = await supabase
          .from('profile_messages')
          .delete()
          .eq('id', messageId);

        if (error) throw error;

        user.messages = user.messages.filter(m => m.id !== messageId);
      } catch (error) {
        console.error('Error deleting message:', error);
      }
    };

    const closeAnnouncementModal = () => {
      showAnnouncementModal.value = false;
    };

    const sendAnnouncement = async () => {
      if (quill) {
        const content = quill.root.innerHTML;
        if (content.trim()) {
          try {
            const { data, error } = await supabase
              .from('announcements')
              .insert({
                user_id: user.id,
                content: content,
                is_pinned: pinAnnouncement.value
              })
              .select()
              .single();

            if (error) throw error;

            if (pinAnnouncement.value) {
              user.pinnedAnnouncements.unshift(data);
            }
            user.messages.unshift({
              id: data.id,
              author: { name: user.name, avatar: user.avatar_url },
              content: content,
              createdAt: data.created_at,
              replies: []
            });
            quill.setText('');
            pinAnnouncement.value = false;
            closeAnnouncementModal();
          } catch (error) {
            console.error('Error sending announcement:', error);
          }
        }
      }
    };

    const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
}

    const unpinAnnouncement = async (id) => {
      try {
        const { error } = await supabase
          .from('announcements')
          .update({ is_pinned: false })
          .eq('id', id);

        if (error) throw error;

        user.pinnedAnnouncements = user.pinnedAnnouncements.filter(a => a.id !== id);
      } catch (error) {
        console.error('Error unpinning announcement:', error);
      }
    };

    const addMessage = async () => {
      if (newMessage.value.trim()) {
        try {
          const { data, error } = await supabase
            .from('profile_messages')
            .insert({
              profile_id: user.id,
              author_id: currentUser.value.id,
              content: newMessage.value
            })
            .select(`
              *,
              author:users!author_id(name, avatar)
            `)
            .single();

          if (error) throw error;

          user.messages.unshift({
            ...data,
            replies: []
          });
          newMessage.value = '';
        } catch (error) {
          console.error('Error adding message:', error);
        }
      }
    };

    const viewBook = (bookId) => {
      router.push({ name: 'StoryPage', params: { id: bookId } });
    };

    const viewFollowers = () => {
      router.push({ name: 'Followers', params: { username: user.username } });
    };

    const viewFollowing = () => {
      router.push({ name: 'Following', params: { username: user.username } });
    };

    const createRecommendationList = async (listData) => {
      try {
        const { data, error } = await supabase
          .from('recommendation_lists')
          .insert({ ...listData, user_id: user.id })
          .select()
          .single();

        if (error) throw error;
        user.recommendationLists.push(data);
        newListTitle.value = '';
        newListDescription.value = '';
      } catch (error) {
        console.error('Error creating recommendation list:', error);
      }
    };

    const deleteRecommendationList = async (listId) => {
      if (confirm('Are you sure you want to delete this recommendation list?')) {
        try {
          const { error: itemsError } = await supabase
            .from('recommendation_list_items')
            .delete()
            .eq('list_id', listId);

          if (itemsError) throw itemsError;

          const { error: listError } = await supabase
            .from('recommendation_lists')
            .delete()
            .eq('id', listId);

          if (listError) throw listError;

          user.recommendationLists = user.recommendationLists.filter(list => list.id !== listId);

          console.log(`Recommendation list ${listId} deleted successfully`);
        } catch (error) {
          console.error(`Error deleting recommendation list ${listId}:`, error);
        }
      }
    };

    watch(showAnnouncementModal, (newValue) => {
      if (newValue) {
        setTimeout(() => {
          initQuill();
        }, 0);
      } else {
        destroyQuill();
      }
    });

    onMounted(async () => {
      await store.dispatch('auth/checkAuth');
      await fetchUserProfile();
      await fetchActivityFeed();
    });

    onUnmounted(() => {
      destroyQuill();
    });

    return {
      user,
      defaultAvatar,
      visibleTabs,
      currentTab,
      currentUser,
      showAboutMe,
      showAnnouncementModal,
      pinAnnouncement,
      replyingToMessageId,
      newReply,
      newMessage,
      newListTitle,
      newListDescription,
      toggleAboutMe,
      showReplyForm,
      addReply,
      deleteMessage,
      closeAnnouncementModal,
      sendAnnouncement,
      unpinAnnouncement,
      addMessage,
      viewBook,
      viewFollowers,
      viewFollowing,
      createRecommendationList,
      deleteRecommendationList,
      formatDate
    };
  }
};
</script>

<style scoped>
::v-deep .ql-editor {
  min-height: 100px;
  max-height: 200px;
  overflow-y: auto;
}

::v-deep .ql-toolbar {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

::v-deep .ql-container {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
</style>