<template>
  <div class="group-details-page bg-gray-100 min-h-screen p-4 md:p-8">
    <div v-if="loading">Loading group details...</div>
    <div v-else-if="error">{{ error }}</div>
    <div v-else-if="group" class="max-w-7xl mx-auto">
      <!-- Group Header -->
      <div class="bg-white rounded-lg shadow-md overflow-hidden mb-8">
        <div class="bg-gradient-to-r from-indigo-600 to-purple-600 p-6 sm:p-10">
          <div class="flex justify-between items-center">
            <h1 class="text-3xl sm:text-4xl font-bold text-white">{{ group.name }}</h1>
            <button 
              v-if="!isMember" 
              @click="joinGroup" 
              class="bg-white text-indigo-600 px-4 py-2 rounded-full hover:bg-indigo-100 transition duration-300"
            >
              Join Group
            </button>
            <span v-else class="bg-green-500 text-white px-4 py-2 rounded-full">Member</span>
          </div>
          <p class="mt-4 text-indigo-100 text-lg">{{ group.description }}</p>
        </div>
        <div class="p-6 sm:p-10 bg-white">
          <div class="flex flex-wrap justify-between items-center">
            <div class="mb-4 sm:mb-0">
              <p class="text-gray-600">Created by <span class="font-semibold">{{ group.creator?.username }}</span></p>
              <p class="text-gray-600">{{ group.members[0].count }} members • {{ group.posts.length }} posts</p>
            </div>
            <div class="flex space-x-4">
              <router-link 
                :to="{ name: 'CreatePost', params: { groupId: group.id } }" 
                class="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition duration-300"
              >
                Create New Post
              </router-link>
              <button 
                @click="showInviteModal = true" 
                class="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-300"
              >
                Invite Users
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col lg:flex-row gap-8">
        <!-- Main Content -->
        <div class="lg:w-2/3">
          <!-- Search Bar -->
          <div class="mb-6">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Search posts or members..."
              class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>

          <!-- Tabs and Sort -->
          <div class="flex justify-between items-center mb-6">
            <nav class="flex space-x-4" aria-label="Tabs">
              <button 
                v-for="tab in tabs" 
                :key="tab"
                @click="currentTab = tab"
                :class="[
                  'px-3 py-2 font-medium text-sm rounded-md',
                  currentTab === tab
                    ? 'bg-indigo-100 text-indigo-700'
                    : 'text-gray-500 hover:text-gray-700'
                ]"
              >
                {{ tab }}
              </button>
            </nav>
            <div v-if="currentTab === 'Posts'" class="flex items-center">
              <label for="postSort" class="mr-2 text-sm font-medium text-gray-700">Sort:</label>
              <select 
                v-model="postSortOption" 
                id="postSort" 
                class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option value="newest">Newest</option>
                <option value="mostLiked">Most Liked</option>
                <option value="mostCommented">Most Commented</option>
              </select>
            </div>
          </div>

          <!-- Posts Tab -->
          <div v-if="currentTab === 'Posts'" class="space-y-6">
            <div v-for="post in filteredPosts" :key="post.id" class="bg-white p-6 rounded-lg shadow-md">
              <div class="flex items-center mb-4">
                <img :src="post.author.avatar_url || '/default-avatar.png'" :alt="post.author.username" class="w-10 h-10 rounded-full mr-4">
                <div>
                  <h3 class="font-semibold">
                    <router-link :to="{ name: 'VisitedUserProfile', params: { username: post.author.username } }" class="hover:underline">
                      {{ post.author.username }}
                    </router-link>
                  </h3>
                  <p class="text-sm text-gray-500">{{ formatDate(post.created_at) }}</p>
                </div>
              </div>
              <router-link :to="{ name: 'GroupPost', params: { groupId: group.id, postId: post.id } }">
                <h2 class="text-xl font-semibold mb-2">{{ post.title }}</h2>
              </router-link>
              <div class="text-gray-600 mb-4" v-html="truncateContent(post.content)"></div>
              <div class="flex items-center space-x-4 text-gray-500">
    <button @click="likePost(post)" class="flex items-center space-x-1 hover:text-indigo-600">
      <ThumbsUpIcon :class="{ 'text-indigo-600': post.isLiked }" size="18" />
      <span>{{ post.likes || 0 }}</span>
    </button>
                <button class="flex items-center space-x-1 hover:text-indigo-600">
      <MessageCircleIcon size="18" />
      <span>{{ post.comments && post.comments[0] ? post.comments[0].count : 0 }}</span>
    </button>
    <button class="flex items-center space-x-1 hover:text-indigo-600">
      <ShareIcon size="18" />
      <span>Share</span>
    </button>
              </div>
            </div>
          </div>

          <!-- Members Tab -->
          <div v-if="currentTab === 'Members'" class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            <div v-for="member in filteredMembers" :key="member.id" class="bg-white p-6 rounded-lg shadow-md flex items-center space-x-4">
              <img :src="member.user.avatar_url || '/default-avatar.png'" :alt="member.user.username" class="w-16 h-16 rounded-full">
              <div>
                <h3 class="font-semibold">
                  <router-link :to="{ name: 'VisitedUserProfile', params: { username: member.user.username } }" class="hover:underline">
                    {{ member.user.username }}
                  </router-link>
                </h3>
                <p class="text-sm text-gray-500">{{ member.role }}</p>
              </div>
            </div>
          </div>

          <!-- Bookmarked Posts Tab -->
          <div v-if="currentTab === 'Bookmarks'" class="space-y-6">
            <div v-if="bookmarkedPosts.length === 0" class="text-center py-8">
              <p class="text-lg text-gray-600">You haven't bookmarked any posts in this group yet.</p>
            </div>
            <div v-else v-for="post in bookmarkedPosts" :key="post.id" class="bg-white p-6 rounded-lg shadow-md">
              <div class="flex items-center mb-4">
                <img :src="post.author.avatar_url || '/default-avatar.png'" :alt="post.author.username" class="w-10 h-10 rounded-full mr-4">
                <div>
                  <h3 class="font-semibold">
                    <router-link :to="{ name: 'VisitedUserProfile', params: { username: post.author.username } }" class="hover:underline">
                      {{ post.author.username }}
                    </router-link>
                  </h3>
                  <p class="text-sm text-gray-500">{{ formatDate(post.created_at) }}</p>
                </div>
              </div>
              <router-link :to="{ name: 'GroupPost', params: { groupId: group.id, postId: post.id } }">
                <h2 class="text-xl font-semibold mb-2">{{ post.title }}</h2>
              </router-link>
              <div class="text-gray-600 mb-4" v-html="truncateContent(post.content)"></div>
              <div class="flex items-center space-x-4 text-gray-500">
                <button class="flex items-center space-x-1 hover:text-indigo-600">
                  <ThumbsUpIcon size="18" />
                  <span>{{ post.likes || 0 }}</span>
                </button>
                <button class="flex items-center space-x-1 hover:text-indigo-600">
                  <MessageCircleIcon size="18" />
                  <span>{{ post.comments || 0 }}</span>
                </button>
                <button @click="removeBookmark(post.id)" class="flex items-center space-x-1 hover:text-indigo-600">
                  <BookmarkIcon size="18" />
                  <span>Remove Bookmark</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Sidebar -->
        <div class="lg:w-1/3 space-y-6">
          <!-- About Section -->
          <div class="bg-white p-6 rounded-lg shadow-md">
            <div class="flex space-x-4 mb-4">
              <button 
                @click="aboutTab = 'about'"
                :class="['px-3 py-2 font-medium text-sm rounded-md', aboutTab === 'about' ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700']"
              >
                About
              </button>
              <button 
                @click="aboutTab = 'rules'"
                :class="['px-3 py-2 font-medium text-sm rounded-md', aboutTab === 'rules' ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700']"
              >
                Rules
              </button>
            </div>
            <div v-if="aboutTab === 'about'">
              <h2 class="text-xl font-semibold mb-4">About This Group</h2>
              <p class="text-gray-600 mb-4">{{ group.description }}</p>
              <div class="flex flex-wrap gap-2">
                <span v-for="tag in group.tags" :key="tag" class="bg-indigo-100 text-indigo-800 px-3 py-1 rounded-full text-sm">
                  {{ tag }}
                </span>
              </div>
            </div>
            <div v-else-if="aboutTab === 'rules'">
              <h2 class="text-xl font-semibold mb-4">Group Rules</h2>
              <ul v-if="group.rules && group.rules.length > 0" class="list-disc pl-5 space-y-2">
                <li v-for="(rule, index) in group.rules" :key="index" class="text-gray-700">
                  {{ rule }}
                </li>
              </ul>
              <p v-else class="text-gray-600">No specific rules have been set for this group.</p>
            </div>
          </div>

          <!-- Moderators Section -->
          <div class="bg-white p-6 rounded-lg shadow-md">
            <h2 class="text-xl font-semibold mb-4">Moderators</h2>
            <ul class="space-y-4">
              <li v-for="mod in moderators" :key="mod.id" class="flex items-center space-x-3">
                <img :src="mod.user.avatar_url || '/default-avatar.png'" :alt="mod.user.username" class="w-10 h-10 rounded-full">
                <div>
                  <p class="font-semibold">
                    <router-link :to="{ name: 'VisitedUserProfile', params: { username: mod.user.username } }" class="hover:underline">
                      {{ mod.user.username }}
                    </router-link>
                  </p>
                  <p class="text-sm text-gray-500">{{ mod.role }}</p>
                </div>
              </li>
            </ul>
          </div>

          <!-- Recent Activity -->
          <div class="bg-white p-6 rounded-lg shadow-md">
            <h2 class="text-xl font-semibold mb-4">Recent Activity</h2>
            <div class="h-64 overflow-y-auto">
              <ul class="space-y-4">
                <li v-for="activity in recentActivities" :key="activity.id" class="flex items-start space-x-3">
                  <div class="flex-shrink-0 mt-1">
                    <ActivityIcon v-if="activity.type === 'join'" :size="18" class="text-green-500" />
                    <PenToolIcon v-else-if="activity.type === 'post'" :size="18" class="text-blue-500" />
                    <MessageCircleIcon v-else-if="activity.type === 'comment'" :size="18" class="text-yellow-500" />
                  </div>
                  <div>
                    <p class="text-sm">
                      <router-link :to="{ name: 'VisitedUserProfile', params: { username: activity.username } }" class="font-semibold hover:underline">
                        {{ activity.username }}
                      </router-link> 
                      {{ activity.action }}
                      <span v-if="activity.type === 'comment'" class="italic">"{{ activity.content }}"</span>
                    </p>
                    <p class="text-xs text-gray-500">{{ formatDate(activity.created_at) }}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Invite Users Modal -->
    <div v-if="showInviteModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div class="bg-white rounded-lg p-8 max-w-md w-full">
        <h2 class="text-2xl font-bold mb-4">Invite Users</h2>
        <div class="mb-4">
          <label for="inviteUsers" class="block text-sm font-medium text-gray-700">Username</label>
          <input
            v-model="inviteUserQuery"
            @input="searchUsers"
            type="text"
            id="inviteUsers"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            placeholder="Start typing a username..."
          >
          <div v-if="filteredUsers.length > 0" class="mt-2 bg-white border border-gray-300 rounded-md shadow-sm max-h-40 overflow-y-auto">
            <div 
              v-for="user in filteredUsers" 
              :key="user.id" 
              @click="selectUser(user)"
              class="p-2 hover:bg-gray-100 cursor-pointer"
            >
              {{ user.username }}
            </div>
          </div>
        </div>
        <div class="mb-4">
          <label class="block text-sm font-medium text-gray-700">Selected Users</label>
          <div class="mt-2 flex flex-wrap gap-2">
            <div 
              v-for="user in selectedUsers" 
              :key="user.id" 
              class="bg-indigo-100 text-indigo-800 px-2 py-1 rounded-full text-sm flex items-center"
            >
              {{ user.username }}
              <button @click="removeUser(user)" class="ml-2 text-indigo-600 hover:text-indigo-800">&times;</button>
            </div>
          </div>
        </div>
        <div class="flex justify-end">
          <button
            @click="inviteUsers"
            class="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition duration-300"
          >
            Send Invites
          </button>
          <button
            @click="showInviteModal = false"
            class="ml-2 bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition duration-300"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ThumbsUpIcon, MessageCircleIcon, ShareIcon, BookmarkIcon, ActivityIcon, PenToolIcon } from 'lucide-vue-next';
import { groupService } from '@/services/groupService';
import { useStore } from 'vuex';

export default {
  name: 'GroupDetailsPage',
  components: {
    ThumbsUpIcon,
    MessageCircleIcon,
    ShareIcon,
    BookmarkIcon,
    ActivityIcon,
    PenToolIcon
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const groupId = route.params.id;
    const group = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const isMember = ref(false);
    const searchQuery = ref('');
    const showInviteModal = ref(false);
    const inviteUserQuery = ref('');
    const filteredUsers = ref([]);
    const selectedUsers = ref([]);
    const currentTab = ref('Posts');
    const tabs = ['Posts', 'Members', 'Bookmarks'];
    const posts = ref([]);
    const members = ref([]);
    const moderators = ref([]);
    const bookmarkedPosts = ref([]);
    const postSortOption = ref('newest');
    const aboutTab = ref('about');
    const comments = ref([]);

    const fetchGroupDetails = async () => {
  try {
    loading.value = true;
    group.value = await groupService.getGroupDetails(groupId);
    const userId = store.getters['auth/getCurrentUserId'];
    posts.value = await groupService.getGroupPosts(groupId, userId);
    members.value = await groupService.getGroupMembers(groupId);
    moderators.value = members.value.filter(member => member.role === 'admin' || member.role === 'moderator');
    comments.value = await groupService.getRecentGroupComments(groupId, 50);
    
    isMember.value = members.value.some(member => member.user.id === userId);
    
    if (isMember.value) {
      bookmarkedPosts.value = await groupService.getBookmarkedPosts(userId, groupId);
    }
  } catch (err) {
    console.error('Error fetching group details:', err);
    error.value = 'Failed to load group details: ' + (err.message || 'Unknown error');
  } finally {
    loading.value = false;
  }
};

    const sortedPosts = computed(() => {
      let sortedPosts = [...posts.value];
      switch (postSortOption.value) {
        case 'mostLiked':
          return sortedPosts.sort((a, b) => (b.likes || 0) - (a.likes || 0));
        case 'mostCommented':
          return sortedPosts.sort((a, b) => b.comments[0].count - a.comments[0].count);
        case 'newest':
        default:
          return sortedPosts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      }
    });

    const filteredPosts = computed(() => {
      return sortedPosts.value.filter(post =>
        post.title.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        post.content.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        post.author.username.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const filteredMembers = computed(() => {
      return members.value.filter(member =>
        member.user.username.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        member.role.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const recentActivities = computed(() => {
  const stripHtml = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };
      const allActivities = [
    ...posts.value.map(post => ({
      id: `post_${post.id}`,
      type: 'post',
      username: post.author.username,
      action: 'created a new post',
      created_at: post.created_at
    })),
    ...members.value.map(member => ({
      id: `member_${member.id}`,
      type: 'join',
      username: member.user.username,
      action: 'joined the group',
      created_at: member.joined_at || member.created_at
    })),
    ...comments.value.map(comment => ({
      id: `comment_${comment.id}`,
      type: 'comment',
      username: comment.author.username,
      action: 'commented on a post',
      content: stripHtml(comment.content).substring(0, 50) + (stripHtml(comment.content).length > 50 ? '...' : ''),
      created_at: comment.created_at
    }))
  ];

  return allActivities
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .slice(0, 20);
});

    const joinGroup = async () => {
      const userId = store.getters['auth/getCurrentUserId'];
      if (!userId) {
        console.error('User not logged in');
        return;
      }
      try {
        await groupService.addGroupMember(groupId, userId);
        isMember.value = true;
        await fetchGroupDetails();
      } catch (err) {
        console.error('Error joining group:', err);
        error.value = 'Failed to join group: ' + (err.message || 'Unknown error');
      }
    };

    const truncateContent = (content) => {
      const strippedContent = content.replace(/<[^>]*>/g, '');
      return strippedContent.length > 200 ? strippedContent.slice(0, 200) + '...' : strippedContent;
    };

    const likePost = async (post) => {
  try {
    const userId = store.getters['auth/getCurrentUserId'];
    if (post.isLiked) {
      await groupService.unlikePost(post.id, userId);
      post.likes--;
    } else {
      await groupService.likePost(post.id, userId);
      post.likes++;
    }
    post.isLiked = !post.isLiked;
    // Create a new array to trigger reactivity
    posts.value = posts.value.map(p => p.id === post.id ? {...p} : p);
  } catch (error) {
    console.error('Error liking/unliking post:', error);
  }
};

    const searchUsers = async () => {
      if (inviteUserQuery.value.length < 2) {
        filteredUsers.value = [];
        return;
      }
      try {
        filteredUsers.value = await groupService.searchUsers(inviteUserQuery.value);
      } catch (err) {
        console.error('Error searching users:', err);
        error.value = 'Failed to search users: ' + (err.message || 'Unknown error');
      }
    };

    const selectUser = (user) => {
      if (!selectedUsers.value.some(u => u.id === user.id)) {
        selectedUsers.value.push(user);
      }
      inviteUserQuery.value = '';
      filteredUsers.value = [];
    };

    const removeUser = (user) => {
      selectedUsers.value = selectedUsers.value.filter(u => u.id !== user.id);
    };

    const inviteUsers = async () => {
      try {
        const currentUserId = store.getters['auth/getCurrentUserId'];
        for (const user of selectedUsers.value) {
          await groupService.inviteUserToGroup(groupId, currentUserId, user.id);
        }
        showInviteModal.value = false;
        selectedUsers.value = [];
        // Show success message or notification here
      } catch (err) {
        console.error('Error inviting users:', err);
        error.value = 'Failed to invite users: ' + (err.message || 'Unknown error');
      }
    };

    const formatDate = (date) => {
      return new Date(date).toLocaleDateString('en-US', { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' 
      });
    };

    const removeBookmark = async (postId) => {
      try {
        const userId = store.getters['auth/getCurrentUserId'];
        await groupService.unbookmarkPost(postId, userId);
        bookmarkedPosts.value = bookmarkedPosts.value.filter(post => post.id !== postId);
      } catch (err) {
        console.error('Error removing bookmark:', err);
        error.value = 'Failed to remove bookmark: ' + (err.message || 'Unknown error');
      }
    };

    onMounted(async () => {
      await fetchGroupDetails();
    });

    watch(currentTab, (newTab) => {
      if (newTab === 'Bookmarks' && bookmarkedPosts.value.length === 0) {
        const userId = store.getters['auth/getCurrentUserId'];
        if (userId) {
          groupService.getBookmarkedPosts(userId, groupId).then(posts => {
            bookmarkedPosts.value = posts;
          });
        }
      }
    });

    return {
      group,
      loading,
      error,
      isMember,
      posts,
      members,
      moderators,
      bookmarkedPosts,
      recentActivities,
      searchQuery,
      filteredPosts,
      filteredMembers,
      showInviteModal,
      inviteUserQuery,
      filteredUsers,
      selectedUsers,
      currentTab,
      tabs,
      postSortOption,
      aboutTab,
      joinGroup,
      searchUsers,
      selectUser,
      removeUser,
      inviteUsers,
      formatDate,
      router,
      truncateContent,
      sortedPosts,
      likePost,
      removeBookmark
    };
  }
};
</script>

<style scoped>
.group-details-page {
  /* Add any specific styles for the page */
}

.post-image {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 4px;
}

/* Add styles for scrollable recent activity */
.recent-activity-scroll {
  max-height: 300px;
  overflow-y: auto;
}
</style>