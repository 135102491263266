<template>
  <div class="writing-interface bg-gray-100 min-h-screen p-6">
    <div class="max-w-7xl mx-auto bg-white rounded-lg shadow-md p-6">
      <div class="flex justify-between items-center mb-6">
        <div class="flex items-center space-x-4">
          <h1 class="text-3xl font-bold text-gray-900">{{ story.title }}</h1>
          <input 
            v-model="chapterTitle" 
            @input="updateChapterTitle"
            class="text-2xl font-semibold p-2 border border-gray-300 rounded"
            placeholder="Chapter title"
          >
        </div>
        <div>
          <button @click="saveChapter" class="bg-blue-500 text-white px-4 py-2 rounded mr-2">Save</button>
          <button @click="publishChapter" class="bg-green-500 text-white px-4 py-2 rounded">Publish</button>
        </div>
      </div>
      
      <div class="flex space-x-6">
        <div class="w-3/4">
          <div id="editor" class="w-full min-h-[500px] border border-gray-300 rounded"></div>
        </div>
        
        <div class="w-1/4 space-y-6">
          <div class="bg-gray-100 rounded-lg p-4">
            <h2 class="text-xl font-semibold mb-4">Chapter Navigation</h2>
            <ul>
              <li 
                v-for="chapter in chapters" 
                :key="chapter.id" 
                class="mb-2 cursor-pointer"
                :class="{ 'text-blue-500': chapter.id === currentChapterId }"
                @click="loadChapter(chapter.id)"
              >
                {{ chapter.title }}
              </li>
            </ul>
            <button @click="addNewChapter" class="text-blue-500 mt-4">+ Add New Chapter</button>
          </div>
          
          <div class="bg-gray-100 rounded-lg p-4">
            <h2 class="text-xl font-semibold mb-4">Story Details</h2>
            <p><strong>Genre:</strong> {{ story.genre }}</p>
            <p><strong>Tags:</strong> {{ story.tags?.join(', ') }}</p>
            <p><strong>Target Audience:</strong> {{ story.target_audience }}</p>
            <p><strong>18+ Content:</strong> {{ story.is_adult ? 'Yes' : 'No' }}</p>
          </div>
          
          <div class="bg-gray-100 rounded-lg p-4">
            <h2 class="text-xl font-semibold mb-4">Writing Stats</h2>
            <p><strong>Word Count:</strong> {{ wordCount }}</p>
            <p><strong>Time Spent:</strong> {{ timeSpent }}</p>
          </div>
          
          <div class="bg-gray-100 rounded-lg p-4">
            <h2 class="text-xl font-semibold mb-4">Progress</h2>
            <div class="bg-gray-200 rounded-full h-4 mb-2">
              <div class="bg-blue-500 h-4 rounded-full" :style="{ width: `${progress}%` }"></div>
            </div>
            <p>{{ completedChapters }} / {{ totalChapters }} chapters</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { storyService } from '@/services/storyService';
import { useRoute} from 'vue-router';
import { supabase } from '@/supabase';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

export default {
  name: 'WritingInterface',
  setup() {
    const route = useRoute();
    const story = ref({});
    const chapters = ref([]);
    const currentChapterId = ref(null);
    const chapterTitle = ref('');
    const quill = ref(null);
    const timeSpent = ref(0);
    const completedChapters = ref(0);
    let timer;

    const wordCount = computed(() => {
      if (!quill.value) return 0;
      return quill.value.getText().split(/\s+/).filter(word => word.length > 0).length;
    });

    const totalChapters = computed(() => chapters.value.length);
    const progress = computed(() => (completedChapters.value / totalChapters.value) * 100);

    const fetchStory = async () => {
      const { data, error } = await supabase
        .from('stories')
        .select('*')
        .eq('id', route.params.storyId)
        .single();

      if (error) {
        console.error('Error fetching story:', error);
        return;
      }

      story.value = data;
    };

    const fetchChapters = async () => {
      const { data, error } = await supabase
        .from('chapters')
        .select('*')
        .eq('story_id', route.params.storyId)
        .order('chapter_number', { ascending: true });

      if (error) {
        console.error('Error fetching chapters:', error);
        return;
      }

      chapters.value = data;
      completedChapters.value = data.filter(chapter => chapter.status === 'published').length;
    };

    const loadChapter = async (chapterId) => {
      const chapter = chapters.value.find(c => c.id === chapterId);
      if (chapter) {
        currentChapterId.value = chapterId;
        chapterTitle.value = chapter.title;
        quill.value.root.innerHTML = chapter.content;
      }
    };

    const updateChapterTitle = async () => {
      if (!currentChapterId.value) return;

      const { error } = await supabase
        .from('chapters')
        .update({ title: chapterTitle.value })
        .eq('id', currentChapterId.value);

      if (error) {
        console.error('Error updating chapter title:', error);
      }
    };

    const publishChapter = async () => {
      if (!currentChapterId.value) return;

      const { error } = await supabase
        .from('chapters')
        .update({ 
          status: 'published',
          published_at: new Date().toISOString()
        })
        .eq('id', currentChapterId.value);

      if (error) {
        console.error('Error publishing chapter:', error);
      } else {
        completedChapters.value++;
      }
    };

    const addNewChapter = async () => {
      const newChapterNumber = chapters.value.length + 1;
      const { data, error } = await supabase
        .from('chapters')
        .insert({
          story_id: route.params.storyId,
          title: `Chapter ${newChapterNumber}`,
          chapter_number: newChapterNumber,
          content: '',
          status: 'draft'
        })
        .select()
        .single();

      if (error) {
        console.error('Error adding new chapter:', error);
      } else {
        chapters.value.push(data);
        loadChapter(data.id);
      }
    };

    const saveChapter = async () => {
  if (!currentChapterId.value) return;

  try {
    const chapterData = {
      title: chapterTitle.value,
      content: quill.value.root.innerHTML,
      word_count: wordCount.value,
      time_spent: timeSpent.value
    };

    const savedChapter = await storyService.saveChapter(currentChapterId.value, chapterData);
    console.log('Chapter saved successfully:', savedChapter);
  } catch (error) {
    console.error('Error saving chapter:', error);
  }
};

    onMounted(async () => {
      await fetchStory();
      await fetchChapters();

      quill.value = new Quill('#editor', {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['link', 'image'],
            ['blockquote'],
            ['clean']
          ]
        }
      });

      if (chapters.value.length > 0) {
        loadChapter(chapters.value[0].id);
      }

      timer = setInterval(() => {
        timeSpent.value += 1;
      }, 1000);

      quill.value.on('text-change', () => {
        saveChapter();
      });
    });

    onUnmounted(() => {
      clearInterval(timer);
    });

    return {
      story,
      chapters,
      currentChapterId,
      chapterTitle,
      wordCount,
      timeSpent,
      completedChapters,
      totalChapters,
      progress,
      loadChapter,
      updateChapterTitle,
      saveChapter,
      publishChapter,
      addNewChapter
    };
  }
};
</script>

<style>
.ql-editor {
  min-height: 500px;
}
</style>